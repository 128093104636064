import React from "react";
import { useTheme, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { logout } from "../auth";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const FetchError = ({ title, error, onRetry }) => {
  const dispatch = useDispatch();
  const onLogOut = React.useCallback(
    (errorDetail) => dispatch(logout(errorDetail)),
    [dispatch],
  );
  const theme = useTheme();
  const classes = useStyles();

  if (
    error?.statusCode &&
    error?.statusCode === 401 &&
    error?.statusText &&
    error?.statusText === "Unauthorized"
  ) {
    toast.error("Authorization Expired. Please login again.");
    onLogOut(error.detail);
    return null;
  } else {
    return (
      <div className={theme.mainContainer}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          align-items="center"
        >
          <Grid item xs={6}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="error content"
                id="panel1a-header"
              >
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  align-items="center"
                >
                  <Grid item xs={10}>
                    <Typography className={classes.heading}>{title}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton aria-label="retry" size="small">
                      <RefreshIcon onClick={onRetry} />
                    </IconButton>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  align-items="center"
                >
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Message:{error.detail}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Status Code:{error.statusCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Status Message:{error.statusText}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </div>
    );
  }
};
