import { createMuiTheme } from "@material-ui/core/styles/createTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

const theme = createMuiTheme({
  palette: {
    error: {
      main: "#f44336",
    },
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#5c5b5b",
    },
    text: {
      primary: "#676767",
      secondary: "#388e3c",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "Asap"].join(","),
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#1d243d",
        "&:hover": {
          color: "#1d243d",
        },
      },
    },
  },
  // @ts-ignore
  mainContainer: {
    flexGrow: 1,
    margin: 20,
  },
  masterContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  detailContainer: {
    flexGrow: 1,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
  infoContainer: {
    flexGrow: 1,
    margin: 20,
    width: 500,
    overflowX: "hidden",
    overflowY: "hidden",
  },

  tableContainer: {
    spacing: 16,
    textAlign: "center",
  },
  table: {
    maxHeight: 440,
  },
  projectReportTableContainer: {
    maxHeight: 440,
    background: "#EEEEEE",
  },
  mfgTableReports: {
    minHeight: 780,
  },
  tableHeader: {
    fontFamily: "Open Sans",
    color: "#FFFFFF",
    fontSize: "15px",
    background: "#003366",
  },
  subTableHeader: {
    fontFamily: "Open Sans",
    color: "#FFFFFF",
    fontSize: "16px",
    background: "#003366",
    padding: "10px",
  },
  titleContainer: {
    marginTop: 16,
    textAlign: "center",
  },
  splitButtonPopper: {
    zIndex: 50,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  buttonConfirmDisclosure: {
    background: "#4caf50",
    borderRadius: 30,
  },
  buttonSubmit: {
    margin: 16,
    background: "#4caf50",
    borderRadius: 30,
  },
  buttonReject: {
    margin: 16,
    color: "#1d243d",
    borderColor: "#1d243d",
    background: "none",
    borderRadius: 30,
  },
  buttonDisclosure: {
    color: "#1d243d",
    borderColor: "#1d243d",
  },
  itemsBar: {
    background: "#e9ecef",
    borderRadius: 50,
    margin: 6,
    padding: 18,
  },
  pvelH1: {
    textAlign: "left",
    fontSize: "32.5px",
    color: "#1d243d",
    margin: 10,
    marginTop: 15,
    marginBottom: 15,
    fontFamily: "Asap",
  },

  pvelH1Center: {
    textAlign: "center",
    fontSize: "32.5px",
    color: "#1d243d",
    margin: 10,
    fontFamily: "Asap",
  },
  pvelH2: {
    textAlign: "left",
    fontSize: "24px",
    color: "#1d243d",
    margin: 10,
    fontFamily: "Asap",
  },
  pvelH2Center: {
    textAlign: "center",
    fontSize: "24px",
    color: "#1d243d",
    margin: 10,
    fontFamily: "Asap",
  },
  pvelH3: {
    textAlign: "center",
    fontSize: "20.8px",
    color: "#1d243d",
    margin: 10,
    fontFamily: "Asap",
  },
  pvelH4: {
    textAlign: "center",
    fontSize: "18px",
    color: "#1d243d",
    margin: 8,
    fontFamily: "Asap",
  },
  pvelH5: {
    fontSize: "12px",
    color: "#30417f",
    textTransform: "uppercase",
    margin: 10,
    fontFamily: "Asap",
  },
  paragraph: {
    fontFamily: "Open Sans",
    color: "#676767",
    margin: 10,
  },
  downloadLink: {
    color: "#1d243d",
  },
  infoButtonColor: {
    color: "#003366",
  },
  scorecardsStyle: {
    color: "#30417f",
    padding: 10,
    background: "#e9ecef",
    borderRadius: 50,
    textTransform: "uppercase",
  },
  userOptionsStyle: {
    color: "#30417f",
    padding: 2,
    background: "#e9ecef",
    borderRadius: 50,
    textTransform: "uppercase",
  },
  userOptionsText: {
    textTransform: "uppercase",
  },
  userOptionsLogout: {
    textTransform: "uppercase",
    color: "#1d243d",
  },
  generalContainer: {
    minWidth: 1300,
  },
  resetPasswordMainTitle: {
    textAlign: "center",
  },
  resetPasswordTextField: {
    minWidth: "50vh",
  },
  resetPasswordContainer: {
    textAlign: "center",
  },
  resetPasswordButtonSubmit: {
    margin: 16,
    background: "#4caf50",
    borderRadius: 30,
    textAlign: "center",
    minWidth: "50vh",
  },
  resetPasswordButtonCancel: {
    margin: 16,
    color: "#1d243d",
    borderColor: "#1d243d",
    background: "none",
    borderRadius: 30,
    textAlign: "center",
    minWidth: "50vh",
  },
  stickyElement: {
    position: "sticky",
    top: 57,
    zIndex: 67,
    backgroundColor: "#fff",
  },
  stickyHead: {
    position: "sticky",
    top: 57,
  },
  subTable: {
    maxHeight: 220,
  },
  tableSubHeader: {
    fontFamily: "Open Sans",
    color: "#FFFFFF",
    fontSize: "16px",
    background: "#003366",
    position: "sticky",
    top: 87,
  },
  projectReportHeader: {
    background: "#676766",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
  technologyTagsColumn: {
    background: "#676766",
    color: "#FFFFFF",
    width: 300,
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
});

export const PVELTheme = responsiveFontSizes(theme);
