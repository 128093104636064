import Link from "@material-ui/core/Link";
import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Button from "@material-ui/core/Button";
import { useClearButtonsStyles } from "../../../../themes/appStyles";

const useStyles = makeStyles((theme) => ({
  twitterContainer: {
    minWidth: 275,
    minHeight: 400,
    margin: theme.spacing(3),
    verticalAlign: '"baseline"',
    padding: theme.spacing(0),
    maxHeight: 600,
  },
  marketingContainer: {
    minWidth: 275,
    minHeight: 400,
    margin: theme.spacing(3),
    verticalAlign: "baseline",
    padding: theme.spacing(3),
    overflow: "auto",
    maxHeight: 600,
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    textAlign: "center",
  },
  marketingContent: {
    marginBottom: theme.spacing(1),
  },
  container: {
    margin: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

export const TwitterMarketingView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();

  return (
    <Grid container className={classes.container} spacing={3}>
      <Grid item md={6} xs={12} style={{ order: 1 }}>
        <Card className={classes.marketingContainer}>
          <Typography variant="h3" component="h1" style={theme.pvelH2}>
            The Latest News from Kiwa PVEL
          </Typography>
          <Typography variant="body1" style={theme.paragraph}>
          Welcome to the Kiwa PVEL Portal. The Portal is here to help manufacturers access and share their Kiwa PVEL test reports, and for Downstream Partners to access shared reports. Begin by clicking on the navigation tabs to get started.<br /><br/>Are you a module purchaser that wants to access benchmarking data and test result insights?  
          As part of&nbsp;
          <Link style={theme.downloadLink} href="https://www.kiwa.com/us/en/service/consultancy/ppp/" target="_blank"
            rel="nofollow noopener noreferrer">
          Kiwa PVEL’s Premium Partner Program,
          </Link> subscribers receive a quarterly dashboard of our Product Qualification Program (PQP) test results from a rolling period of 36-months. Other benefits include a comprehensive presentation with key testing insights and a quarterly 1:1 phone call with our experts. 
          Contact us to learn more and sign up!
          <br/> <br/>
          To read more about all of Kiwa PVEL’s services, visit us online at&nbsp;

          <Link style={theme.downloadLink} href="https://www.kiwa.com/pvel" target="_blank"
            rel="nofollow noopener noreferrer">
          kiwa.com/pvel
          </Link>
          .
<br/><br/>
            As always, we welcome your feedback on how our testing and services. Please email us at&nbsp;  

            <Link style={theme.downloadLink} href="mailto:pvel@kiwa.com">
            pvel@kiwa.com 
            </Link>
             , to share your ideas.
          </Typography>
          <Button
            variant="outlined"
            className={newClasses.clearButtons}
            href="https://www.kiwa.com/pvel/news"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
           ACCESS LATEST NEWS
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};
