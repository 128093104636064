import { createSlice } from "@reduxjs/toolkit";
import { loadBootstrapData } from "./bootstrapServices";

const initialState = {
  bootstrapDataLoading: false,
  bootstrapData: {},
  errorBootstrapData: null,
};

const bootstrapSlice = createSlice({
  name: "bootstrap",
  initialState: initialState,
  reducers: {
    loadBootstrapDataPending: (state, action) => {
      state.bootstrapData = {};
      state.bootstrapDataLoading = true;
      state.errorBootstrapData = null;
    },
    loadBootstrapDataFulfilled: (state, action) => {
      state.bootstrapDataLoading = false;
      state.bootstrapData = action.payload;
    },
    loadBootstrapDataRejected: (state, action) => {
      state.bootstrapDataLoading = false;
      state.errorBootstrapData = action.payload;
    },
  },
});

export const { actions, reducer } = bootstrapSlice;

// Bootstrap Thunks

export const loadBootstrapDataRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadBootstrapDataPending());
    try {
      let result = await loadBootstrapData();
      dispatch(actions.loadBootstrapDataFulfilled(result));
    } catch (err) {
      dispatch(actions.loadBootstrapDataRejected(err));
    }
  };
};
