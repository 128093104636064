import React from "react";
export const useDisableContextMenu = () => {
  const disableContextMenu = React.useCallback(
    () => (event) => {
      event.preventDefault();
    },
    [],
  );
  return { disableContextMenu };
};
