import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { MY_PENDING_REPORT_OPTIONS } from "../reportConstants";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { TablePaginationActions } from "../../../common/TablePaginationActions";
import { SplitButton } from "features/common/SplitButton";
import { FetchError } from "features/common/FetchError";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSorting } from "features/common/hooks/useSorting";
import { usePagination } from "../../../common/hooks/usePagination";
import { useDisableContextMenu } from "../../../common/hooks/useDisableContextMenu";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { DisclosureModalAuthorization } from "../../../shared/manufacturerDiclosureAuthorization/DisclosureModalAuthorization";
import { DownloadReportLink } from "../../../common/DownloadReportLink";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const MyPendingReportsViewComponent = (props) => {
  const {
    onLoadPendingReports,
    pendingReports,
    pendingReportsCount,
    pendingReportsRowsPerPage,
    pendingReportsCurrentPage,
    pendingReportsLoading,
    onDisclosureSelection,
    onDownloadReport,
    errorPendingReports,
    errorDisclosingReport,
    successDisclosingReport,
    onResetErrorsPendingReports,
    pendingReportsSortCriterias,
    processedReportsSortCriterias,
    processedReportsRowsPerPage,
    currentProjectFilter,
    executeScroll,
  } = props;

  const [open, setOpen] = useState(false);
  const [disclosedReports, setDisclosedReports] = useState([]);
  const theme = useTheme();
  const classes = useClearButtonsStyles();
  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: pendingReportsSortCriterias,
    getDataFunc: refreshReports,
  });

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadPendingReports,
    limit: pendingReportsRowsPerPage,
    sort: pendingReportsSortCriterias,
  });

  const { disableContextMenu } = useDisableContextMenu();

  React.useEffect(() => {
    checkForDiscloseStatus();
    onResetErrorsPendingReports();
  }, [errorDisclosingReport, successDisclosingReport]);

  function refreshReports({ sort, clearSort }) {
    onLoadPendingReports({
      page: pendingReportsCurrentPage,
      limit: pendingReportsRowsPerPage,
      offset: pendingReportsCurrentPage * pendingReportsRowsPerPage,
      sort,
      clearSort,
    });
  }

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkForDiscloseStatus = () => {
    if (!isEmpty(errorDisclosingReport)) {
      toast.error(errorDisclosingReport.detail);
    } else if (successDisclosingReport) {
      toast.success("Action was completed successfully.");
    }
  };

  const hasRecords = () => !isEmpty(pendingReports);

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={pendingReportsLoading} />
      </TableCell>
    </TableRow>
  );

  const onConfirmDisclose = (rowId, option) => (event) => {
    if (option !== "PENDING") {
      onDisclosureSelection(rowId, option, {
        sortPendingReports: pendingReportsSortCriterias,
        sortProcessedReports: processedReportsSortCriterias,
        processedReportsRowsPerPage,
        pendingReportsRowsPerPage,
        projectNumber: currentProjectFilter,
      });
    }
  };

  const onDisclosureSelectionSelected = (rowId, option) => {
    let selectedOptions = disclosedReports;
    selectedOptions = { ...selectedOptions, [rowId]: option };
    setDisclosedReports(selectedOptions);
  };

  const renderConfirmButton = (reportId) => {
    if (
      disclosedReports[reportId] &&
      disclosedReports[reportId] !== "PENDING"
    ) {
      return (
        <Button
          variant="contained"
          style={theme.buttonConfirmDisclosure}
          onClick={onConfirmDisclose(reportId, disclosedReports[reportId])}
        >
          SAVE
        </Button>
      );
    }
  };

  const renderTableBody = () => {
    if (!isEmpty(pendingReports) && !isEmpty(pendingReports.entities)) {
      return (
        <TransitionGroup component={null}>
          {pendingReports.result.results.map((reportId) => {
            let report = pendingReports.entities.results[reportId];
            return (
              <CSSTransition timeout={1000} key={reportId} classNames="item">
                <TableRow key={`my-pending-report-${reportId}`}>
                  {report.disclosure !== null ? (
                    <TableCell align="left">
                      <SplitButton
                        rowId={report.id}
                        options={MY_PENDING_REPORT_OPTIONS}
                        selectedOption={"Pending"}
                        onDisclosureSelection={onDisclosureSelectionSelected}
                      />
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell align="left">
                    {renderConfirmButton(reportId)}
                  </TableCell>
                  <TableCell align="left">
                    <DownloadReportLink
                      onContextMenu={disableContextMenu}
                      currentReport={report}
                      onDownloadReport={onDownloadReport}
                      objectToModify={report}
                    />
                  </TableCell>
                  <TableCell align="left">{report.project_number}</TableCell>
                  <TableCell align="left">{report.project_type_text}</TableCell>
                  <TableCell align="left">{report?.issued_date}</TableCell>
                  <TableCell align="left">{report.type_text}</TableCell>
                </TableRow>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      );
    }
  };

  const scrollToProcessedReports = (evt) => {
    executeScroll();
  };

  if (errorPendingReports && !pendingReports.length) {
    return (
      <FetchError
        title="Error while loading data for pending reports. Expand for more details"
        error={errorPendingReports}
        onRetry={() => refreshReports({ sort: null, clearSort: true })}
      />
    );
  }

  return (
    <div style={theme.mainContainer}>
      <Typography variant="subtitle1" style={theme.pvelH2} gutterBottom>
        Pending Reports
      </Typography>
      <Typography style={theme.paragraph}>
        The reports listed below are ready for review. The primary project
        contact may disclose a report using the buttons below. Buttons are only
        visible for projects for which you are the primary contact. To view a
        tutorial for disclosing reports,&nbsp;
        <Link
          href="https://www.pvel.com/portal-help-disclose-reports/"
          style={theme.downloadLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          click here
        </Link>
        .
      </Typography>
      <Typography style={theme.paragraph}>
        Reports marked "General" will be available for download by Kiwa PVEL's
        Downstream Partners. Reports marked "By Request" will appear to
        Downstream Partners showing only metadata, and will require the
        Downstream Partner to seek your additional approval within the portal
        before they are able to download the report. Reports designated as
        "Unavailable" will only be visible to your company.&nbsp;
        <Link href="" onClick={handleOpen} className={classes.dashboardLinks}>
          Click here&nbsp;
        </Link>
        for our full disclosure terms.
      </Typography>
      <div style={theme.mainContainer}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={scrollToProcessedReports}
              className={classes.clearButtons}
            >
              Go To Processed Reports
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={clearSorting}
              disabled={isSortingDisabled()}
              className={classes.clearButtons}
            >
              Clear Sorting
            </Button>
          </Grid>
        </Grid>
      </div>
      <Paper style={theme.tableContainer}>
        <TableContainer style={theme.mfgTableReports}>
          <Table stickyHeader aria-label="My Pending Reports Table">
            <TableHead>
              <TableRow style={theme.tableHeaderRow}>
                <Tooltip title="Click the drop down arrow to make your disclosure selection, and then click the disclosure button once more to save your selection.">
                  <TableCell align="left" style={theme.tableHeader}>
                    Disclose
                  </TableCell>
                </Tooltip>
                <TableCell align="left" style={theme.tableHeader}>
                  Confirm Disclose
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={pendingReportsSortCriterias.reportName.active}
                    direction={
                      pendingReportsSortCriterias.reportName.active
                        ? pendingReportsSortCriterias.reportName.type
                        : "asc"
                    }
                    onClick={sortHandler("reportName")}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={pendingReportsSortCriterias.projectNumber.active}
                    direction={
                      pendingReportsSortCriterias.projectNumber.active
                        ? pendingReportsSortCriterias.projectNumber.type
                        : "asc"
                    }
                    onClick={sortHandler("projectNumber")}
                  >
                    Project No.
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Project Type
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={pendingReportsSortCriterias.reportDate.active}
                    direction={
                      pendingReportsSortCriterias.reportDate.active
                        ? pendingReportsSortCriterias.reportDate.type
                        : "asc"
                    }
                    onClick={sortHandler("reportDate")}
                  >
                    Report Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={pendingReportsSortCriterias.reportType.active}
                    direction={
                      pendingReportsSortCriterias.reportType.active
                        ? pendingReportsSortCriterias.reportType.type
                        : "asc"
                    }
                    onClick={sortHandler("reportType")}
                  >
                    Report Type
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingReportsLoading ? renderLoader() : renderTableBody()}
            </TableBody>
            <TableFooter>
              <TableRow></TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[25, { label: "All", value: -1 }]}
          colSpan={3}
          count={pendingReportsCount}
          rowsPerPage={pendingReportsRowsPerPage}
          page={pendingReportsCurrentPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <DisclosureModalAuthorization open={open} handleClose={handleClose} />
    </div>
  );
};

export const MyPendingReportsView = React.memo(MyPendingReportsViewComponent);

MyPendingReportsView.propTypes = {
  onLoadPendingReports: PropTypes.func.isRequired,
  onDownloadReport: PropTypes.func.isRequired,
  errorPendingReports: PropTypes.any,
  successDisclosingReport: PropTypes.bool,
  errorDisclosingReport: PropTypes.any,
  pendingReports: PropTypes.object.isRequired,
  pendingReportsCount: PropTypes.number.isRequired,
  pendingReportsRowsPerPage: PropTypes.number.isRequired,
  pendingReportsCurrentPage: PropTypes.number.isRequired,
  pendingReportsLoading: PropTypes.bool.isRequired,
  discloseReportLoading: PropTypes.bool.isRequired,
  onDisclosureSelection: PropTypes.func.isRequired,
};
