import { authorizationHeader } from "../../../util/fetchUtil";
import { responseBody } from "../../../util/fetchUtil";
import { TokenManager } from "../../../util/TokenManager";

// const host = process.env.REACT_APP_API_HOST;
const host = "https://portolaprod.azurewebsites.net/";

export const loadRequestedReports = async (limit, offset, sortQueryString) => {
  try {
    const url = `${host}/api/requests/?limit=${limit || 10}&offset=${
      offset || 0
    }${sortQueryString || ""}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadProcessedReports = async (limit, offset, projectFilter) => {
  try {
    const url = `${host}/api/documents/?disclosure!=PENDING&limit=${
      limit || 10
    }&offset=${offset || 0}${projectFilter}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const discloseReport = async (reportId, disclosureType) => {
  try {
    const url = `${host}/api/documents/${reportId}/disclose/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        disclosure: disclosureType,
      }),
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadMessageFromPvel = async () => {
  try {
    const url = `${host}/api/newsfeed/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadPendingRequests = async () => {
  try {
    const url = `${host}/api/documents/pending/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadActiveProjects = async () => {
  try {
    const url = `${host}/api/projects/?status=ACTIVE`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadReportRequests = async () => {
  try {
    const url = `${host}/api/requests/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadNewFollowersRequests = async () => {
  try {
    const url = `${host}/api/whoami/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadNewPvelReports = async () => {
  try {
    const url = `${host}/api/pvmodel/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
