import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";
import { useClearButtonsStyles } from "../../themes/appStyles";


const terms = require("../../assets/text/termsUse.md");

const useDialogTitleClasses = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    fontSize: "5px",
  },
}));

const PVELDialogTitle = (props) => {
  const classes = useDialogTitleClasses();
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useDialogContentClasses = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const useDialogActionClasses = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TermsUse = (props) => {
  const [post, setPost] = useState("");
  const dialogContentClasses = useDialogContentClasses();
  const dialogActionClasses = useDialogActionClasses();
  const newClasses = useClearButtonsStyles();

  const { open, setOpen } = props;

  useEffect(() => {
    fetch(terms)
      .then((res) => res.text())
      .then((response) => setPost(response))
      .catch((err) => console.error(err));
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <PVELDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Terms of Use
      </PVELDialogTitle>
      <DialogContent className={dialogContentClasses.root} dividers>
      <ReactMarkdown>
        {post}         
      </ReactMarkdown>
      </DialogContent>
      <DialogActions className={dialogActionClasses.root}>
        <Button
          autoFocus
          variant="outlined"
          onClick={handleClose}
          className={newClasses.clearButtons}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsUse;
