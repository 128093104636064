import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { useDialog } from "../../../common/hooks/useDialog";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { TablePaginationActions } from "../../../common/TablePaginationActions";
import { ReportRequestDialogView } from "../../../shared/reportRequestStatus/ReportRequestDialogView";
import { usePagination } from "../../../common/hooks/usePagination";
import { useDisableContextMenu } from "../../../common/hooks/useDisableContextMenu";
import { ReportInfoView } from "../../../shared/processedFindReports/ReportInfoView";
import { FilterReportsCriteriaView } from "./FilterReportsCriteriaView";
import TelegramIcon from "@material-ui/icons/Telegram";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import TimerOff from "@material-ui/icons/TimerOff";
import { FetchError } from "features/common/FetchError";
import Tooltip from "@material-ui/core/Tooltip";
import has from "lodash/has";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import { useRouteMatch } from "react-router-dom";

import { toast } from "react-toastify";
import { DownloadReportLink } from "../../../common/DownloadReportLink";
import {
  DISCLOSURE_GENERAL_STATUS,
  DISCLOSURE_PENDING_STATUS,
  ICON_ACTIVE_STATUS,
  ICON_APPROVED_STATUS,
  ICON_EXPIRED_STATUS,
  ICON_REFUSED_STATUS,
  ICON_REJECTED_STATUS,
  REQUEST_ACTIVE_STATUS,
  REQUEST_APPROVED_STATUS,
  REQUEST_FILTER_REPORT_TABLE_HEADERS,
} from "../filterReportsConstants";

const FilterReportsViewComponent = (props) => {
  const {
    onLoadFilteredReports,
    onClearFilteredReports,
    onSentReportRequests,
    filteredReports,
    filteredReportsCount,
    filteredReportsRowsPerPage,
    filteredReportsCurrentPage,
    filteredReportsLoading,
    companies,
    companiesLoading,
    technologyTags,
    technologyTagsLoading,
    types,
    typesLoading,
    range,
    rangeLoading,
    reportRequestsLoading,
    onDownloadReport,
    errorFilteredReports,
    errorCompanies,
    errorTechnologyTags,
    errorTypes,
    errorRange,
    errorSentReportRequests,
    loadCompaniesRequested,
    loadTechnologyTagsRequested,
    loadTypesRequested,
    loadRangeRequested,
    resetErrorsFilterReportRequested,
    successSentReportRequests,
  } = props;

  const initialOrder = {
    company: { active: false, type: "asc" },
    reportName: { active: false, type: "asc" },
    projectNumber: { active: false, type: "asc" },
    reportType: { active: false, type: "asc" },
    issueDate: { active: false, type: "asc" },
  };
  const { path } = useRouteMatch();
  const theme = useTheme();
  const classes = useClearButtonsStyles();
  const [selected, setSelected] = React.useState({});
  const [openRequestDialog, setOpenRequestDialog] = React.useState(false);
  const [item, open, toggleDialog] = useDialog();
  const [order, setOrder] = React.useState(initialOrder);
  const [isInitialSearch, setIsInitialSearch] = React.useState(true);

  const [fromDateSelected, setFromDateSelected] = React.useState("");
  const [toDateSelected, setToDateSelected] = React.useState("");
  const [companiesSelected, setCompaniesSelected] = React.useState([]);
  const [technologyTagsSelected, setTechnologyTagsSelected] = React.useState(
    [],
  );
  const [typesSelected, setTypesSelected] = React.useState([]);

  React.useEffect(() => {
    setFromDateSelected(range.issued_min);
    setToDateSelected(range.issued_max);
    checkForRequestStatus();
    resetErrorsFilterReportRequested();
  }, [
    range.issued_min,
    range.issued_max,
    resetErrorsFilterReportRequested,
    successSentReportRequests,
  ]);

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadFilteredReports,
    limit: filteredReportsRowsPerPage,
    issuedMin: fromDateSelected,
    issuedMax: toDateSelected,
    companies: companiesSelected,
    types: typesSelected,
    technologyTags: technologyTagsSelected,
  });

  const { disableContextMenu } = useDisableContextMenu();

  const isFilterDataLoading =
    companiesLoading ||
    technologyTagsLoading ||
    typesLoading ||
    rangeLoading ||
    reportRequestsLoading;

  const checkForRequestStatus = () => {
    if (!isEmpty(errorSentReportRequests)) {
      toast.error(errorSentReportRequests.detail);
    } else if (successSentReportRequests) {
      toast.success("Request was sent succesfully.");
    }
  };

  const handleClick = (event, id) => {
    let selectedReports = {};
    if (isSelected(id)) {
      selectedReports = delete selected[id];
      setSelected(selectedReports);
    } else {
      let currentReport = filteredReports.entities.results[id];
      selectedReports = {
        ...selected,
        [id]: currentReport,
      };

      setSelected(selectedReports);
    }
  };

  const createSortHandler = (orderCriteria) => () => {
    let requestedOrdering = order[orderCriteria];
    if (order[orderCriteria].active && order[orderCriteria].type === "asc") {
      requestedOrdering.type = "desc";
    } else if (
      order[orderCriteria].active &&
      order[orderCriteria].type === "desc"
    ) {
      requestedOrdering.type = "asc";
    } else if (!order[orderCriteria].active) {
      requestedOrdering.active = true;
    }
    setOrder({
      ...order,
      [orderCriteria]: requestedOrdering,
    });
    setSelected([]);
    performFiltering();
  };

  const handleOpenRequestDialog = () => {
    setOpenRequestDialog(true);
  };

  const handleCancelRequestDialog = () => {
    setOpenRequestDialog(false);
  };

  const handleSendReportRequests = (requests) => {
    setSelected([]);
    onSentReportRequests(requests, {
      page: filteredReportsCurrentPage,
      limit: filteredReportsRowsPerPage,
      offset: filteredReportsCurrentPage * filteredReportsRowsPerPage,
      issuedMin: fromDateSelected,
      issuedMax: toDateSelected,
      companies: companiesSelected,
      types: typesSelected,
      technologyTags: technologyTagsSelected,
    });
    setOpenRequestDialog(false);
  };

  const onClearSorting = () => {
    setOrder(initialOrder);
    performFiltering();
  };
  const isSortingDisabled = () =>
    !(
      order.company.active ||
      order.reportName.active ||
      order.projectNumber.active ||
      order.reportType.active ||
      order.issueDate.active
    );

  const performFiltering = () => {
    onLoadFilteredReports({
      page: filteredReportsCurrentPage,
      limit: filteredReportsRowsPerPage,
      offset: filteredReportsCurrentPage * filteredReportsRowsPerPage,
      issuedMin: fromDateSelected,
      issuedMax: toDateSelected,
      companies: companiesSelected,
      types: typesSelected,
      technologyTags: technologyTagsSelected,
      order: order,
    });
  };

  const renderTableLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={filteredReportsLoading} />
      </TableCell>
    </TableRow>
  );

  const renderCheckboxSelection = (currentReport, isItemSelected, labelId) => {
    if (currentReport["disclosure"] !== DISCLOSURE_GENERAL_STATUS) {
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
      );
    }
    return <TableCell></TableCell>;
  };

  const renderDownloadReportLink = (currentReport) => {
    if (
      currentReport["disclosure"] === DISCLOSURE_GENERAL_STATUS ||
      currentReport.requests[0].status === REQUEST_APPROVED_STATUS ||
      (currentReport["disclosure"] === DISCLOSURE_PENDING_STATUS &&
        (isDSCUser() || isPVELUser()))
    ) {
      return (
        <TableCell>
          <DownloadReportLink
            onContextMenu={disableContextMenu}
            currentReport={currentReport}
            onDownloadReport={onDownloadReport}
            objectToModify={currentReport}
          />
        </TableCell>
      );
    }
    return <TableCell>{currentReport.title}</TableCell>;
  };

  const isSelected = (id) => has(selected, id);

  const isPVELUser = () => {
    return path.includes("/pvel");
  };
  const isDSCUser = () => {
    return path.includes("/client");
  };

  const renderIconStatus = (status) => {
    switch (status) {
      case ICON_ACTIVE_STATUS:
        return (
          <Tooltip title="Request Sent">
            <TelegramIcon />
          </Tooltip>
        );
      case ICON_APPROVED_STATUS:
        return (
          <Tooltip title="Request Approved">
            <Done />
          </Tooltip>
        );
      case ICON_REJECTED_STATUS:
      case ICON_REFUSED_STATUS:
        return (
          <Tooltip title="Request Rejected">
            <Close />
          </Tooltip>
        );
      case ICON_EXPIRED_STATUS:
        return (
          <Tooltip title="Request Expired">
            <TimerOff />
          </Tooltip>
        );
      default:
        return <div />;
    }
  };

  const hasRecords = () =>
    !isEmpty(filteredReports) && !isEmpty(filteredReports.entities);

  const renderTableBody = () => {
    if (!isEmpty(filteredReports) && !isEmpty(filteredReports.entities)) {
      return filteredReports.result.results.map((reportId) => {
        let currentReport = filteredReports.entities.results[reportId];
        const isItemSelected = isSelected(reportId);
        const labelId = `filter-report-table-checkbox-${reportId}`;

        if (
          currentReport.requests[0]?.status === "APPROVED" ||
          currentReport.requests[0]?.status === "ACTIVE" ||
          isPVELUser()
        ) {
          return (
            <TableRow>
              <TableCell></TableCell>
              {isPVELUser() && <TableCell>{currentReport.id}</TableCell>}
              {isPVELUser() && (
                <TableCell>{currentReport.disclosure_text}</TableCell>
              )}
              <TableCell>{currentReport.entity_display_name}</TableCell>
              <TableCell>
                <Tooltip title="More Info">
                  <IconButton
                    style={theme.infoButtonColor}
                    aria-label="info"
                    component="span"
                    onClick={toggleDialog(true, currentReport)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                {renderIconStatus(currentReport.requests[0].status)}
              </TableCell>
              {renderDownloadReportLink(currentReport)}
              <TableCell>{currentReport.project_info[0]?.number}</TableCell>
              <TableCell>{currentReport.type_text}</TableCell>
              <TableCell>{currentReport?.issued_date}</TableCell>
              <TableCell>{currentReport?.technology_tags.join(", ")}</TableCell>
            </TableRow>
          );
        }
        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, reportId)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={reportId}
            selected={isItemSelected}
          >
            {renderCheckboxSelection(currentReport, isItemSelected, labelId)}
            {isPVELUser() && <TableCell>{currentReport.id}</TableCell>}
            <TableCell>{currentReport.entity_display_name}</TableCell>
            <TableCell>
              <Tooltip title="More Info">
                <IconButton
                  style={theme.infoButtonColor}
                  aria-label="info"
                  component="span"
                  onClick={toggleDialog(true, currentReport)}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>
              {renderIconStatus(currentReport.requests[0].status)}
            </TableCell>
            {renderDownloadReportLink(currentReport)}
            <TableCell>{currentReport.project_info[0]?.number}</TableCell>
            <TableCell>{currentReport.type_text}</TableCell>
            <TableCell>{currentReport?.issued_date}</TableCell>
            <TableCell>{currentReport?.technology_tags.join(", ")}</TableCell>
          </TableRow>
        );
      });
    }

    return (
      !isInitialSearch && (
        <TableRow>
          <TableCell align="center" colSpan={9}>
            <Typography variant="body1" style={theme.paragraph}>
              There are no matching reports for the current search. Try using
              fewer filters and searching again. For additional support, please
              contact&nbsp;
              <Link style={theme.downloadLink} href="mailto:portal@pvel.com">
                portal@pvel.com
              </Link>
            </Typography>
          </TableCell>
        </TableRow>
      )
    );
  };

  return (
    <div style={theme.generalContainer}>
      <div style={theme.mainContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1" style={theme.pvelH1} gutterBottom>
              Find a Report
            </Typography>
            <Typography style={theme.paragraph}>
              Use the search tool below to find and request reports. Click on
              the Report Name to download the desired report. Reports with
              checkboxes must be requested individually from the manufacturer.
              Note that Kiwa PVEL’s test programs have evolved significantly since
              our first reports were released in 2012. Both the names of Report
              Types and our Technology Tags have changed over the years. Also,
              some Technology Tags and Report Types will not return results
              because they relate to reports that the manufacturer has not
              disclosed. For questions, please contact&nbsp;
              <Link style={theme.downloadLink} href="mailto:pvel@kiwa.com">
                pvel@kiwa.com
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FilterReportsCriteriaView
              companies={companies}
              range={range}
              technologyTags={technologyTags}
              types={types}
              isFilterDataLoading={isFilterDataLoading}
              onLoadFilteredReports={onLoadFilteredReports}
              onClearFilteredReports={onClearFilteredReports}
              fromDateSelected={fromDateSelected}
              setFromDateSelected={setFromDateSelected}
              toDateSelected={toDateSelected}
              setToDateSelected={setToDateSelected}
              companiesSelected={companiesSelected}
              setCompaniesSelected={setCompaniesSelected}
              technologyTagsSelected={technologyTagsSelected}
              setTechnologyTagsSelected={setTechnologyTagsSelected}
              typesSelected={typesSelected}
              setTypesSelected={setTypesSelected}
              setSelected={setSelected}
              setIsInitialSearch={setIsInitialSearch}
              errorCompanies={errorCompanies}
              errorTechnologyTags={errorTechnologyTags}
              errorTypes={errorTypes}
              errorRange={errorRange}
              loadCompaniesRequested={loadCompaniesRequested}
              loadTechnologyTagsRequested={loadTechnologyTagsRequested}
              loadTypesRequested={loadTypesRequested}
              loadRangeRequested={loadRangeRequested}
              currentLimit={filteredReportsRowsPerPage}
            />
          </Grid>
          <>
            {errorFilteredReports && !filteredReports.length ? (
              <Grid item xs={12}>
                <FetchError
                  title="Error while loading data for filtered reports. Expand for more details"
                  error={errorFilteredReports}
                  onRetry={() =>
                    onLoadFilteredReports({
                      page: filteredReportsCurrentPage,
                      limit: filteredReportsRowsPerPage,
                      offset:
                        filteredReportsCurrentPage * filteredReportsRowsPerPage,
                      issuedMin: fromDateSelected,
                      issuedMax: toDateSelected,
                      companies: companiesSelected,
                      types: typesSelected,
                      technologyTags: technologyTagsSelected,
                      order: order,
                    })
                  }
                />
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    className={classes.clearButtons}
                    onClick={onClearSorting}
                    disabled={isSortingDisabled()}
                  >
                    Clear Sorting
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Paper style={theme.tableContainer}>
                    <TableContainer style={theme.table}>
                      <Table
                        stickyHeader
                        aria-label="My Filtered Reports Table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell style={theme.tableHeader}></TableCell>
                            {isPVELUser() && (
                              <TableCell style={theme.tableHeader}>
                                Document Id
                              </TableCell>
                            )}
                            {isPVELUser() && (
                              <TableCell style={theme.tableHeader}>
                                Disclosure
                              </TableCell>
                            )}
                            <TableCell
                              key="Company"
                              sortDirection={
                                order.company.active
                                  ? order.company.type
                                  : false
                              }
                              style={theme.tableHeader}
                            >
                              <TableSortLabel
                                disabled={!hasRecords()}
                                active={order.company.active}
                                direction={
                                  order.company.active
                                    ? order.company.type
                                    : "asc"
                                }
                                onClick={createSortHandler("company")}
                              >
                                {"Company"}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={theme.tableHeader}>
                              Info
                            </TableCell>
                            <TableCell style={theme.tableHeader}>
                              Status
                            </TableCell>
                            <TableCell
                              key="Report Name"
                              style={theme.tableHeader}
                            >
                              <TableSortLabel
                                disabled={!hasRecords()}
                                active={order.reportName.active}
                                onClick={createSortHandler("reportName")}
                                direction={
                                  order.reportName.active
                                    ? order.reportName.type
                                    : "asc"
                                }
                              >
                                {"Report Name"}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              key="Project Number"
                              style={theme.tableHeader}
                            >
                              <TableSortLabel
                                disabled={!hasRecords()}
                                active={order.projectNumber.active}
                                direction={
                                  order.projectNumber.active
                                    ? order.projectNumber.type
                                    : "asc"
                                }
                                onClick={createSortHandler("projectNumber")}
                              >
                                {"Project Number"}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              key="Report Type"
                              style={theme.tableHeader}
                            >
                              <TableSortLabel
                                disabled={!hasRecords()}
                                active={order.reportType.active}
                                direction={
                                  order.reportType.active
                                    ? order.reportType.type
                                    : "asc"
                                }
                                onClick={createSortHandler("reportType")}
                              >
                                {"Report Type"}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              key="Issue Date"
                              style={theme.tableHeader}
                            >
                              <TableSortLabel
                                disabled={!hasRecords()}
                                active={order.issueDate.active}
                                direction={
                                  order.issueDate.active
                                    ? order.issueDate.type
                                    : "asc"
                                }
                                onClick={createSortHandler("issueDate")}
                              >
                                {"Report Date"}
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={theme.tableHeader}>
                              Technology Tags
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredReportsLoading
                            ? renderTableLoader()
                            : renderTableBody()}
                        </TableBody>
                        <TableFooter>
                          <TableRow></TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={filteredReportsCount}
                      rowsPerPage={filteredReportsRowsPerPage}
                      page={filteredReportsCurrentPage}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography>
                    Note: Since PVEL was founded in 2010, the specific companies
                    issuing our reports have evolved. PVEL operated as PV
                    Evolution Labs from 2010 until 2014 when it joined DNV GL.
                    In 2019, PVEL relaunched as an independent entity. The logos
                    and company names shown on older reports reflect this
                    history.{" "}
                    <Link
                      href="https://www.pvel.com/about/#story"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={theme.downloadLink}
                    >
                      Click here{" "}
                    </Link>{" "}
                    to learn more.
                  </Typography> */}
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    style={theme.buttonSubmit}
                    onClick={handleOpenRequestDialog}
                    disabled={isEmpty(selected)}
                  >
                    Request Report
                  </Button>
                </Grid>
              </>
            )}
          </>
        </Grid>
        {!isEmpty(selected) && !isEmpty(filteredReports) && (
          <ReportRequestDialogView
            open={openRequestDialog}
            data={filteredReports}
            selectedData={selected}
            dataType={"Report"}
            dialogTitle="Request Confirmation"
            dialogContentText="Please add an optional message for each report request"
            onCancelRequestDialog={handleCancelRequestDialog}
            onSubmitData={handleSendReportRequests}
          />
        )}
        <Dialog open={open} keepMounted onClose={toggleDialog(false, null)}>
          <DialogTitle id="Info Title">{`Report# ${
            item ? item.id : ""
          }`}</DialogTitle>
          <DialogContent>
            <ReportInfoView report={item} />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export const FilterReportsView = React.memo(FilterReportsViewComponent);

FilterReportsView.propTypes = {
  onLoadFilteredReports: PropTypes.func.isRequired,
  onClearFilteredReports: PropTypes.func.isRequired,
  onDownloadReport: PropTypes.func.isRequired,
  onSentReportRequests: PropTypes.func.isRequired,
  filteredReports: PropTypes.object.isRequired,
  filteredReportsCount: PropTypes.number.isRequired,
  filteredReportsRowsPerPage: PropTypes.number.isRequired,
  filteredReportsCurrentPage: PropTypes.number.isRequired,
  filteredReportsLoading: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  companiesLoading: PropTypes.bool.isRequired,
  technologyTags: PropTypes.array.isRequired,
  technologyTagsLoading: PropTypes.bool.isRequired,
  types: PropTypes.object.isRequired,
  typesLoading: PropTypes.bool.isRequired,
  range: PropTypes.object.isRequired,
  rangeLoading: PropTypes.bool.isRequired,
  reportRequestsLoading: PropTypes.bool.isRequired,
  errorFilteredReports: PropTypes.any,
  errorCompanies: PropTypes.any,
  errorTechnologyTags: PropTypes.any,
  errorTypes: PropTypes.any,
  errorRange: PropTypes.any,
  errorSentReportRequests: PropTypes.any,
  resetErrorsFilterReportRequested: PropTypes.any,
  successSentReportRequests: PropTypes.any,
};
