import React from "react";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";

import { MyProjectsGeneralView } from "./MyProjectsGeneralView";
import { MyProjectDetailsView } from "./MyProjectDetailsView";

export const MyProjectsView = (props) => {
  const {
    currentProject,
    onDownloadReport,
    entityType,
    loadingProjects,
    projects,
    projectsCount,
    projectsRowsPerPage,
    projectsCurrentPage,
    onLoadProjectsRequested,
    projectSortCriterias,
    onSetCurrentProject,
  } = props;

  const theme = useTheme();

  const toggleCurrentView = (projectId) => (event) => {
    if (currentProject === projectId) {
      onSetCurrentProject(null);
    } else {
      onSetCurrentProject(projectId);
    }
  };

  const renderMainView = () => {
    if (currentProject === null) {
      return (
        <MyProjectsGeneralView
          entityType={entityType}
          loadingProjects={loadingProjects}
          projects={projects}
          projectsCount={projectsCount}
          projectsRowsPerPage={projectsRowsPerPage}
          projectsCurrentPage={projectsCurrentPage}
          onLoadProjectsRequested={onLoadProjectsRequested}
          currentProject={currentProject}
          toggleCurrentView={toggleCurrentView}
          projectSortCriterias={projectSortCriterias}
        />
      );
    } else if (currentProject !== null) {
      const projectData = projects.entities.results[currentProject];
      const documents = projects.entities.document_project;
      return (
        <MyProjectDetailsView
          documents={documents}
          onDownloadReport={onDownloadReport}
          entityType={entityType}
          projectData={projectData}
          toggleCurrentView={toggleCurrentView}
        />
      );
    }
  };

  return (
    <Grid container style={theme.generalContainer}>
      {renderMainView()}
    </Grid>
  );
};
