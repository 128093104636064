import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SummaryActivityView } from "./SummaryActivityView";
import {
  loadActiveRequestsRequested,
  loadNewReportsRequested,
  loadReportsRequestsRequested,
} from "../";

export const SummaryActivityContainer = () => {
  const activeRequestsCount = useSelector(
    (state) => state.dashboard.activeRequestsCount,
  );
  const activeRequestsCountLoading = useSelector(
    (state) => state.dashboard.activeRequestsCountLoading,
  );
  const reportRequestsCount = useSelector(
    (state) => state.dashboard.reportRequestsCount,
  );
  const reportRequestsCountLoading = useSelector(
    (state) => state.dashboard.reportRequestsCountLoading,
  );
  const newReportsCount = useSelector(
    (state) => state.dashboard.newReportsCount,
  );
  const newReportsCountLoading = useSelector(
    (state) => state.dashboard.newReportsCountLoading,
  );

  const dispatch = useDispatch();
  const onLoadActiveRequestsRequested = React.useCallback(() => {
    if (activeRequestsCount === null) {
      dispatch(loadActiveRequestsRequested());
    }
  }, [dispatch]);

  const onLoadReportsRequestsRequested = React.useCallback(() => {
    if (reportRequestsCount === null) {
      dispatch(loadReportsRequestsRequested());
    }
  }, [dispatch]);

  const onLoadNewReportsRequested = React.useCallback(() => {
    if (newReportsCount === null) {
      dispatch(loadNewReportsRequested());
    }
  }, [dispatch]);

  useEffect(() => {
    onLoadActiveRequestsRequested();
    onLoadReportsRequestsRequested();
    onLoadNewReportsRequested();
  }, [
    onLoadActiveRequestsRequested,
    onLoadReportsRequestsRequested,
    onLoadNewReportsRequested,
  ]);

  return (
    <SummaryActivityView
      activeRequestsCount={activeRequestsCount}
      activeRequestsCountLoading={activeRequestsCountLoading}
      reportRequestsCount={reportRequestsCount}
      reportRequestsCountLoading={reportRequestsCountLoading}
      newReportsCount={newReportsCount}
      newReportsCountLoading={newReportsCountLoading}
    />
  );
};
