import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { MaybeLoading } from "../../../common/MaybeLoading";
import Grid from "@material-ui/core/Grid";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import ReactMarkdown from "react-markdown";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 100,
    height: 300,
    overflow: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0,8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    textAlign: "center",
  },
  container: {
    padding: 15,
  },
});

export const MessagePvelView = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();
  const { messageFromPvelLoading, messageFromPvel } = props;

  const renderMessageFromPvel = () => (
    <Typography
      variant="body1"
      component="div"
      className={newClasses.pvelMessage}
    >
      <ReactMarkdown escapeHtml={false} source={messageFromPvel.body} />
    </Typography>
  );

  const renderLoading = () => (
    <Grid container justify="center" alignContent="center">
      <MaybeLoading loading={messageFromPvelLoading} />
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" style={theme.pvelH2} gutterBottom>
            {messageFromPvel.subject}
          </Typography>
          {messageFromPvelLoading ? renderLoading() : renderMessageFromPvel()}
        </CardContent>
      </Card>
    </div>
  );
};

MessagePvelView.propTypes = {
  messageFromPvelLoading: PropTypes.bool.isRequired,
  messageFromPvel: PropTypes.object.isRequired,
};
