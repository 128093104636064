import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export const ReportRequestDialogView = (props) => {
  const {
    open,
    data,
    selectedData,
    dataType,
    dialogTitle,
    dialogContentText,
    onSubmitData,
    onCancelRequestDialog,
  } = props;
  const [messages, setMessages] = React.useState({});

  React.useEffect(() => {
    let initialMessages = {};
    Object.keys(selectedData).map((key) => {
      initialMessages[key] = "";
    });
    setMessages(initialMessages);
  }, [selectedData]);

  const handleCancelReportRequest = () => {
    onCancelRequestDialog();
  };

  const handleSendReportRequest = () => {
    onSubmitData(messages);
  };

  const handleMessageChange = (event, id) => {
    let message = event.target.value;
    let messagesUpdated = {
      ...messages,
      [id]: message,
    };
    setMessages(messagesUpdated);
  };

  const renderTableContainer = () => {
    if (dataType === "Report") {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Report Name</TableCell>
                <TableCell>Request Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(selectedData).map((key) => {
                let currentItem = selectedData[key];
                return (
                  <TableRow key={key}>
                    <TableCell>{currentItem.entity_display_name}</TableCell>
                    <TableCell>{currentItem.title}</TableCell>
                    <TableCell>
                      <TextField
                        id="standard-basic"
                        label="Request Message"
                        onChange={(event) => handleMessageChange(event, key)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Requestor Company</TableCell>
                <TableCell>Report Name</TableCell>
                <TableCell>Approver Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(selectedData).map((id) => {
                let currentItem = data.entities.results[id];
                return (
                  <TableRow key={id}>
                    <TableCell>
                      {currentItem.requestor_company[0].display_name}
                    </TableCell>
                    <TableCell>{currentItem.document.title}</TableCell>
                    <TableCell>
                      <TextField
                        id={`text-field-${id}`}
                        label="Message"
                        onChange={(event) => handleMessageChange(event, id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancelRequestDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          {renderTableContainer()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelReportRequest} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendReportRequest} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
