export const getApiPaginationParams = (
  page = null,
  limit = null,
  offset = null,
  initialRowsPerPage,
  initialPage,
) => {
  let currentPage = page;
  let currentLimit = limit;
  let currentOffset = offset;

  if (page === null && limit === null && offset === null) {
    currentPage = initialPage;
    currentLimit = initialRowsPerPage;
    currentOffset = currentPage * currentLimit;
  }
  return {
    pageToRequest: currentPage,
    limitToRequest: currentLimit,
    offsetToRequest: currentOffset,
  };
};
