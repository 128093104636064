import React from "react";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

export const DownloadReportLink = (props) => {
  const { onContextMenu, currentReport, onDownloadReport, objectToModify } =
    props;

  const theme = useTheme();

  const handleReportDownload = (event) => {
    event.preventDefault();
    onDownloadReport(objectToModify);
  };

  if (!objectToModify.loading) {
    return (
      <Link
        style={theme.downloadLink}
        onContextMenu={onContextMenu()}
        href={currentReport.file}
        onClick={handleReportDownload}
        target="_blank"
        rel="noopener noreferrer"
      >
        {currentReport.title}
      </Link>
    );
  } else if (objectToModify.loading) {
    return (
      <>
        <p>{currentReport.title}</p>
        <LinearProgress />
      </>
    );
  }
};
