import React, { useState } from "react";
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { DownstreamPartnersContainer } from "./downstreamPartners/DownstreamPartnersContainer";
import { ManufacturersContainer } from "../shared/partnerManufacturer/ManufacturersContainer";
import { useClearButtonsStyles } from "../../themes/appStyles";
import Link from "@material-ui/core/Link";

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tab-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && <Box p={3}>{children} </Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const TabList = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const classes = useClearButtonsStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Grid container style={theme.generalContainer}>
      <Grid item xs={12} style={theme.titleContainer}>
        <Typography style={theme.pvelH1}>
          Participating Partners & Manufacturers
        </Typography>
        <Typography align="left" style={theme.paragraph}>
          The complete list of participating manufacturers can be found below.
          Click "+ FOLLOW" if you wish to be updated by email and on your portal
          home screen when a new report is available from that manufacturer.
        </Typography>
        <Typography align="left" style={theme.paragraph}>
          Would you like to refer a company to the Kiwa PVEL Downstream Partner
          network? Let us know at{" "}
          <Link style={theme.downloadLink} href="mailto:pvel@kiwa.com">
            pvel@kiwa.com
          </Link>
        </Typography>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs Downstream Partners"
            className={classes.headerManufacturerParners}
          >
            <Tab
              label="Downstream Partners"
              className={value === 0 ? classes.headerActive : null}
              {...a11yProps(0)}
            />
            <Tab
              label="Manufacturers"
              className={value === 1 ? classes.headerActive : null}
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid item xs={12}>
              <DownstreamPartnersContainer />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid item xs={12}>
              <ManufacturersContainer />
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
