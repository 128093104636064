import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useRouteMatch, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import PersonIcon from "@material-ui/icons/Person";
import { useClearButtonsStyles } from "../../../../themes/appStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    textAlign: "center",
    padding: theme.spacing(3),
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  container: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(2),
    height: 300,
    borderRadius: 10,
    padding: theme.spacing(4),
  },
  items: {
    borderColor: "rgb(0, 51, 102) !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundImage: "linear-gradient(green, black) !important",
      color: "#FFFFFF",
    },
  },
  content: {
    lineHeight: "normal",
    fontSize: "18px",
    padding: theme.spacing(0.5),
  },
  icon: {
    width: "100%",
  },
}));

export const SummaryActivityPartnerView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();
  const { path } = useRouteMatch();

  const projectCount = useSelector(
    (state) => state.auth.user.profile.entity.project_count,
  );

  const reportCount = useSelector(
    (state) => state.auth.user.profile.entity.document_count,
  );

  const renderDownstreamDashboardItems = () => {
    if (path === "/client") {
      return (
        <>
          <Paper elevation={3} variant="outlined" className={newClasses.items}>
            <>
              <Typography className={newClasses.pvelH4}>
                {projectCount}
              </Typography>
              <Typography
                className={newClasses.dashboardLinks}
                component={Link}
                to={`${path}/my-projects`}
              >
                My projects
              </Typography>
            </>
          </Paper>
          <Paper elevation={3} variant="outlined" className={newClasses.items}>
            <>
              <Typography className={newClasses.pvelH4}>
                {reportCount}
              </Typography>
              <Typography
                className={newClasses.dashboardLinks}
                component={Link}
                to={`${path}/my-reports`}
              >
                My reports
              </Typography>
            </>
          </Paper>
        </>
      );
    }
    return (
      <>
        <Paper elevation={3} variant="outlined" className={newClasses.items}>
          <Typography>
            <Link
              to={`${path}/my-reports`}
              className={newClasses.dashboardLinks}
            >
              <>
                <SendIcon className={classes.icon} />
                Report <br /> requests
              </>
            </Link>
          </Typography>
        </Paper>
        <Paper elevation={3} variant="outlined" className={newClasses.items}>
          <Typography>
            <Link
              to={`${path}/partners-and-manufacturers`}
              className={newClasses.dashboardLinks}
            >
              <PersonIcon className={classes.icon} />
              Partners & manufacturers
            </Link>
          </Typography>
        </Paper>
      </>
    );
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h3" style={theme.pvelH1Center}>
        EXPLORE
      </Typography>
      <Container className={classes.root}>
        <Paper
          elevation={3}
          variant="outlined"
          className={newClasses.items}
          color="red"
        >
          <Typography>
            <Link
              to={`${path}/find-a-report`}
              className={newClasses.dashboardLinks}
            >
              <SearchIcon className={classes.icon} /> Find a <br />
              report
            </Link>
          </Typography>
        </Paper>
        {renderDownstreamDashboardItems()}
      </Container>
    </Container>
  );
};
