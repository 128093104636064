import React from "react";

import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { CenteredMessage } from "features/common/CenteredMessage";

const useStyles = makeStyles((theme) => ({
  forgotPasswordContainer: {
    height: "60vh",
    paddingTop: "20vh",
  },
}));

export const ForgotPasswordView = (props) => {
  const {
    onForgotPasswordRequested,
    errorForgotPasswordRequested,
    successForgotPasswordRequested,
  } = props;
  const [newPassword, setNewPassword] = React.useState("");
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  React.useEffect(() => {
    verifyForgotPasswordStatus();
  }, [errorForgotPasswordRequested, successForgotPasswordRequested]);

  function verifyForgotPasswordStatus() {
    if (errorForgotPasswordRequested) {
      toast.error(errorForgotPasswordRequested.status);
    }
  }

  const onEmailChange = (e) => {
    setNewPassword(e.target.value);
  };

  const onSubmitPassword = (e) => {
    onForgotPasswordRequested(newPassword);
  };

  if (successForgotPasswordRequested) {
    return (
      <CenteredMessage
        message={successForgotPasswordRequested.status}
        title={"Check your email"}
        error={false}
      />
    );
  }

  return (
    <div className={classes.forgotPasswordContainer}>
      <Container>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h3" style={theme.resetPasswordMainTitle}>
              Forgot Password
            </Typography>
          </Grid>
          <Grid item xs={12} style={theme.resetPasswordMainTitle}>
            <Typography variant="body2">
              Enter your email address and we will send you a link to reset your
              password
            </Typography>
          </Grid>
          <Grid item xs={12} style={theme.resetPasswordContainer}>
            <TextField
              id="standard-basic"
              label="Email"
              style={theme.resetPasswordTextField}
              onChange={onEmailChange}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} style={theme.resetPasswordContainer}>
              <Button
                variant="outlined"
                color="primary"
                style={theme.resetPasswordButtonSubmit}
                onClick={onSubmitPassword}
              >
                Send Email
              </Button>
            </Grid>
            <Grid item xs={12} style={theme.resetPasswordContainer}>
              <Button
                variant="outlined"
                color="secondary"
                style={theme.resetPasswordButtonCancel}
                onClick={(evt) => history.push("/login")}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
