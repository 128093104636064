import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetPasswordRequested, magicLinkRequested } from "../index";
import { ResetPasswordView } from "./ResetPasswordView";
import { CenteredMessage } from "features/common/CenteredMessage";

export const ResetPasswordContainer = () => {
  const errorResetPasswordRequested = useSelector(
    (state) => state.auth.errorResetPasswordRequested,
  );
  const successResetPasswordRequested = useSelector(
    (state) => state.auth.successResetPasswordRequested,
  );

  const dispatch = useDispatch();
  const onResetPasswordRequested = (id, password) =>
    dispatch(resetPasswordRequested(id, password));

  const errorMagicLinkCallback = useSelector(
    (state) => state.auth.errorMagicLinkCallback,
  );
  const authData = useSelector((state) => state.auth);
  const { currentUserChecked, user } = authData;

  const { accessToken } = useParams();

  const onMagicLinkRequested = React.useCallback(
    (accessToken) => {
      dispatch(magicLinkRequested(accessToken));
    },
    [dispatch],
  );

  React.useEffect(() => {
    onMagicLinkRequested(accessToken);
  }, []);

  if (errorMagicLinkCallback) {
    return (
      <CenteredMessage
        title=""
        message={errorMagicLinkCallback.detail}
        error={true}
      />
    );
  }

  return (
    <>
      {currentUserChecked === true && user && (
        <ResetPasswordView
          errorResetPasswordRequested={errorResetPasswordRequested}
          successResetPasswordRequested={successResetPasswordRequested}
          onResetPasswordRequested={onResetPasswordRequested}
          user={user}
        />
      )}
    </>
  );
};
