import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useClearButtonsStyles } from "../../../themes/appStyles";

const UserAccountSecurityViewComponent = (props) => {
  const theme = useTheme();
  const classes = useClearButtonsStyles();
  const {
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    handlePasswordChange,
    toggleDrawer,
  } = props;

  return (
    <div style={theme.infoContainer}>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        align-items="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4">Account Security</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Change Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Old Password"
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Confirm new password"
            value={confirmNewPassword}
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            style={theme.buttonSubmit}
            onClick={handlePasswordChange}
          >
            Update password
          </Button>
          <Button
            variant="outlined"
            className={classes.clearButtons}
            onClick={toggleDrawer(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export const UserAccountSecurityView = React.memo(
  UserAccountSecurityViewComponent,
);
