import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { useDialog } from "../../../common/hooks/useDialog";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { TablePaginationActions } from "../../../common/TablePaginationActions";
import { ReportInfoView } from "../../../shared/processedFindReports/ReportInfoView";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FetchError } from "features/common/FetchError";
import { useSorting } from "features/common/hooks/useSorting";
import { usePagination } from "../../../common/hooks/usePagination";
import { useDisableContextMenu } from "../../../common/hooks/useDisableContextMenu";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import isEmpty from "lodash/isEmpty";
import { DownloadReportLink } from "../../../common/DownloadReportLink";

import { useClearButtonsStyles } from "../../../../themes/appStyles";
import { SplitButton } from "features/common/SplitButton";
import { MY_PENDING_REPORT_OPTIONS } from "../reportConstants";

export const MyProcessedReportsView = (props) => {
  
  const {
    onLoadProcessedReports,
    processedReports,
    processedReportsCount,
    processedReportsRowsPerPage,
    processedReportsCurrentPage,
    processedReportsLoading,
    projectNumbers,
    currentProjectFilter,
    onDownloadReport,
    errorProcessedReports,
    processedReportsSortCriterias,
    scrollRef,
    onDisclosureSelectionUpdate,
  } = props;

  const [disclosedReports, setDisclosedReports] = useState([]);

  const theme = useTheme();
  const classes = useClearButtonsStyles();
  const [project, setProject] = useState("");
  const [item, open, toggleDialog, Transition] = useDialog();
  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: processedReportsSortCriterias,
    getDataFunc: refreshReports,
  });

  const onDisclosureSelectionSelected = (rowId, option) => {
    onDisclosureSelectionUpdate(rowId, option, {
      sortProcessedReports: processedReportsSortCriterias,
      processedReportsRowsPerPage,
      projectNumber: currentProjectFilter,
    });
  };

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadProcessedReports,
    limit: processedReportsRowsPerPage,
    sort: processedReportsSortCriterias,
    projectNumber: project ? project : currentProjectFilter,
  });

  const { disableContextMenu } = useDisableContextMenu();

  const handleProjectChange = (event) => {
    setProject(event.target.value);
  };

  const onFilterByProject = () => {
    const projectFilter = project ? project : currentProjectFilter;
    onLoadProcessedReports({
      page: null,
      limit: null,
      offset: null,
      sort: processedReportsSortCriterias,
      projectNumber: projectFilter,
      clearSort: false,
    });
  };

  function refreshReports({ sort, clearSort }) {
    onLoadProcessedReports({
      page: processedReportsCurrentPage,
      limit: processedReportsRowsPerPage,
      offset: processedReportsCurrentPage * processedReportsRowsPerPage,
      projectNumber: project ? project : currentProjectFilter,
      sort,
      clearSort,
    });
  }

  const onClearProjectFilter = () => {
    setProject("");
    onLoadProcessedReports({
      page: null,
      limit: null,
      offset: null,
      sort: processedReportsSortCriterias,
      projectNumber: null,
      clearSort: true,
    });
  };

  const hasRecords = () => !isEmpty(processedReports);

  const scrollToPendingReports = () => {
    window.scrollTo(0, 0);
  };

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={processedReportsLoading} />
      </TableCell>
    </TableRow>
  );

  const renderTableBody = () => {
    if (!isEmpty(processedReports) && !isEmpty(processedReports.entities)) {
      return processedReports.result.results.map((reportId) => {
        let report = processedReports.entities.results[reportId];
        return (
          <TableRow key={`my-processed-report-${reportId}`}>
            <TableCell align="left">
              <Tooltip title="Info Report">
                <IconButton
                  style={theme.infoButtonColor}
                  aria-label="info"
                  component="span"
                  onClick={toggleDialog(true, report)}
                >
                  <InfoOutlinedIcon style={theme.infoButtonColor} />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell align="left">
              {/* {report.disclosure_text} */}
              <SplitButton
                        rowId={report.id}
                        options={MY_PENDING_REPORT_OPTIONS}
                        selectedOption={report.disclosure_text}
                        onDisclosureSelection={onDisclosureSelectionSelected}
                      />
            </TableCell>
            <TableCell align="left">
              <DownloadReportLink
                onContextMenu={disableContextMenu}
                currentReport={report}
                onDownloadReport={onDownloadReport}
                objectToModify={report}
              />
            </TableCell>
            <TableCell align="left">{report.project_info[0]?.number}</TableCell>
            <TableCell align="left">
              {report.project_info[0]?.type_text}
            </TableCell>
            <TableCell align="left">{report?.issued_date}</TableCell>
            <TableCell align="left">
              {report.technology_tags.join(", ")}
            </TableCell>
            <TableCell align="left">{report.type_text}</TableCell>
          </TableRow>
        );
      });
    }
  };

  if (errorProcessedReports && !processedReports.length) {
    return (
      <FetchError
        title="Error while loading data for processed reports. Expand for more details"
        error={errorProcessedReports}
        onRetry={() => refreshReports({ sort: null, clearSort: true })}
      />
    );
  }

  return (
    <div style={theme.mainContainer} ref={scrollRef}>
      <Grid
        container
        direction="row"
        justify="flex-end"
        item
        xs={12}
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={scrollToPendingReports}
            className={classes.clearButtons}
          >
            Go To Pending Reports
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            className={classes.clearButtons}
            onClick={clearSorting}
            disabled={isSortingDisabled()}
          >
            Clear Sorting
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={theme.pvelH2} gutterBottom>
            Processed Reports
          </Typography>
          <Typography style={theme.paragraph}>
            The reports below have been successfully processed according to the
            designation in the Disclosure column.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            component={Paper}
            justify="space-around"
            alignItems="center"
          >
            <Grid item xs={6}>
              <FormControl style={theme.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Search by Project Number
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={project}
                  onChange={handleProjectChange}
                >
                  {projectNumbers.map((project, index) => (
                    <MenuItem key={index} value={project["number"]}>
                      {project["number"]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={theme.buttonSubmit}
                onClick={onFilterByProject}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                className={classes.clearButtons}
                color="secondary"
                onClick={onClearProjectFilter}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper style={theme.tableContainer}>
            <TableContainer style={theme.mfgTableReports}>
              <Table stickyHeader aria-label="My Pending Reports Table">
                <TableHead>
                  <TableRow style={theme.tableHeaderRow}>
                    <TableCell
                      align="left"
                      style={theme.tableHeader}
                    ></TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      Disclose
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={processedReportsSortCriterias.reportName.active}
                        direction={
                          processedReportsSortCriterias.reportName.active
                            ? processedReportsSortCriterias.reportName.type
                            : "asc"
                        }
                        onClick={sortHandler("reportName")}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={
                          processedReportsSortCriterias.projectNumber.active
                        }
                        direction={
                          processedReportsSortCriterias.projectNumber.active
                            ? processedReportsSortCriterias.projectNumber.type
                            : "asc"
                        }
                        onClick={sortHandler("projectNumber")}
                      >
                        Project No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      Project Type
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={processedReportsSortCriterias.reportDate.active}
                        direction={
                          processedReportsSortCriterias.reportDate.active
                            ? processedReportsSortCriterias.reportDate.type
                            : "asc"
                        }
                        onClick={sortHandler("reportDate")}
                      >
                        Report Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      Technology Tags
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={processedReportsSortCriterias.reportType.active}
                        direction={
                          processedReportsSortCriterias.reportType.active
                            ? processedReportsSortCriterias.reportType.type
                            : "asc"
                        }
                        onClick={sortHandler("reportType")}
                      >
                        Report Type
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processedReportsLoading ? renderLoader() : renderTableBody()}
                </TableBody>
                <TableFooter>
                  <TableRow></TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={[25, { label: "All", value: -1 }]}
              colSpan={3}
              count={processedReportsCount}
              rowsPerPage={processedReportsRowsPerPage}
              page={processedReportsCurrentPage}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleDialog(false, null)}
      >
        <DialogTitle id="Info Title">{`Report# ${
          item ? item.id : ""
        }`}</DialogTitle>
        <DialogContent>
          <ReportInfoView report={item} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

MyProcessedReportsView.propTypes = {
  onLoadProcessedReports: PropTypes.func.isRequired,
  processedReports: PropTypes.object.isRequired,
  processedReportsCount: PropTypes.number.isRequired,
  processedReportsRowsPerPage: PropTypes.number.isRequired,
  processedReportsCurrentPage: PropTypes.number.isRequired,
  processedReportsLoading: PropTypes.bool.isRequired,
  projectNumbers: PropTypes.array.isRequired,
};
