import { createSlice } from "@reduxjs/toolkit";
import { loadRequestedReports } from "./partnerReportServices";
import { getApiPaginationParams } from "../../../util/appUtil";
import isEmpty from "lodash/isEmpty";
import { downloadReport } from "../../manufacturer/reports/reportServices";

import { normalize, schema } from "normalizr";
const result = new schema.Entity("results");
const resultSchema = { results: [result] };

const initialState = {
  requestedReportsLoading: false,
  requestedReportsCurrentPage: 0,
  requestedReportsCount: 0,
  requestedReportsRowsPerPage: 25,
  requestedReports: {},
  errorRequestedReports: null,
  requestedReportsSortCriterias: {
    status: { active: false, type: "asc" },
  },
};

const partnerReportSlice = createSlice({
  name: "partner/reports",
  initialState: initialState,
  reducers: {
    loadRequestedReportsPending: (state, action) => {
      state.requestedReports = {};
      state.requestedReportsLoading = true;
      state.requestedReportsCurrentPage = action.payload.pageToRequest;
      state.requestedReportsRowsPerPage = action.payload.limitToRequest;
      state.errorRequestedReports = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.requestedReportsSortCriterias =
            initialState.requestedReportsSortCriterias;
        } else {
          state.requestedReportsSortCriterias = action.payload.sort;
        }
      }
    },
    loadRequestedReportsFulfilled: (state, action) => {
      state.requestedReportsLoading = false;
      state.requestedReportsCount = action.payload.count;
      state.requestedReports = normalize(action.payload, resultSchema);
    },
    loadRequestedReportsRejected: (state, action) => {
      state.requestedReportsLoading = false;
      state.errorRequestedReports = action.payload;
    },
    downloadedRequestedReportPending: (state, action) => {
      state.requestedReports.entities.results[action.payload].loading = true;
    },
    downloadedRequestedReportFulfilled: (state, action) => {
      state.requestedReports.entities.results[action.payload].loading = false;
    },
    downloadedRequestedReportRejected: (state, action) => {
      state.requestedReports.entities.results[action.payload].loading = false;
    },
  },
});

export const { actions, reducer } = partnerReportSlice;

// My PartnerReports Reports Thunks

export const loadRequestedReportsRequested = ({
  page,
  limit,
  offset,
  sort,
  clearSort,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.requestedReportsRowsPerPage,
        initialState.requestedReportsCurrentPage,
      );
    dispatch(
      actions.loadRequestedReportsPending({
        pageToRequest,
        limitToRequest,
        sort,
        clearSort,
      }),
    );
    try {
      const sortQueryString = getSortQueryForRequestedReports(sort);
      let result = await loadRequestedReports(
        limitToRequest,
        offsetToRequest,
        sortQueryString,
      );
      dispatch(actions.loadRequestedReportsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadRequestedReportsRejected(err));
    }
  };
};

const getSortQueryForRequestedReports = (sort) => {
  let queryString = "";
  if (!isEmpty(sort) && sort.status.active) {
    let sortParametersArray = [];
    if (sort.status.active) {
      sortParametersArray.push(
        sort.status.type === "asc" ? "status" : "-status",
      );
    }
    queryString += `&ordering=${sortParametersArray.join()}`;
    return queryString;
  }
};

export const downloadedRequestedReport = (request) => {
  return async (dispatch) => {
    dispatch(actions.downloadedRequestedReportPending(request.id));
    try {
      await downloadReport(request.document);
      dispatch(actions.downloadedRequestedReportFulfilled(request.id));
    } catch (err) {
      dispatch(actions.downloadedRequestedReportRejected(err));
    }
  };
};
