export const MY_PENDING_REPORT_OPTIONS = [
  {
    id: 0,
    option: "PENDING",
    text: "Pending",
  },
  {
    id: 1,
    option: "GENERAL",
    text: "General",
  },
  {
    id: 2,
    option: "BY REQUEST",
    text: "By Request",
  },
  {
    id: 3,
    option: "UNAVAILABLE",
    text: "Unavailable",
  },
];
