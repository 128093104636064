import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TermsUse from "../common/TermsUse";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "30vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(10),
  },
  footer: {
    marginTop: "auto",
  },
  firstLink: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    borderRightWidth: ".5px",
    borderRightStyle: "solid",
    borderRightColor: "black",
  },
  links: {
    borderRightColor: "black",
    borderRightStyle: "ridge",
    borderRightWidth: ".5px",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  lastLink: {
    paddingLeft: theme.spacing(2),
    color: "#1d243d !important",
  },
  listContainer: {
    listStyle: "none",
    paddingRight: "20px",
  },
}));
export const Footer = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <TermsUse open={open} setOpen={setOpen} />
      <Container component="main" className={classes.main} maxWidth="xl">
        <Typography style={theme.pvelH2} gutterBottom>
          Need Help?
        </Typography>
        <Typography variant="body2" style={theme.paragraph} gutterBottom>
          Experiencing problems with logging in, accessing services or other
          issues when using this portal?
        </Typography>
        <List className={classes.listContainer}>
          <ListItem>
            <Link
              href="https://www.kiwa.com/us/en/kiwa/entities/pvel/contact/"
              className={classes.links}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Contact Us
            </Link>
            <Link
              href="https://www.kiwa.com/pvel"
              rel="nofollow noopener noreferrer"
              target="_blank"
              className={classes.lastLink}
            >
              Kiwa PVEL Services
            </Link>
          </ListItem>
        </List>
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="xl">
          <List component="ul" className={classes.listContainer}>
            <ListItem>
              <Link
                href="https://www.kiwa.com/pvel/about"
                className={classes.firstLink}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                About Kiwa PVEL
              </Link>
              <Link
                href="https://www.pvel.com/privacy-policy/"
                className={classes.links}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Privacy statement
              </Link>
              <Link
                href="#"
                className={classes.links}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                Terms of use
              </Link>
              <Link className={classes.lastLink}>
                &copy; {new Date().getFullYear()}  PVEL LLC ("Kiwa PVEL"). All rights reserved.
              </Link>
            </ListItem>
          </List>
        </Container>
      </footer>
    </div>
  );
};
