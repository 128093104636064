import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { LoginContainer } from "./features/auth/login";
import { MagicLinkCallback } from "./features/auth/login/MagicLinkCallback";
import { ForgotPasswordContainer } from "./features/auth/forgotPassword/ForgotPasswordContainer";
import { ResetPasswordContainer } from "./features/auth/forgotPassword/ResetPasswordContainer";
import { Manufacturer } from "./features/manufacturer";
import { Partner } from "./features/downstream/partner/Partner";
import { PrivateRoute } from "./util/authUtil";
import { Client } from "./features/downstream/client/Client";
import { PVEL } from "./features/pvel/PVEL";
import { Footer } from "./features/layout/Footer";

export const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LoginContainer} />
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/login/:accessToken" component={MagicLinkCallback} />
        <Route
          exact
          path="/forgot_password/:accessToken"
          component={ResetPasswordContainer}
        />
        <Route
          exact
          path="/forgot_password"
          component={ForgotPasswordContainer}
        />
        <PrivateRoute path="/manufacturer" component={Manufacturer} />
        <PrivateRoute path="/partner" component={Partner} />
        <PrivateRoute path="/client" component={Client} />
        <PrivateRoute path="/pvel" component={PVEL} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};
