export const REQUEST_FILTER_REPORT_TABLE_HEADERS = [
  {
    id: 1,
    label: "Company",
    disablePadding: false,
  },
  {
    id: 2,
    label: "Info",
    disablePadding: false,
  },
  {
    id: 3,
    label: "Status",
    disablePadding: false,
  },
  {
    id: 4,
    label: "Report Name",
    disablePadding: false,
  },
  {
    id: 5,
    label: "Project No",
    disablePadding: false,
  },
  {
    id: 6,
    label: "Project Type",
    disablePadding: false,
  },
  {
    id: 7,
    label: "Issue Date",
    disablePadding: false,
  },
  {
    id: 8,
    label: "Technology Tags",
    disablePadding: false,
  },
];

export const DISCLOSURE_GENERAL_STATUS = "GENERAL";
export const DISCLOSURE_PENDING_STATUS = "PENDING";

export const REQUEST_APPROVED_STATUS = "APPROVED";
export const REQUEST_ACTIVE_STATUS = "ACTIVE";

export const ICON_ACTIVE_STATUS = "ACTIVE";
export const ICON_APPROVED_STATUS = "APPROVED";
export const ICON_REJECTED_STATUS = "REJECTED";
export const ICON_REFUSED_STATUS = "REFUSED";
export const ICON_EXPIRED_STATUS = "EXPIRED";
