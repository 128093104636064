import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { MaybeLoading } from "../../../common/MaybeLoading";

export const FilterReportsCriteriaLoaderView = ({ isFilterDataLoading }) => (
  <Grid
    container
    spacing={4}
    component={Paper}
    direction="row"
    justify="space-between"
    align-items="center"
  >
    <Grid item xs={12} align="center">
      <MaybeLoading loading={isFilterDataLoading} />
    </Grid>
  </Grid>
);
