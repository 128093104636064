import React from "react";
import { UserOptionsView } from "./UserOptionsView";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../auth";
import {
  userProfileChanged,
  userPasswordChanged,
  loginRequested,
} from "../../auth";

export const UserOptionsContainer = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onLogin = React.useCallback(
    (username, password) => {
      dispatch(loginRequested(username, password));
    },
    [dispatch],
  );

  const onLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onUserProfileChanged = React.useCallback(
    (data) => {
      dispatch(userProfileChanged(data));
    },
    [dispatch],
  );

  const onUserPasswordChanged = React.useCallback(
    (data) => {
      dispatch(userPasswordChanged(data));
    },
    [dispatch],
  );

  return (
    <UserOptionsView
      auth={auth}
      onLogin={onLogin}
      onLogout={onLogout}
      onUserProfileChanged={onUserProfileChanged}
      onUserPasswordChanged={onUserPasswordChanged}
    />
  );
};
