import { authorizationHeader } from "../../../util/fetchUtil";
import { responseBody } from "../../../util/fetchUtil";
import { TokenManager } from "../../../util/TokenManager";

// const host = process.env.REACT_APP_API_HOST;
const host = "https://portolaprod.azurewebsites.net/";

export const loadPendingReports = async (limit, offset, sortQueryString) => {
  try {
    const url = `${host}/api/documents/?disclosure=PENDING&limit=${
      limit || 10
    }&offset=${offset || 0}${sortQueryString || ""}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadProcessedReports = async (
  limit,
  offset,
  projectFilter,
  queryString,
) => {
  try {
    const url = `${host}/api/documents/?disclosure!=PENDING&limit=${
      limit || 10
    }&offset=${offset || 0}${projectFilter}${queryString || ""}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const discloseReport = async (reportId, disclosureType) => {
  try {
    const url = `${host}/api/documents/${reportId}/disclose/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        disclosure: disclosureType,
      }),
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};


export const discloseReportUpdate = async (reportId, disclosureType) => {
  try {
    const url = `${host}/api/documents/${reportId}/update_status_to_pending/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        disclosure: disclosureType,
      }),
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};


export const loadMessageFromPvel = async () => {
  try {
    const url = `${host}/api/newsfeed/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadPendingRequests = async () => {
  try {
    const url = `${host}/api/documents/pending/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadActiveRequests = async () => {
  try {
    const url = `${host}/api/requests/?status=ACTIVE`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
export const loadExpiredRequests = async () => {
  try {
    const url = `${host}/api/requests/?status=EXPIRED`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadReportRequests = async () => {
  try {
    const url = `${host}/api/requests/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
/**Commented for future use  */
/* export const loadNewPvelReports = async () => {
  try {
    const url = `${host}/api/pvmodel/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
 */
export const downloadReport = async (report) => {
  try {
    const response = await fetch(report.file, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        Accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        "accept-encoding": "gzip, deflate, br",
        "accept-language": "en-US,en;q=0.9,es;q=0.8",
      },
    });
    /* var file = window.URL.createObjectURL(response);
    window.location.assign(file); */
    const documentBlob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([documentBlob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", report.name);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    //return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
