import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class ErrorRequest extends React.Component {
  render() {
    const { error } = this.props;

    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        align-items="center"
      >
        <Grid item xs={4}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              align-items="center"
            >
              <Grid item xs={12}>
                <Typography variant="body2">Message:{error.detail}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Status Code:{error.statusCode}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Status Message:{error.statusText}
                </Typography>
              </Grid>
            </Grid>
          </Alert>
        </Grid>
      </Grid>
    );
  }
}

export default ErrorRequest;
