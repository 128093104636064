import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Menu from "@material-ui/core/Menu";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { UserAccountSecurityView } from "./UserAccountSecurityView";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useClearButtonsStyles } from "../../../themes/appStyles";

/*Commented code belongs to future feature on User Profile Settings. #9*/
const useStyles = makeStyles((theme) => ({
  userOptions: {
    borderRadius: 15,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 150,
    borderColor: "#676767",
    color: "#1976d2",
    marginRight: 2,
  },
  root: {
    width: 150,
  },
}));

export const UserOptionsView = (props) => {
  const {
    auth,
    onLogout,
    //onUserProfileChanged,
    onUserPasswordChanged,
  } = props;
  const { errorPasswordChange } = auth;
  /*const [bio, setBio] = useState("");
  const [location, setLocation] = useState(auth.user?.profile.bio);
  const [firstName, setFirstName] = useState(auth.user?.profile?.location);
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState(null);*/
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [clientPasswordError, setClientPasswordError] = useState("");
  /*const [isAccountSettingsDisplayed, setIsAccountSettingsDisplayed] = useState(
    false
  );*/
  const [isAccountSecurityDisplayed, setIsAccountSecurityDisplayed] =
    useState(false);
  const classes = useStyles();
  const newClasses = useClearButtonsStyles();
  const theme = useTheme();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  React.useEffect(() => {
    //setBio(auth.user?.profile.bio);
    //setLocation(auth.user?.profile?.location);
    //setBirthDate(auth.user?.profile?.birth_date);
    //setFirstName(auth.user?.first_name);//
    //setLastName(auth.user?.last_name);
    verifyPasswordStatus();
  }, [auth]);

  const toggleDrawer = (status) => () => {
    setOpenDrawer(status);
    if (!status) {
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }
  };
  /*const onClickAccountSettings = () => {
    setOpenDrawer(true);
    //setIsAccountSettingsDisplayed(true);
    setIsAccountSecurityDisplayed(false);
    setAnchorEl(null);
  };*/

  const onClickAccountSecurity = () => {
    setOpenDrawer(true);
    //setIsAccountSettingsDisplayed(false);
    setIsAccountSecurityDisplayed(true);
    setAnchorEl(null);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  /*const handleProfileChange = () => {
    setOpenDrawer(false);
    onUserProfileChanged({
      id: auth.user.id,
      bio,
      location,
      birthDate,
      firstName,
      lastName,
    });
  };*/

  const handlePasswordChange = () => {
    setClientPasswordError("");
    if (isEmpty(oldPassword)) {
      toast.error("Old password field may not be empty.");
      return;
    }
    if (isEmpty(newPassword)) {
      toast.error("New Password field may not be empty.");
      return;
    }

    if (isEmpty(confirmNewPassword)) {
      toast.error("Confirm password field may not be empty.");
      return;
    }
    if (newPassword.localeCompare(confirmNewPassword) !== 0) {
      toast.error("Passwords don't match. Please try once more.");
    } else {
      onUserPasswordChanged({
        id: auth.user.id,
        username: auth.user.username,
        oldPassword,
        newPassword,
      });
      toast.info("Password change requested.");
    }
  };

  const verifyPasswordStatus = () => {
    if (!isEmpty(auth.errorPasswordChange)) {
      if (auth.errorPasswordChange.hasOwnProperty("old_password")) {
        toast.error(
          `${errorPasswordChange.old_password[0]} Please submit your correct previous password.`,
        );
      }
      if (auth.errorPasswordChange.hasOwnProperty("new_password")) {
        toast.error(auth.errorPasswordChange.new_password[0]);
      }
    }

    if (auth.successPasswordChange) {
      toast.success("Password change successful.");
      setOpenDrawer(false);
    }
  };

  return (
    <>
      <div className={classes.root}>
        {auth.user && (
          <FormControl variant="outlined" className={classes.formControl}>
            <Button
              aria-controls="button-settings-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              className={newClasses.userOptionsStyle}
            >
              {auth.displayName} <ArrowDropDownIcon />
            </Button>
            <Menu
              id="settings-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {/*  User Profile Page: Account Settings #9 */}
              {/*  <MenuItem onClick={onClickAccountSettings}>
                Account Settings
              </MenuItem> */}
              <MenuItem
                style={theme.userOptionsText}
                onClick={onClickAccountSecurity}
              >
                Account Security
              </MenuItem>
              <MenuItem>
                <Link
                  to="/login"
                  style={theme.userOptionsLogout}
                  onClick={onLogout}
                >
                  Log off
                </Link>
              </MenuItem>
            </Menu>
          </FormControl>
        )}
      </div>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        {/* {isAccountSettingsDisplayed && (
          <UserAccountSettingsView
            bio={bio}
            setBio={setBio}
            location={location}
            setLocation={setLocation}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            birthDate={birthDate}
            setBirthDate={setBirthDate}
            handleProfileChange={handleProfileChange}
            toggleDrawer={toggleDrawer}
          />
        )} */}
        {isAccountSecurityDisplayed && (
          <UserAccountSecurityView
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmNewPassword={confirmNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
            handlePasswordChange={handlePasswordChange}
            toggleDrawer={toggleDrawer}
            errorPasswordChange={errorPasswordChange}
            clientPasswordError={clientPasswordError}
          />
        )}
      </Drawer>
    </>
  );
};

UserOptionsView.propTypes = {
  auth: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onUserProfileChanged: PropTypes.func.isRequired,
  onUserPasswordChanged: PropTypes.func.isRequired,
};
