import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useClearButtonsStyles } from "../../../themes/appStyles";

export const DisclosureModalAuthorization = (props) => {
  const { open, handleClose } = props;
  const theme = useTheme();
  const classes = useClearButtonsStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Manufacturer Disclosure Authorization"}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography style={theme.paragraph}>
            {/* PVEL LLC's ("PVEL") services to manufacturer clients include
              providing certain downstream entities with access to independent
              reliability and performance test results of the manufacturer's. In
              order for PVEL to provide such access, PVEL must have permission
              from our manufacturer clients to disclose equipment names and
              models, testing protocols, results, reports and other data
              collected by PVEL in the course of performing services for
              manufacturers (collectively "Data") to downstream entities
              ("Downstream Partners"). "Downstream Partners" include but are not
              limited to developers, finance institutions, EPC firms,
              independent power producers and other third parties, as well as
              downstream entities that may also provide manufacturing services
              and/or may use Data to provide advisory services to other
              downstream entities. All Downstream Partners registered in the
              portal are required to sign a non-disclosure agreement in order to
              gain access. The list of participating Downstream Partners, as
              amended and updated from time to time, is available in the portal
              within the "DOWNSTREAM PARTNERS" tab in the top navigation bar. */}
          </Typography>

          <Typography style={theme.paragraph}>
            By toggling the disclosure options, manufacturer agrees that Kiwa PVEL
            may disclose to Downstream Partners the Data as indicated in the
            final disclosure selection. Manufacturer further agrees that
            Downstream Partners may use such Data for any purpose and that Kiwa PVEL
            is released from and shall have no liability for any such disclosure
            or use.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={classes.clearButtons}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
