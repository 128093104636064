import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { magicLinkRequested } from "../";
import isEmpty from "lodash/isEmpty";
import ErrorRequest from "features/common/ErrorRequest";

export const MagicLinkCallback = (props) => {
  const errorLoginCallback = useSelector(
    (state) => state.auth.errorMagicLinkCallback,
  );
  const authData = useSelector((state) => state.auth);
  const { currentUserChecked, user } = authData;

  const { accessToken } = useParams();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(magicLinkRequested(accessToken));
  }, []);

  return (
    <>
      {!isEmpty(errorLoginCallback) && (
        <ErrorRequest error={errorLoginCallback} />
      )}
      <div>
        {currentUserChecked === true && user && (
          <Redirect to={`/${user?.profile?.entity?.type?.toLowerCase()}`} />
        )}
      </div>
    </>
  );
};
