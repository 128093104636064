import { authorizationHeader } from "../../util/fetchUtil";
import { responseBody } from "../../util/fetchUtil";

const host = "https://portolaprod.azurewebsites.net";

export const login = async (formData) => {
  try {
    console.log(formData);
    const loginResponse = await fetch(`${host}/api/signin/`, {
      method: "POST",
      body: formData,
    });
    const loginResponseAsJSON = await responseBody(loginResponse);
    return loginResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const getCurrentUser = async (token) => {
  try {
    const getCurrentUserResponse = await fetch(`${host}/api/whoami/`, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(token),
      },
    });
    const getCurrentUserResponseAsJSON = await responseBody(
      getCurrentUserResponse,
    );
    return getCurrentUserResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const changeUserProfile = async (
  token,
  { id, bio, location, firstName, lastName, birthDate },
) => {
  try {
    const changeUserProfileResponse = await fetch(`${host}/api/users/${id}/`, {
      headers: {
        Authorization: authorizationHeader(token),
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify({
        profile: {
          bio,
          location,
          birth_date: birthDate,
        },
        first_name: firstName,
        last_name: lastName,
      }),
    });
    const changeUserProfileResponseAsJSON = await responseBody(
      changeUserProfileResponse,
    );
    return changeUserProfileResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const changeUserPassword = async (
  token,
  { id, oldPassword, newPassword },
) => {
  try {
    const changeUserPasswordResponse = await fetch(
      `${host}/api/users/${id}/set_password/`,
      {
        headers: {
          Authorization: authorizationHeader(token),
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      },
    );
    const changeUserPasswordResponseAsJSON = await responseBody(
      changeUserPasswordResponse,
    );
    return changeUserPasswordResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const magicLinkRequest = async (data) => {
  try {
    const getMagicLinkResponse = await fetch(`${host}/api/signin/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: data,
      }),
    });
    const getMagicLinkResponseAsJSON = await responseBody(getMagicLinkResponse);
    return getMagicLinkResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const forgotPasswordRequest = async (email) => {
  try {
    const forgotPasswordRequestResponse = await fetch(
      `${host}/api/forgot_password/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email,
        }),
      },
    );
    const forgotPasswordRequestResponseAsJSON = await responseBody(
      forgotPasswordRequestResponse,
    );
    return forgotPasswordRequestResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const resetPasswordRequest = async (id, password, token) => {
  try {
    const getMagicLinkResponse = await fetch(
      `${host}/api/users/${id}/lost_password/`,
      {
        method: "PUT",
        headers: {
          Authorization: authorizationHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_password: password,
        }),
      },
    );
    const getMagicLinkResponseAsJSON = await responseBody(getMagicLinkResponse);
    return getMagicLinkResponseAsJSON;
  } catch (err) {
    throw err;
  }
};
