import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { loadProcessedReportsRequested, downloadedProcessedReport, disclosedReportUpdate } from "../";

import { loadProjectNumbersRequested } from "../../../shared/projects";

import { MyProcessedReportsView } from "./MyProcessedReportsView";

export const MyProcessedReportsContainer = ({ scrollRef }) => {
  const processedReportsLoading = useSelector(
    (state) => state.report.processedReportsLoading,
  );
  const processedReports = useSelector(
    (state) => state.report.processedReports,
  );
  const processedReportsCurrentPage = useSelector(
    (state) => state.report.processedReportsCurrentPage,
  );
  const processedReportsCount = useSelector(
    (state) => state.report.processedReportsCount,
  );
  const processedReportsRowsPerPage = useSelector(
    (state) => state.report.processedReportsRowsPerPage,
  );

  const processedReportsSortCriterias = useSelector(
    (state) => state.report.processedReportsSortCriterias,
  );

  const currentProjectFilter = useSelector(
    (state) => state.report.currentProjectFilter,
  );

  const projectNumbers = useSelector((state) => state.project.projectNumbers);

  const errorProcessedReports = useSelector(
    (state) => state.report.errorProcessedReports,
  );

  const dispatch = useDispatch();

  const onLoadProcessedReports = useCallback(
    (params) => dispatch(loadProcessedReportsRequested(params)),
    [dispatch],
  );
  const onDownloadReport = useCallback(
    (report) => dispatch(downloadedProcessedReport(report)),
    [dispatch],
  );

  const onLoadProjectNumbersRequested = useCallback(
    () => dispatch(loadProjectNumbersRequested()),
    [dispatch],
  );

  const onDisclosureSelectionUpdate = useCallback(
    (reportId, disclosureAction, params) =>
      dispatch(disclosedReportUpdate(reportId, disclosureAction, params)),
    [dispatch],
  );

  useEffect(() => {
    if (isEmpty(processedReports) || isEmpty(projectNumbers)) {
      onLoadProcessedReports({
        page: null,
        limit: null,
        offset: null,
        projectNumber: null,
        sort: null,
        clearSort: false,
      });
      onLoadProjectNumbersRequested();
    }
  }, [onLoadProcessedReports, onLoadProjectNumbersRequested]);

  return (
    <MyProcessedReportsView
      onLoadProcessedReports={onLoadProcessedReports}
      processedReports={processedReports}
      processedReportsCount={processedReportsCount}
      processedReportsRowsPerPage={processedReportsRowsPerPage}
      processedReportsCurrentPage={processedReportsCurrentPage}
      processedReportsLoading={processedReportsLoading}
      projectNumbers={projectNumbers}
      currentProjectFilter={currentProjectFilter}
      onDownloadReport={onDownloadReport}
      errorProcessedReports={errorProcessedReports}
      processedReportsSortCriterias={processedReportsSortCriterias}
      onDisclosureSelectionUpdate={onDisclosureSelectionUpdate}
      scrollRef={scrollRef}
    />
  );
};
