import React from "react";
import { useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../../util/authUtil";
import PartnerMenu from "./PartnerMenu";
import { FilterReportsContainer } from "../filterReports/FilterReports";
import { RequestedReportsContainer } from "./MyReportRequests";
import { DashboardPartnerView } from "../dashboard/DashboardPartnerView";
import { TabList } from "../TabList";
// import { IdleTimerContainer } from "features/common/IdleTimerContainer";

export const Partner = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <PartnerMenu />
      {/* <IdleTimerContainer /> */}
      <PrivateRoute exact path={`${path}`} component={DashboardPartnerView} />

      <PrivateRoute
        path={`${path}/partners-and-manufacturers`}
        component={TabList}
      />

      <PrivateRoute
        path={`${path}/find-a-report`}
        component={FilterReportsContainer}
      />

      <PrivateRoute
        path={`${path}/my-reports`}
        component={RequestedReportsContainer}
      />
    </div>
  );
};
