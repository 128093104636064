import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { userExists } from "../features/auth/authSelectors";
import { REDIRECT_ON_LOGIN } from "../features/auth/authConstants";
import { TokenManager } from "./TokenManager";
import startsWith from "lodash/startsWith";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isActiveUser = useSelector((state) => userExists(state));
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuth(isActiveUser) &&
          startsWith(
            history.location.pathname,
            `/${user?.profile?.entity?.type?.toLowerCase()}`,
          )
        ) {
          return <Component {...props} />;
        } else {
          localStorage.setItem(
            REDIRECT_ON_LOGIN,
            JSON.stringify(props.location),
          );
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export const isAuth = (isActiveUser) => {
  if (window !== undefined) {
    if (TokenManager.getToken() && isActiveUser) {
      return true;
    } else {
      return false;
    }
  }
};
