import { makeStyles } from "@material-ui/core/styles";

export const useClearButtonsStyles = makeStyles((theme) => ({
  clearButtons: {
    margin: 10,
    color: "#1d243d",
    borderColor: "#1d243d",
    borderRadius: 30,
    "&:hover": {
      background: "#1d243d",
      color: "#FFFFFF",
    },
  },
  logoButton: {
    width: "205px",
    "&:hover": {
      color: "#FFFFFF",
    },
    "& a:hover": {
      color: "#FFFFFF",
    },
  },
  reportButtons: {
    marginTop: "2%",
    float: "right",
    borderRadius: 30,
    borderColor: "#1d243d",
    color: "#1d243d",
    "&:hover": {
      borderColor: "none",
      background: "#1d243d",
      color: "#FFFFFF",
    },
  },
  hoverReportButtons: {
    color: "#1d243d",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  paperContent: {
    color: "#1d243d",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  headerLinks: {
    color: "#1d243d",
    background: "#e9ecef",
    borderRadius: 80,
    margin: 6,
    padding: 18,
    "&:hover": {
      color: "#FFFFFF",
    },
    "& a:hover": {
      color: "#FFFFFF",
    },
  },
  buttonDisclosure: {
    color: "#1d243d",
    borderColor: "#1d243d",
    "&:hover": {
      background: "#1d243d",
      color: "#FFFFFF",
    },
    "&:disabled": {
      color: "#1d243d",
      borderColor: "#1d243d",
    },
  },
  items: {
    padding: theme.spacing(3),
    color: "#1d243d !important",
    borderColor: "rgb(0, 51, 102) !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: "linear-gradient(green,black)",
      "& $dashboardLinks": {
        color: "#FFFFFF",
      },
      "& $pvelH4": {
        color: "#FFFFFF",
      },
    },
  },
  dashboardLinks: {
    color: "#1d243d",
  },
  pvelH4: {
    color: "#1d243d",
    fontSize: "18px",
    textAlign: "center",
    margin: 8,
    fontFamily: "Asap",
  },
  headerManufacturerParners: {
    background: "#2e5275 !important",
    color: "#FFFFFF !important",
    indicatorColor: "green !important",
  },
  headerActive: {
    background: "#003366",
    color: "#1d243d",
  },
  pvelMessage: {
    fontFamily: "Open Sans",
    color: "#676767",
    margin: 10,
    "& a": {
      color: "#1d243d",
    },
  },
  scorecardsStyle: {
    color: "#30417f",
    padding: 18,
    margin: 6,
    background: "#e9ecef",
    borderRadius: 80,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    textTransform: "uppercase",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  userOptionsStyle: {
    color: "#30417f",
    padding: 18,
    margin: -10,
    background: "#e9ecef",
    borderRadius: 80,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)!important",
    textTransform: "uppercase",
    "&:hover": {
      "& span": {
        color: "#FFFFFF",
      },
    },
  },
  activeButtons: {
    color: "#30417f",
    background: "#d5d5d5",
    borderRadius: 80,
    margin: 6,
    padding: 18,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      color: "#FFFFFF",
    },
    "& a:hover": {
      color: "#FFFFFF",
    },
  },
}));
