import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const RequestedReportInfoView = ({ requestedReport }) => {
  const theme = useTheme();
  return (
    <div style={theme.infoContainer}>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        align-items="center"
      >
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Report Name
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Project Number
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.project_number}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Report Id
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.id}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Report Type
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.type_text}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Issue Date
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.issued_date}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Technology Tags
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.technology_tags.join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Project Number
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.project_number}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Status
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.status}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Product Type
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {requestedReport?.document?.project_number}
          </Typography>
        </Grid>
        {requestedReport?.requestor_company &&
          requestedReport?.requestor_company[0]?.display_name && (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Requestor Company
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  {requestedReport?.requestor_company &&
                    requestedReport?.requestor_company[0]?.display_name}
                </Typography>
              </Grid>
            </>
          )}
        {requestedReport?.requestor_company &&
          requestedReport?.requestor_company[0]?.company_type_text && (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Requestor Company Type
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" gutterBottom>
                  {requestedReport?.requestor_company &&
                    requestedReport?.requestor_company[0]?.company_type_text}
                </Typography>
              </Grid>
            </>
          )}
        {requestedReport?.requestor_comment && (
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2" gutterBottom>
                Requestor Message
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                {requestedReport?.requestor_comment}
              </Typography>
            </Grid>
          </>
        )}
        {requestedReport?.approver_comment && (
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2" gutterBottom>
                Approver Message
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                {requestedReport?.approver_comment}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
