import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadProjectsRequested } from "./projectSlice";
import { actions } from "./projectSlice";
import { MyProjectsView } from "./MyProjectsView";
import { downloadedProjectReport } from "./";
import isEmpty from "lodash/isEmpty";

export const MyProjectsContainer = () => {
  const entityType = useSelector((state) => state.auth.entityType);

  const projects = useSelector((state) => state.project.projects);
  const errorProjects = useSelector((state) => state.project.errorProjects);
  const loadingProjects = useSelector((state) => state.project.loadingProjects);
  const currentProject = useSelector((state) => state.project.currentProject);
  const projectsCurrentPage = useSelector(
    (state) => state.project.projectsCurrentPage,
  );
  const projectsCount = useSelector((state) => state.project.projectsCount);
  const projectsRowsPerPage = useSelector(
    (state) => state.project.projectsRowsPerPage,
  );

  const projectSortCriterias = useSelector(
    (state) => state.project.projectSortCriterias,
  );

  const { setCurrentProject } = actions;

  const dispatch = useDispatch();

  const onSetCurrentProject = React.useCallback(
    (projectId) => {
      dispatch(setCurrentProject(projectId));
    },
    [dispatch],
  );

  const onLoadProjectsRequested = React.useCallback(
    (params) => {
      dispatch(loadProjectsRequested(params));
    },
    [dispatch],
  );

  const onDownloadReport = React.useCallback(
    (report) => dispatch(downloadedProjectReport(report)),
    [dispatch],
  );

  React.useEffect(() => {
    if (isEmpty(projects)) {
      onLoadProjectsRequested({
        page: null,
        limit: null,
        offset: null,
        sort: null,
        clearSort: false,
      });
    }
  }, [onLoadProjectsRequested]);

  return (
    <MyProjectsView
      currentProject={currentProject}
      onDownloadReport={onDownloadReport}
      entityType={entityType}
      loadingProjects={loadingProjects}
      onSetCurrentProject={onSetCurrentProject}
      projects={projects}
      errorProjects={errorProjects}
      onLoadProjectsRequested={onLoadProjectsRequested}
      projectsCurrentPage={projectsCurrentPage}
      projectsCount={projectsCount}
      projectsRowsPerPage={projectsRowsPerPage}
      projectSortCriterias={projectSortCriterias}
    />
  );
};
