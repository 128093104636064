import React from "react";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import { ProjectReportsRowView } from "./ProjectReportsRowView";
import TablePagination from "@material-ui/core/TablePagination";
import { usePagination } from "../../common/hooks/usePagination";
import { TablePaginationActions } from "../../common/TablePaginationActions";
import Slide from "@material-ui/core/Slide";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { useSorting } from "features/common/hooks/useSorting";
import { useClearButtonsStyles } from "../../../themes/appStyles";
import { MaybeLoading } from "../../common/MaybeLoading";
export const MyProjectsGeneralView = (props) => {
  const {
    loadingProjects,
    entityType,
    projects,
    projectsCount,
    projectsRowsPerPage,
    projectsCurrentPage,
    onLoadProjectsRequested,
    currentProject,
    toggleCurrentView,
    projectSortCriterias,
  } = props;
  const theme = useTheme();
  const classes = useClearButtonsStyles();

  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: projectSortCriterias,
    getDataFunc: refreshProjects,
  });

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadProjectsRequested,
    limit: projectsRowsPerPage,
    sort: projectSortCriterias,
  });

  const hasRecords = () => !isEmpty(projects);

  function refreshProjects({ sort, clearSort }) {
    onLoadProjectsRequested({
      page: projectsCurrentPage,
      limit: projectsRowsPerPage,
      offset: projectsCurrentPage * projectsRowsPerPage,
      sort,
      clearSort,
    });
  }

  const renderTableBody = () => {
    if (currentProject === null) {
      if (!isEmpty(projects) && !isEmpty(projects.entities)) {
        return projects.result.results.map((projectId) => {
          let projectData = projects.entities.results[projectId];
          return (
            <ProjectReportsRowView
              entityType={entityType}
              projectData={projectData}
              currentProject={currentProject}
              toggleCurrentView={toggleCurrentView}
              key={projectId}
            />
          );
        });
      }
    }
  };

  if (loadingProjects) {
    return (
      <div style={theme.mainContainer}>
        <Paper style={theme.tableContainer}>
          <MaybeLoading loading={loadingProjects} />
        </Paper>
      </div>
    );
  }

  return (
    <Grid item xs={12}>
      <div style={theme.mainContainer}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <Typography variant="h4" style={theme.pvelH1}>
              My Projects
            </Typography>

            <Typography style={theme.paragraph}>
              All of your company's Kiwa PVEL testing projects are listed in the
              table below. Click the + icon to show a list of the reports that
              are associated with a specific project.
            </Typography>
            {entityType === "Manufacturer" && (
              <Typography style={theme.paragraph}>
                To disclose a report, please navigate to the My Reports tab. To
                assign names to your projects, please email{" "}
                <Link style={theme.downloadLink} href="mailto:portal@pvel.com">
                  portal@pvel.com
                </Link>{" "}
                with the PVEL project numbers and their corresponding project
                names
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={clearSorting}
              disabled={isSortingDisabled()}
              className={classes.clearButtons}
            >
              Clear Project Sorting
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={theme.mainContainer}>
        <Paper style={theme.tableContainer}>
          <TableContainer style={theme.table}>
            <Table stickyHeader aria-label="My Projects Table">
              <TableHead>
                <TableRow>
                  <TableCell padding="default" style={theme.tableHeader}>
                    <TableSortLabel
                      disabled={!hasRecords()}
                      active={projectSortCriterias.number.active}
                      direction={
                        projectSortCriterias.number.active
                          ? projectSortCriterias.number.type
                          : "asc"
                      }
                      onClick={sortHandler("number")}
                    >
                      Project Number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell padding="default" style={theme.tableHeader}>
                    <TableSortLabel
                      disabled={!hasRecords()}
                      active={projectSortCriterias.type_text.active}
                      direction={
                        projectSortCriterias.type_text.active
                          ? projectSortCriterias.type_text.type
                          : "asc"
                      }
                      onClick={sortHandler("type_text")}
                    >
                      Project Type
                    </TableSortLabel>
                  </TableCell>
                  <TableCell padding="default" style={theme.tableHeader}>
                    <TableSortLabel
                      disabled={!hasRecords()}
                      active={projectSortCriterias.name.active}
                      direction={
                        projectSortCriterias.name.active
                          ? projectSortCriterias.name.type
                          : "asc"
                      }
                      onClick={sortHandler("name")}
                    >
                      Project Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell padding="default" style={theme.tableHeader}>
                    <TableSortLabel
                      disabled={!hasRecords()}
                      active={projectSortCriterias.pvel_manager_user.active}
                      direction={
                        projectSortCriterias.pvel_manager_user.active
                          ? projectSortCriterias.pvel_manager_user.type
                          : "asc"
                      }
                      onClick={sortHandler("pvel_manager_user")}
                    >
                      PVEL Project Manager
                    </TableSortLabel>
                  </TableCell>
                  {entityType !== "Downstream Client" && (
                    <TableCell padding="default" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={
                          projectSortCriterias.document_approver_user.active
                        }
                        direction={
                          projectSortCriterias.document_approver_user.active
                            ? projectSortCriterias.document_approver_user.type
                            : "asc"
                        }
                        onClick={sortHandler("document_approver_user")}
                      >
                        Document Approver
                      </TableSortLabel>
                    </TableCell>
                  )}

                  <TableCell padding="default" style={theme.tableHeader}>
                    <TableSortLabel
                      disabled={!hasRecords()}
                      active={projectSortCriterias.primary_contact_user.active}
                      direction={
                        projectSortCriterias.primary_contact_user.active
                          ? projectSortCriterias.primary_contact_user.type
                          : "asc"
                      }
                      onClick={sortHandler("primary_contact_user")}
                    >
                      Primary Contact
                    </TableSortLabel>
                  </TableCell>
                  <TableCell padding="default" style={theme.tableHeader}>
                    Contract Signature Date
                  </TableCell>
                  <TableCell padding="default" style={theme.tableHeader}>
                    Latest Report Date
                  </TableCell>
                  <TableCell padding="default" style={theme.tableHeader}>
                    # of Reports
                  </TableCell>
                </TableRow>
              </TableHead>
              <Slide
                direction="up"
                in={currentProject === null}
                mountOnEnter
                unmountOnExit
              >
                <TableBody>{renderTableBody()}</TableBody>
              </Slide>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {projectsCount > 15 && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[15, { label: "All", value: -1 }]}
              colSpan={3}
              count={projectsCount}
              rowsPerPage={projectsRowsPerPage}
              page={projectsCurrentPage}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}
        </Paper>
      </div>
    </Grid>
  );
};
