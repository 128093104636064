import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  twitterContainer: {
    minWidth: 275,
    minHeight: 400,
    height: 600,
    margin: theme.spacing(3),
    verticalAlign: "baseline",
    padding: theme.spacing(3),
    maxHeight: 600,
  },
  marketingContainer: {
    minWidth: 275,
    minHeight: 400,
    margin: theme.spacing(3),
    verticalAlign: "baseline",
    padding: theme.spacing(10),
    maxHeight: 600,
    overflow: "auto",
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    textAlign: "center",
  },
  marketingContent: {
    marginBottom: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(3),
  },
}));

export const TwitterMarketingPartnerView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const newclasses = useClearButtonsStyles();
  return (
    <Container className={classes.container}>
      <Grid item md={6} xs={12}>
        <Card className={classes.twitterContainer}>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="PVEvolutionLabs"
            options={{ height: 550 }}
            noFooter={true}
            onComplete={null}
          />
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card className={classes.marketingContainer}>
          <Typography
            variant="h3"
            style={theme.pvelH2}
            className={classes.title}
          >
            Solar Technology and Cost Forecasting
          </Typography>
          <Typography
            variant="body1"
            style={theme.paragraph}
            className={classes.marketingContent}
          >
            PVEL’s new Solar Technology and Cost Forecasting report is a deep
            dive analysis of PV module cost, price, technology and form factor
            for the next 3 years with data on trends in module raw materials
            usage. The report supports build-planning and RFP bidding for
            developers with >1-year lead times for module selection and
            negotiating near-term supply agreements. Our Q2 report highlights
            COVID-19 effects on supply chain and logistics. Contact PVEL's
            Andrew Sundling at
            <Link
              style={theme.downloadLink}
              href="mailto:Andrew.Sundling@PVEL.com?subject=PVEL STAC Report"
            >
              Andrew.Sundling@PVEL.com
            </Link>
            to learn more.
          </Typography>
          <Button
            variant="outlined"
            className={newclasses.clearButtons}
            href="https://www.pvel.com/wp-content/uploads/PVEL-Solar-Technology-and-Cost-Report_March-2020.pdf"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Learn About the Report
          </Button>
        </Card>
      </Grid>
    </Container>
  );
};
