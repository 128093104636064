import { createSlice } from "@reduxjs/toolkit";
import {
  loadFilteredReports,
  loadCompanies,
  loadTechnologyTags,
  loadTypes,
  loadRange,
  sendReportRequests,
} from "./filterReportsServices";
import { downloadReport } from "../../manufacturer/reports/reportServices";
import isEmpty from "lodash/isEmpty";
import { getApiPaginationParams } from "../../../util/appUtil";

import { normalize, schema } from "normalizr";
const result = new schema.Entity("results");
const resultSchema = { results: [result] };

const initialState = {
  companies: [],
  companiesLoading: false,
  errorCompanies: null,
  technologyTags: [],
  technologyTagsLoading: false,
  errorTechnologyTags: null,
  types: {},
  typesLoading: false,
  errorTypes: null,
  range: {},
  rangeLoading: false,
  errorRange: null,
  filteredReportsLoading: false,
  filteredReports: {},
  filteredReportsError: null,
  filteredReportsCurrentPage: 0,
  filteredReportsCount: 0,
  filteredReportsRowsPerPage: 5,
  sentReportRequestsLoading: false,
  errorSentReportRequests: null,
  successSentReportRequests: false,
};

const filterReportsSlice = createSlice({
  name: "partner/filterReports",
  initialState: initialState,
  reducers: {
    companiesPending: (state, action) => {
      state.companies = [];
      state.errorCompanies = null;
      state.companiesLoading = true;
    },
    companiesFulfilled: (state, action) => {
      state.companiesLoading = false;
      state.companies = action.payload;
    },
    companiesRejected: (state, action) => {
      state.companiesLoading = false;
      state.errorCompanies = action.payload;
    },
    technologyTagsPending: (state, action) => {
      state.technologyTags = [];
      state.errorTechnologyTags = null;
      state.technologyTagsLoading = true;
    },
    technologyTagsFulfilled: (state, action) => {
      state.technologyTagsLoading = false;
      state.technologyTags = action.payload.results;
    },
    technologyTagsRejected: (state, action) => {
      state.technologyTagsLoading = false;
      state.errorTechnologyTags = action.payload;
    },
    typesPending: (state, action) => {
      state.types = {};
      state.errorTypes = null;
      state.typesLoading = true;
    },
    typesFulfilled: (state, action) => {
      state.typesLoading = false;
      state.types = action.payload;
    },
    typesRejected: (state, action) => {
      state.typesLoading = false;
      state.errorTypes = action.payload;
    },
    rangePending: (state, action) => {
      state.range = {};
      state.errorRange = null;
      state.rangeLoading = true;
    },
    rangeFulfilled: (state, action) => {
      state.rangeLoading = false;
      state.range = action.payload;
    },
    rangeRejected: (state, action) => {
      state.rangeLoading = false;
      state.errorRange = action.payload;
    },
    loadFilteredReportsPending: (state, action) => {
      state.filteredReports = {};
      state.filteredReportsLoading = true;
      state.filteredReportsCurrentPage = action.payload.pageToRequest;
      state.filteredReportsRowsPerPage = action.payload.limitToRequest;
      state.errorFilteredReports = null;
    },
    loadFilteredReportsFulfilled: (state, action) => {
      state.filteredReportsLoading = false;
      state.filteredReportsCount = action.payload.count;
      state.filteredReports = normalize(action.payload, resultSchema);
    },
    loadFilteredReportsRejected: (state, action) => {
      state.filteredReportsLoading = false;
      state.errorFilteredReports = action.payload;
    },
    clearedFilteredReports: (state, action) => {
      state.filteredReports = {};
      state.filteredReportsLoading = false;
      state.filteredReportsCount = 0;
      state.errorFilteredReports = null;
    },
    sentReportRequestsPending: (state, action) => {
      state.sentReportRequestsLoading = true;
      state.errorSentReportRequests = null;
      state.successSentReportRequests = false;
    },
    sentReportRequestsFulfilled: (state, action) => {
      state.sentReportRequestsLoading = false;
      state.successSentReportRequests = true;
    },
    sentReportRequestsRejected: (state, action) => {
      state.sentReportRequestsLoading = false;
      state.errorSentReportRequests = action.payload;
    },
    resetErrorsFilterReportRequested: (state, action) => {
      state.errorSentReportRequests = null;
      state.successSentReportRequests = false;
    },
    downloadFilteredReportPending: (state, action) => {
      state.filteredReports.entities.results[action.payload].loading = true;
    },
    downloadFilteredReportFulfilled: (state, action) => {
      state.filteredReports.entities.results[action.payload].loading = false;
    },
    downloadFilteredReportRejected: (state, action) => {
      state.filteredReports.entities.results[action.payload].loading = false;
    },
  },
});

export const { actions, reducer } = filterReportsSlice;

// Filter Reports Thunks

export const loadFilteredReportsRequested = ({
  page,
  limit,
  offset,
  issuedMin,
  issuedMax,
  companies,
  types,
  technologyTags,
  order,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.filteredReportsRowsPerPage,
        initialState.filteredReportsCurrentPage,
      );
    dispatch(
      actions.loadFilteredReportsPending({ pageToRequest, limitToRequest }),
    );
    try {
      let queryString = `?issued_min=${issuedMin}&issued_max=${issuedMax}`;
      if (companies.length > 0) {
        let companyIds = companies.map((company) => company.id);
        queryString += `&entity__id__in=${companyIds.join()}`;
      }
      if (types.length > 0) {
        let typesSelected = types.map((type) => type.key);
        queryString += `&type__in=${typesSelected.join()}`;
      }
      if (technologyTags.length > 0) {
        let technologyTagsIds = technologyTags.map(
          (technologyTag) => technologyTag.id,
        );
        queryString += `&tags__id__in=${technologyTagsIds}`;
      }
      if (
        !isEmpty(order) &&
        (order.company.active ||
          order.reportName.active ||
          order.projectNumber.active ||
          order.reportType.active ||
          order.issueDate.active)
      ) {
        let orderArray = [];
        if (order.company.active) {
          orderArray.push(
            order.company.type === "asc"
              ? "entity__display_name"
              : "-entity__display_name",
          );
        }
        if (order.reportName.active) {
          orderArray.push(order.reportName.type === "asc" ? "title" : "-title");
        }
        if (order.projectNumber.active) {
          orderArray.push(
            order.projectNumber.type === "asc" ? "project" : "-project",
          );
        }
        if (order.reportType.active) {
          orderArray.push(order.reportType.type === "asc" ? "type" : "-type");
        }
        if (order.issueDate.active) {
          orderArray.push(
            order.issueDate.type === "asc" ? "issued_date" : "-issued_date",
          );
        }
        queryString += `&ordering=${orderArray.join()}`;
      } else {
        queryString += `&ordering=-issued_date`;
      }
      let result = await loadFilteredReports(
        limitToRequest,
        offsetToRequest,
        queryString,
      );
      dispatch(actions.loadFilteredReportsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadFilteredReportsRejected(err));
    }
  };
};

export const loadCompaniesRequested = () => {
  return async (dispatch) => {
    dispatch(actions.companiesPending());
    try {
      let result = await loadCompanies();
      dispatch(actions.companiesFulfilled(result));
    } catch (err) {
      dispatch(actions.companiesRejected(err));
    }
  };
};

export const loadTechnologyTagsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.technologyTagsPending());
    try {
      let result = await loadTechnologyTags();
      dispatch(actions.technologyTagsFulfilled(result));
    } catch (err) {
      dispatch(actions.technologyTagsRejected(err));
    }
  };
};

export const loadTypesRequested = () => {
  return async (dispatch) => {
    dispatch(actions.typesPending());
    try {
      let result = await loadTypes();
      dispatch(actions.typesFulfilled(result));
    } catch (err) {
      dispatch(actions.typesRejected(err));
    }
  };
};

export const loadRangeRequested = () => {
  return async (dispatch) => {
    dispatch(actions.rangePending());
    try {
      let result = await loadRange();
      dispatch(actions.rangeFulfilled(result));
    } catch (err) {
      dispatch(actions.rangeRejected(err));
    }
  };
};

export const sentReportRequests = (requests, params) => {
  return async (dispatch) => {
    dispatch(actions.sentReportRequestsPending());
    try {
      let result = await sendReportRequests(requests);
      dispatch(actions.sentReportRequestsFulfilled(result));
      dispatch(loadFilteredReportsRequested(params));
    } catch (err) {
      dispatch(actions.sentReportRequestsRejected(err));
    }
  };
};

export const downloadedFilteredReport = (report) => {
  return async (dispatch) => {
    dispatch(actions.downloadFilteredReportPending(report.id));
    try {
      let result = await downloadReport(report);
      dispatch(actions.downloadFilteredReportFulfilled(report.id));
    } catch (err) {
      dispatch(actions.downloadFilteredReportRejected(err));
    }
  };
};

/* export const initFilteringData = () => {
  return async (dispatch) => {
    dispatch(loadCompaniesRequested());
    dispatch(loadTechnologyTagsRequested());
    dispatch(loadTypesRequested());
    dispatch(loadRangeRequested());
    dispatch(
      loadFilteredReportsRequested({
        page: 0,
        limit: 5,
        offset: 0,
        order: null,
        issuedMin: "",
        issuedMax: "",
        companies: [],
        types: [],
        technologyTags: [],
      })
    );
  };
  /* if (
    isEmpty(companies) ||
    isEmpty(technologyTags) ||
    isEmpty(types) ||
    isEmpty(range)
  ) { */

//}
//};
