import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import image from "../../../assets/images/panels.png";
import logo from "../../../assets/images/KiwaLogo.png";
import Link from "@material-ui/core/Link";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "110vh",
  },
  image: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    background: "#e9ecef",
    padding: theme.spacing(3),
    borderRadius: 30,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1d243d",
    color: "#FFFFFF",
    borderRadius: 50,
  },
  imageLogo: {
    height: "auto",
    width: "100%",
  },
  copyright: {
    color: "#1d243d",
    marginTop: 20,
  },
  forgotPass: {
    float: "right",
    color: "#1d243d !important",
    margin: 13,
    fontFamily: "Open Sans",
    fontSize: 16,
  },
  formInputs: {
    background: "#FFFFFF",
  },
  headingText: {
    float: "right",
    width: "60%",
    textAlign: "right",
    color: "#1d243d",
  },
}));

export const LoginView = (props) => {
  const {
    loading,
    loginFailed,
    reason,
    actionMessage,
    username,
    password,
    updateLoginData,
    handleSubmit,
    errorUser,
    successLogin,
  } = props;

  const classes = useStyles();

  React.useEffect(() => {
    checkLoginInfo();
  }, [loginFailed, reason, actionMessage, errorUser, successLogin]);

  const checkLoginInfo = () => {
    if (loginFailed) {
      toast.error(reason);
    }
    if (!isEmpty(actionMessage)) {
      toast.info(actionMessage);
    }
    if (!isEmpty(errorUser)) {
      toast.error(`Authentication error. Please see details below.`);
    }
    if (successLogin) {
      toast.success("Login successful.");
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={`${logo}`} className={classes.imageLogo} alt="PVEL Logo" />
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              onChange={updateLoginData}
              value={username}
              autoFocus
              disabled={loading}
              className={classes.formInputs}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={updateLoginData}
              value={password}
              autoComplete="current-password"
              disabled={loading}
              className={classes.formInputs}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              LOG IN
            </Button>
            {/** Code commented from issue #290  for future use*/}
            {/*  <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Link href="/forgot_password" className={classes.forgotPass}>
              Forgot your password?
            </Link>
            {errorUser && (
              <Alert severity="info" className={classes.copyright}>
                {errorUser}
              </Alert>
            )}
          </form>
          <Typography className={classes.copyright}>
          &copy; {new Date().getFullYear()}  PVEL LLC ("Kiwa PVEL"). All rights reserved.
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

LoginView.propTypes = {
  loading: PropTypes.bool.isRequired,
  loginFailed: PropTypes.bool,
  tokenExpired: PropTypes.bool,
  reason: PropTypes.string,
  actionMessage: PropTypes.string,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
