import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  forgotPasswordContainer: {
    height: "60vh",
    paddingTop: "20vh",
  },
}));

export const ResetPasswordView = (props) => {
  const {
    onResetPasswordRequested,
    errorResetPasswordRequested,
    successResetPasswordRequested,
    user,
  } = props;
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  React.useEffect(() => {
    verifyResetPasswordStatus();
  }, [errorResetPasswordRequested, successResetPasswordRequested]);

  function verifyResetPasswordStatus() {
    if (!isEmpty(errorResetPasswordRequested)) {
      toast.error(errorResetPasswordRequested);
    }
    if (successResetPasswordRequested) {
      history.replace(`/${user?.profile?.entity?.type?.toLowerCase()}`);
    }
  }

  const onPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onSubmitPassword = (e) => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match. Please try once more.");
    }
    onResetPasswordRequested(user.id, newPassword);
  };

  return (
    <div className={classes.forgotPasswordContainer}>
      <Container>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h3" style={theme.resetPasswordMainTitle}>
              Set your password
            </Typography>
          </Grid>
          <Grid item xs={12} style={theme.resetPasswordContainer}>
            <TextField
              id="standard-basic"
              type="password"
              label="New Password"
              style={theme.resetPasswordTextField}
              onChange={onPasswordChange}
            />
          </Grid>
          <Grid item xs={12} style={theme.resetPasswordContainer}>
            <TextField
              id="standard-basic"
              type="password"
              label="Confirm Password"
              style={theme.resetPasswordTextField}
              onChange={onConfirmPasswordChange}
            />
          </Grid>
          <Grid item xs={12} style={theme.resetPasswordContainer}>
            <Button
              variant="outlined"
              color="primary"
              style={theme.resetPasswordButtonSubmit}
              onClick={onSubmitPassword}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
