import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { loginRequested, actions } from "../authSlice";
import { LoginView } from "./LoginView";
import isEmpty from "lodash/isEmpty";
import startsWith from "lodash/startsWith";
import { currentUserOnApplicationLoadRequested } from "../";
import { TokenManager } from "util/TokenManager";
import { REDIRECT_ON_LOGIN } from "../authConstants";

export const LoginContainer = () => {
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const authData = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onGetCurrentUserOnApplicationLoad = React.useCallback(() => {
    if (isEmpty(authData.user) && !isEmpty(TokenManager.getToken())) {
      dispatch(currentUserOnApplicationLoadRequested());
    }
  }, [dispatch, authData.user]);

  const onLogin = useCallback(
    (username, password) => dispatch(loginRequested(username, password)),
    [dispatch],
  );

  const onResetErrors = useCallback(
    () => dispatch(actions.resetErrors()),
    [dispatch, loginData],
  );

  React.useEffect(() => {
    onGetCurrentUserOnApplicationLoad();
  }, [onGetCurrentUserOnApplicationLoad]);

  const { currentUserChecked, user } = authData;

  const addLoginHandler = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isEmpty(loginData.username) && !isEmpty(loginData.password)) {
      onLogin(loginData.username.toLowerCase(), loginData.password);
    }
  };

  const handleRedirects = () => {
    if (currentUserChecked === true && user) {
      const redirectUrlOnLogin = localStorage.getItem(REDIRECT_ON_LOGIN);
      localStorage.removeItem(REDIRECT_ON_LOGIN);
      if (redirectUrlOnLogin !== null) {
        const parsedRedirectHistoryObject = JSON.parse(redirectUrlOnLogin);
        if (
          startsWith(
            parsedRedirectHistoryObject.pathname,
            `/${user?.profile?.entity?.type?.toLowerCase()}`,
          )
        ) {
          return <Redirect to={JSON.parse(redirectUrlOnLogin)} />;
        } else {
          return (
            <Redirect to={`/${user?.profile?.entity?.type?.toLowerCase()}`} />
          );
        }
      } else {
        return (
          <Redirect to={`/${user?.profile?.entity?.type?.toLowerCase()}`} />
        );
      }
    }
    return (
      <LoginView
        handleSubmit={submitHandler}
        username={loginData.username}
        password={loginData.password}
        updateLoginData={addLoginHandler}
        onResetErrors={onResetErrors}
        {...authData}
      />
    );
  };

  return <>{handleRedirects()}</>;
};
