import { createSlice } from "@reduxjs/toolkit";
import { loadProjects, loadProjectNumbers } from "./projectServices";
import { getApiPaginationParams } from "../../../util/appUtil";
import { downloadReport } from "../../manufacturer/reports/reportServices";
import { normalize, schema } from "normalizr";
import isEmpty from "lodash/isEmpty";
const document_project = new schema.Entity("document_project");
const result = new schema.Entity("results", {
  document_project: [document_project],
});

const resultSchema = { results: [result] };

const initialState = {
  loadingProjects: false,
  projectsCurrentPage: 0,
  projectsCount: 0,
  projectsRowsPerPage: 15,
  projects: {},
  errorProjects: null,
  currentProject: null,
  projectNumbersLoading: false,
  projectNumbers: [],
  errorProjectNumbers: null,
  downloadReportLoading: false,
  errorDownloadReport: null,
  projectSortCriterias: {
    number: { active: false, type: "asc" },
    name: { active: false, type: "asc" },
    type_text: { active: false, type: "asc" },
    pvel_manager_user: { active: false, type: "asc" },
    document_approver_user: { active: false, type: "asc" },
    primary_contact_user: { active: false, type: "asc" },
  },
};

const projectSlice = createSlice({
  name: "manufacturer/projects",
  initialState: initialState,
  reducers: {
    loadProjectsPending: (state, action) => {
      state.projects = {};
      state.loadingProjects = true;
      state.projectsCurrentPage = action.payload.pageToRequest;
      state.projectsRowsPerPage = action.payload.limitToRequest;
      state.errorProjects = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.projectSortCriterias = initialState.projectSortCriterias;
        } else {
          state.projectSortCriterias = action.payload.sort;
        }
      }
    },
    loadProjectsFulfilled: (state, action) => {
      state.loadingProjects = false;
      state.projectsCount = action.payload.count;
      state.projects = normalize(action.payload, resultSchema);
    },
    loadProjectsRejected: (state, action) => {
      state.loadingProjects = false;
      state.errorProjects = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    loadProjectNumbersPending: (state, action) => {
      state.projectNumbers = [];
      state.pendingReportsLoading = true;
      state.errorPendingReports = null;
    },
    loadProjectNumbersFulfilled: (state, action) => {
      state.pendingReportsLoading = false;
      state.projectNumbers = action.payload;
    },
    loadProjectNumbersRejected: (state, action) => {
      state.projectNumbersLoading = false;
      state.errorProjectNumbers = action.payload;
    },
    downloadProjectReportPending: (state, action) => {
      state.projects.entities.document_project[action.payload].loading = true;
    },
    downloadProjectReportFulfilled: (state, action) => {
      state.projects.entities.document_project[action.payload].loading = false;
    },
    downloadProjectReportRejected: (state, action) => {
      state.errorDownloadReport = action.payload;
      state.projects.entities.document_project[action.payload].loading = false;
    },
  },
});

export const { actions, reducer } = projectSlice;

// My Reports Reports Thunks

export const loadProjectsRequested = ({
  page,
  limit,
  offset,
  sort,
  clearSort,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.projectsRowsPerPage,
        initialState.projectsCurrentPage,
      );
    dispatch(
      actions.loadProjectsPending({
        pageToRequest,
        limitToRequest,
        sort,
        clearSort,
      }),
    );
    const sortQueryString = getSortQueryForProjects(sort);
    try {
      const result = await loadProjects(
        limitToRequest,
        offsetToRequest,
        sortQueryString,
      );
      dispatch(actions.loadProjectsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadProjectsRejected(err));
    }
  };
};

export const loadProjectNumbersRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadProjectNumbersPending());
    try {
      let result = await loadProjectNumbers();
      dispatch(actions.loadProjectNumbersFulfilled(result));
    } catch (err) {
      dispatch(actions.loadProjectNumbersRejected(err));
    }
  };
};

export const downloadedProjectReport = (report) => {
  return async (dispatch) => {
    dispatch(actions.downloadProjectReportPending(report.id));
    try {
      await downloadReport(report);
      dispatch(actions.downloadProjectReportFulfilled(report.id));
    } catch (err) {
      dispatch(actions.downloadProjectReportRejected(err));
    }
  };
};

const getSortQueryForProjects = (sort) => {
  let queryString = "";
  if (
    !isEmpty(sort) &&
    (sort.number.active ||
      sort.number.name ||
      sort.type_text.active ||
      sort.pvel_manager_user.active ||
      sort.document_approver_user.active ||
      sort.primary_contact_user.active)
  ) {
    let sortParametersArray = [];
    if (sort.number.active) {
      sortParametersArray.push(
        sort.number.type === "asc" ? "number" : "-number",
      );
    }
    if (sort.name.active) {
      sortParametersArray.push(sort.name.type === "asc" ? "name" : "-name");
    }
    if (sort.type_text.active) {
      sortParametersArray.push(
        sort.type_text.type === "asc" ? "type_text" : "-type_text",
      );
    }
    if (sort.pvel_manager_user.active) {
      sortParametersArray.push(
        sort.pvel_manager_user.type === "asc"
          ? "pvel_manager__username"
          : "-pvel_manager__username",
      );
    }
    if (sort.document_approver_user.active) {
      sortParametersArray.push(
        sort.document_approver_user.type === "asc"
          ? "document_approver__username"
          : "-document_approver__username",
      );
    }
    if (sort.primary_contact_user.active) {
      sortParametersArray.push(
        sort.primary_contact_user.type === "asc"
          ? "primary_contact__username"
          : "-primary_contact__username",
      );
    }
    queryString += `&ordering=${sortParametersArray.join()}`;
    return queryString;
  }
};
