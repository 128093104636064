import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { Link } from "react-router-dom";
import { useClearButtonsStyles } from "../../../../themes/appStyles.js";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    textAlign: "center",
    "& > * ": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  container: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(2),
    height: 300,
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  items: {
    padding: theme.spacing(3),
    color: "#1d243d !important",
    "&:hover": {
      background: "linear-gradient(green,black)",
      "& $child": {
        color: "#FFFFFF",
      },
    },
  },
  child: {
    color: "#1d243d",
  },
}));

export const SummaryActivityView = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();

  const {
    activeRequestsCountLoading,
    activeRequestsCount,
    reportRequestsCountLoading,
    reportRequestsCount,
    newReportsCountLoading,
    newReportsCount,
  } = props;

  const renderReportRequestsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>
        {reportRequestsCount}
      </Typography>
      <Typography
        className={newClasses.dashboardLinks}
        component={Link}
        to="/manufacturer/requests"
      >
        Report Requests
      </Typography>
    </>
  );

  const renderActiveRequestsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>
        {activeRequestsCount}
      </Typography>
      <Typography className={newClasses.dashboardLinks}>
        Active Requests
      </Typography>
    </>
  );

  const renderNewReportsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>{newReportsCount}</Typography>
      <Tooltip title="New reports uploaded in the last 30 days">
        <Typography
          component={Link}
          className={newClasses.dashboardLinks}
          to="/manufacturer/reports"
        >
          New Reports
        </Typography>
      </Tooltip>
    </>
  );

  const renderLoading = (loading) => <MaybeLoading loading={loading} />;

  return (
    <Container className={classes.container}>
      <Typography style={theme.pvelH3}>Summary of Activity</Typography>
      <Container className={classes.root}>
        <Paper elevation={3} variant="outlined" className={newClasses.items}>
          {reportRequestsCountLoading
            ? renderLoading(reportRequestsCountLoading)
            : renderReportRequestsCount()}
        </Paper>
        <Paper elevation={3} variant="outlined" className={newClasses.items}>
          {newReportsCountLoading
            ? renderLoading(newReportsCountLoading)
            : renderNewReportsCount()}
        </Paper>
        <Paper elevation={3} variant="outlined" className={newClasses.items}>
          {activeRequestsCountLoading
            ? renderLoading(activeRequestsCountLoading)
            : renderActiveRequestsCount()}
        </Paper>
      </Container>
    </Container>
  );
};

SummaryActivityView.propTypes = {
  activeRequestsCountLoading: PropTypes.bool.isRequired,
  activeRequestsCount: PropTypes.number,
  reportRequestsCountLoading: PropTypes.bool.isRequired,
  reportRequestsCount: PropTypes.number,
  newReportsCountLoading: PropTypes.bool.isRequired,
  newReportsCount: PropTypes.number,
};
