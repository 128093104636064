import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadDownstreamPartnersRequested } from "../../downstream";
import { selectFilteredDownstreamPartners } from "../partnerSelectors";
import isEmpty from "lodash/isEmpty";

import DownstreamPartnersView from "./DownstreamPartnersView";

export const DownstreamPartnersContainer = () => {
  const [search, setSearch] = useState("");

  const downstreamPartnersLoading = useSelector(
    (state) => state.partner.downstreamPartnersLoading,
  );

  const filteredPartners = useSelector((state) =>
    selectFilteredDownstreamPartners(state, search),
  );

  const downstreamPartnersError = useSelector(
    (state) => state.partner.downstreamPartnersError,
  );

  const dispatch = useDispatch();

  const onSearchPartner = useCallback(
    (searchText) => setSearch(searchText),
    [search],
  );

  const onLoadDownstreamPartnersRequested = React.useCallback(() => {
    if (isEmpty(filteredPartners)) {
      dispatch(loadDownstreamPartnersRequested());
    }
  }, [dispatch]);

  useEffect(() => {
    onLoadDownstreamPartnersRequested();
  }, [onLoadDownstreamPartnersRequested]);

  return (
    <DownstreamPartnersView
      downstreamPartnersLoading={downstreamPartnersLoading}
      filteredPartners={filteredPartners}
      onSearchPartner={onSearchPartner}
      downstreamPartnersError={downstreamPartnersError}
      loadDownstreamPartnersRequested={loadDownstreamPartnersRequested}
    />
  );
};
