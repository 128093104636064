import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { MaybeLoading } from "../../../common/MaybeLoading";
import Grid from "@material-ui/core/Grid";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles({
  root: {
    minWidth: 100,
    height: 300,
    overflow: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0,8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    textAlign: "left",
  },
  container: {
    padding: 15,
  },
});

export const MessagePvelPartnerView = (props) => {
  const classes = useStyles();
  const newClasses = useClearButtonsStyles();
  const theme = useTheme();
  const { messageFromPvelPartnerLoading, messageFromPvelPartner } = props;

  const renderMessageFromPvel = () => (
    <Typography component="div" className={newClasses.pvelMessage}>
      <ReactMarkdown escapeHtml={false} source={messageFromPvelPartner.body} />
    </Typography>
  );

  const renderLoading = () => (
    <Grid container justify="center" alignContent="center">
      <MaybeLoading loading={messageFromPvelPartnerLoading} />
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {messageFromPvelPartner.subject}
          </Typography>
          {messageFromPvelPartnerLoading
            ? renderLoading()
            : renderMessageFromPvel()}
        </CardContent>
      </Card>
    </div>
  );
};

MessagePvelPartnerView.propTypes = {
  messageFromPvelPartner: PropTypes.object.isRequired,
  messageFromPvelPartnerLoading: PropTypes.bool.isRequired,
};
