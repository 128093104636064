import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const ReportInfoView = ({ report }) => {
  const theme = useTheme();
  return (
    <div style={theme.infoContainer}>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        align-items="center"
      >
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Report Name
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Project Number
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.project_info[0]?.number}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Project Type
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.project_info[0]?.type_text}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Report Id
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.id}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Report Type
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.type_text}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Issue Date
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.issued_date}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Technology Tags
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.technology_tags?.join()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Disclosure
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.disclosure_text}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" gutterBottom>
            Product Type
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom>
            {report?.product_type}
          </Typography>
        </Grid>
        {report?.requests[0]?.requestor_company && (
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2" gutterBottom>
                Requestor Company
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                {report?.requests &&
                  report?.requests[0]?.requestor_company &&
                  report?.requests[0]?.requestor_company[0]?.display_name}
              </Typography>
            </Grid>
          </>
        )}
        {report?.requests && report?.requests[0]?.requestor_comment && (
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2" gutterBottom>
                Requestor Message
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                {report?.requests && report?.requests[0]?.requestor_comment}
              </Typography>
            </Grid>
          </>
        )}
        {report?.requests && report?.requests[0]?.approver_comment && (
          <>
            <Grid item xs={6}>
              <Typography variant="subtitle2" gutterBottom>
                Approver Message
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" gutterBottom>
                {report?.requests && report?.requests[0]?.approver_comment}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
