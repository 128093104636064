import { authorizationHeader } from "../../../util/fetchUtil";
import { responseBody } from "../../../util/fetchUtil";
import { TokenManager } from "../../../util/TokenManager";

// const host = process.env.REACT_APP_API_HOST;
const host = "https://portolaprod.azurewebsites.net/";

export const loadPendingRequests = async (queryString) => {
  try {
    const url = `${host}/api/requests/?status=ACTIVE&limit=-1${queryString}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const changeRequestStatus = async (requests, params) => {
  try {
    const url = params.isApprove
      ? `${host}/api/requests/batchapprove/`
      : `${host}/api/requests/batchreject/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requests),
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadProcessedRequests = async (limit, offset, queryString) => {
  try {
    const url = `${host}/api/requests/?status!=ACTIVE&limit=${
      limit || 10
    }&offset=${offset || 0}${queryString}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
