import { createSlice } from "@reduxjs/toolkit";
import { loadMessageFromPvel, loadReportsRequests } from "../partnerServices";
import { loadNewReports } from "../../shared/services/sharedServices";
import { downloadReport } from "../../manufacturer/reports/reportServices";

import { normalize, schema } from "normalizr";
const resultSchema = new schema.Entity("results");
const resultListSchema = [resultSchema];

const initialState = {
  messageFromPvelPartner: {},
  messageFromPvelPartnerLoading: false,
  messageFromPvelPartnerError: null,
  reports: {},
  reportsLoading: false,
  reportsError: null,
  reportRequests: {},
  reportRequestsLoading: false,
  reportRequestsError: null,
};

const dashboardPartnerSlice = createSlice({
  name: "dashboardPartner",
  initialState: initialState,
  reducers: {
    loadMessageFromPvelPending: (state, action) => {
      state.messageFromPvelPartnerLoading = true;
      state.messageFromPvelPartnerError = null;
    },
    loadMessageFromPvelFulFilled: (state, action) => {
      state.messageFromPvelPartnerLoading = false;
      state.messageFromPvelPartner = action.payload.results[0];
    },
    loadMessageFromPvelRejected: (state, action) => {
      state.messageFromPvelPartnerLoading = false;
      state.messageFromPvelPartnerError = action.payload;
    },
    loadReportsPartnersPending: (state, action) => {
      state.reports = {};
      state.reportsLoading = true;
      state.reportsError = null;
    },
    loadReportPartnersFulFilled: (state, action) => {
      state.reportsLoading = false;
      state.reports = normalize(action.payload, resultListSchema);
    },
    loadReportPartnersRejected: (state, action) => {
      state.reportsLoading = false;
      state.reportsError = action.payload;
    },
    loadReportsRequestsPartnersPending: (state, action) => {
      state.reportRequests = {};
      state.reportsLoading = true;
      state.reportRequestsError = null;
    },
    loadReportsRequestsPartnersFulFilled: (state, action) => {
      state.reportRequestsLoading = false;
      state.reportRequests = normalize(action.payload, resultListSchema);
    },
    loadReportsRequestsPartnersRejected: (state, action) => {
      state.reportRequestsLoading = false;
      state.reportRequestsError = action.payload;
    },
    downloadedReportPartnersDashboardPending: (state, action) => {
      state.reports.entities.results[action.payload].loading = true;
    },
    downloadedReportPartnersDashboardFulfilled: (state, action) => {
      state.reports.entities.results[action.payload].loading = false;
    },
    downloadedReportPartnersDashboardRejected: (state, action) => {
      state.reports.entities.results[action.payload].loading = false;
    },
    downloadedReportRequestPartnersDashboardPending: (state, action) => {
      state.reportRequests.entities.results[action.payload].loading = true;
    },
    downloadedReportRequestPartnersDashboardFulfilled: (state, action) => {
      state.reportRequests.entities.results[action.payload].loading = false;
    },
    downloadedReportRequestPartnersDashboardRejected: (state, action) => {
      state.reportRequests.entities.results[action.payload].loading = false;
    },
  },
});

export const { actions, reducer } = dashboardPartnerSlice;

export const loadMessageFromPvelRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadMessageFromPvelPending());
    try {
      let result = await loadMessageFromPvel();
      dispatch(actions.loadMessageFromPvelFulFilled(result));
    } catch (err) {
      dispatch(actions.loadMessageFromPvelRejected(err));
    }
  };
};

export const loadReportsRequestsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadReportsRequestsPartnersPending());
    try {
      let result = await loadReportsRequests();
      dispatch(actions.loadReportsRequestsPartnersFulFilled(result));
    } catch (err) {
      dispatch(actions.loadReportsRequestsPartnersRejected(err));
    }
  };
};

export const loadReportsPartnersRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadReportsPartnersPending());
    try {
      let result = await loadNewReports();
      dispatch(actions.loadReportPartnersFulFilled(result));
    } catch (err) {
      dispatch(actions.loadReportPartnersRejected(err));
    }
  };
};

export const downloadedReportPartnersDashboard = (report) => {
  return async (dispatch) => {
    dispatch(actions.downloadedReportPartnersDashboardPending(report.id));
    try {
      await downloadReport(report);
      dispatch(actions.downloadedReportPartnersDashboardFulfilled(report.id));
    } catch (err) {
      dispatch(actions.downloadedReportPartnersDashboardRejected(err));
    }
  };
};

export const downloadedReportRequestPartnersDashboard = (report) => {
  return async (dispatch) => {
    dispatch(
      actions.downloadedReportRequestPartnersDashboardPending(report.id),
    );
    try {
      await downloadReport(report);
      dispatch(
        actions.downloadedReportRequestPartnersDashboardFulfilled(report.id),
      );
    } catch (err) {
      dispatch(actions.downloadedReportRequestPartnersDashboardRejected(err));
    }
  };
};
