import React from "react";
import { useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../../util/authUtil";
import ClientMenu from "./ClientMenu";
import { FilterReportsContainer } from "../filterReports/FilterReports/FilterReportsContainer";
import { DashboardPartnerView } from "../dashboard/DashboardPartnerView";
import { TabList } from "../TabList";
import { RequestedReportsContainer } from "../partner/MyReportRequests/RequestedReportsContainer";
import { IdleTimerContainer } from "features/common/IdleTimerContainer";
import { MyProjectsContainer } from "features/shared/projects/MyProjectsContainer";

export const Client = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <ClientMenu />
      {/* <IdleTimerContainer /> */}
      <PrivateRoute exact path={`${path}`} component={DashboardPartnerView} />
      <PrivateRoute
        path={`${path}/partners-and-manufacturers`}
        component={TabList}
      />
      <PrivateRoute
        path={`${path}/my-projects`}
        component={MyProjectsContainer}
      />
      <PrivateRoute
        path={`${path}/find-a-report`}
        component={FilterReportsContainer}
      />
      <PrivateRoute
        path={`${path}/my-reports`}
        component={RequestedReportsContainer}
      />
    </div>
  );
};
