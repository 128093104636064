import React from "react";

export const usePagination = (params) => {
  let { actionCreator, limit } = params;

  const handleChangePage = React.useCallback(
    (event, page) => {
      let offset = limit * page;
      let updatedParams = { ...params, page, offset };
      actionCreator(updatedParams);
    },
    [params, actionCreator, limit],
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      let rowsPerPageValue = parseInt(event.target.value);
      let updatedParams = {
        ...params,
        offset: 0,
        limit: rowsPerPageValue,
        page: 0,
      };
      actionCreator(updatedParams);
    },
    [params, actionCreator],
  );

  return { handleChangePage, handleChangeRowsPerPage };
};
