import some from "lodash/some";

export const useSorting = (params) => {
  const { sortCriterias, getDataFunc } = params;

  const sortHandler = (sortCriteria) => () => {
    let requestedSorting = Object.assign({}, sortCriterias[sortCriteria]);
    if (
      sortCriterias[sortCriteria].active &&
      sortCriterias[sortCriteria].type === "asc"
    ) {
      requestedSorting.type = "desc";
    } else if (
      sortCriterias[sortCriteria].active &&
      sortCriterias[sortCriteria].type === "desc"
    ) {
      requestedSorting.type = "asc";
    } else if (!sortCriterias[sortCriteria].active) {
      requestedSorting = {
        ...requestedSorting,
        active: true,
      };
    }
    const updatedSortCriterias = {
      ...sortCriterias,
      [sortCriteria]: requestedSorting,
    };
    getDataFunc({ sort: updatedSortCriterias, clearSort: false });
  };

  const isSortingDisabled = () => !some(sortCriterias, ["active", true]);
  const clearSorting = () => {
    getDataFunc({ sort: null, clearSort: true });
  };
  return { sortHandler, clearSorting, isSortingDisabled };
};
