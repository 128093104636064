import { createSlice } from "@reduxjs/toolkit";
import {
  loadDownstreamPartners,
  loadManufacturers,
  followManufacturer,
  unFollowManufacturer,
} from "./partnerServices";

import { normalize, schema } from "normalizr";
const result = new schema.Entity("results");
const resultSchema = { results: [result] };

const initialState = {
  downstreamPartnersLoading: false,
  downstreamPartners: {},
  downstreamPartnersError: null,
  manufacturersLoading: false,
  manufacturers: {},
  manufacturersError: null,
  followManufacturerLoading: false,
  errorFollowManufacturer: null,
  successFollowManufacturer: false,
  unfollowManufacturerLoading: false,
  errorUnfollowManufacturer: null,
  successUnfollowManufacturer: false,
};

const partnerSlice = createSlice({
  name: "partner",
  initialState: initialState,
  reducers: {
    loadDownstreamPartnersPending: (state, action) => {
      state.downstreamPartners = {};
      state.downstreamPartnersLoading = true;
      state.downstreamPartnersError = null;
    },
    loadDownstreamPartnersFulfilled: (state, action) => {
      state.downstreamPartnersLoading = false;
      state.downstreamPartners = normalize(action.payload, resultSchema);
    },
    loadDownstreamPartnersRejected: (state, action) => {
      state.downstreamPartnersLoading = false;
      state.downstreamPartnersError = action.payload;
    },
    loadManufacturersPending: (state, action) => {
      state.manufacturers = {};
      state.manufacturersLoading = true;
      state.manufacturersError = null;
    },
    loadManufacturersFulfilled: (state, action) => {
      state.manufacturersLoading = false;
      state.manufacturers = normalize(action.payload, resultSchema);
    },
    loadManufacturersRejected: (state, action) => {
      state.manufacturersLoading = false;
      state.manufacturersError = action.payload;
    },
    followedManufacturerPending: (state, action) => {
      state.followManufacturerLoading = true;
      state.successFollowManufacturer = false;
      state.errorFollowManufacturer = null;
    },
    followedManufacturerFulfilled: (state, action) => {
      state.followManufacturerLoading = false;
      state.successFollowManufacturer = true;
      state.manufacturers.entities.results[
        action.payload.manufacturerId
      ].following = true;
    },
    followedManufacturerRejected: (state, action) => {
      state.followManufacturerLoading = false;
      state.errorFollowManufacturer = action.payload;
    },
    unfollowedManufacturerPending: (state, action) => {
      state.unfollowManufacturerLoading = true;
      state.successUnfollowManufacturer = false;
      state.errorUnfollowManufacturer = null;
    },
    unfollowedManufacturerFulFilled: (state, action) => {
      state.unfollowManufacturerLoading = false;
      state.successUnfollowManufacturer = true;
      state.manufacturers.entities.results[
        action.payload.manufacturerId
      ].following = false;
    },
    unfollowedManufacturerRejected: (state, action) => {
      state.unfollowManufacturerLoading = false;
      state.errorUnfollowManufacturer = action.payload;
    },
    resetErrorFollowManufacturerRequested: (state, action) => {
      state.followManufacturerLoading = false;
      state.successFollowManufacturer = false;
      state.successUnfollowManufacturer = false;
      state.errorFollowManufacturer = null;
    },
  },
});

export const { actions, reducer } = partnerSlice;

// Partner Thunks

export const loadDownstreamPartnersRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadDownstreamPartnersPending());
    try {
      let result = await loadDownstreamPartners();
      dispatch(actions.loadDownstreamPartnersFulfilled(result));
    } catch (err) {
      dispatch(actions.loadDownstreamPartnersRejected(err));
    }
  };
};

export const loadManufacturersRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadManufacturersPending());
    try {
      let result = await loadManufacturers();
      dispatch(actions.loadManufacturersFulfilled(result));
    } catch (err) {
      dispatch(actions.loadManufacturersRejected(err));
    }
  };
};

export const followedManufacturer = (manufacturerId) => {
  return async (dispatch) => {
    dispatch(actions.followedManufacturerPending());
    try {
      await followManufacturer(manufacturerId);
      dispatch(actions.followedManufacturerFulfilled({ manufacturerId }));
    } catch (err) {
      dispatch(actions.followedManufacturerRejected(err));
    }
  };
};

export const unfollowedManufacturer = (manufacturerId) => {
  return async (dispatch) => {
    dispatch(actions.unfollowedManufacturerPending());
    try {
      await unFollowManufacturer(manufacturerId);
      dispatch(actions.unfollowedManufacturerFulFilled({ manufacturerId }));
    } catch (err) {
      dispatch(actions.unfollowedManufacturerRejected(err));
    }
  };
};
