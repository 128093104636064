import React from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { loadRequestedReportsRequested, downloadedRequestedReport } from "..";

import { RequestedReportsView } from "./RequestedReportsView";

export const RequestedReportsContainer = () => {
  const requestedReportsLoading = useSelector(
    (state) => state.partnerReports.requestedReportsLoading,
  );
  const requestedReports = useSelector(
    (state) => state.partnerReports.requestedReports,
  );
  const requestedReportsCurrentPage = useSelector(
    (state) => state.partnerReports.requestedReportsCurrentPage,
  );
  const requestedReportsCount = useSelector(
    (state) => state.partnerReports.requestedReportsCount,
  );
  const requestedReportsRowsPerPage = useSelector(
    (state) => state.partnerReports.requestedReportsRowsPerPage,
  );

  const errorRequestedReports = useSelector(
    (state) => state.partnerReports.errorRequestedReports,
  );
  const requestedReportsSortCriterias = useSelector(
    (state) => state.partnerReports.requestedReportsSortCriterias,
  );

  const dispatch = useDispatch();

  const onLoadRequestedReports = React.useCallback(
    (params) => dispatch(loadRequestedReportsRequested(params)),
    [dispatch],
  );

  const onDownloadReport = React.useCallback(
    (report) => dispatch(downloadedRequestedReport(report)),
    [dispatch],
  );

  React.useEffect(() => {
    if (isEmpty(requestedReports)) {
      onLoadRequestedReports({
        page: null,
        limit: null,
        offset: null,
        sort: null,
        clearSort: false,
      });
    }
    window.scrollTo(0, 0);
  }, [onLoadRequestedReports]);

  return (
    <RequestedReportsView
      onLoadRequestedReports={onLoadRequestedReports}
      requestedReports={requestedReports}
      requestedReportsCount={requestedReportsCount}
      requestedReportsRowsPerPage={requestedReportsRowsPerPage}
      requestedReportsCurrentPage={requestedReportsCurrentPage}
      requestedReportsLoading={requestedReportsLoading}
      onDownloadReport={onDownloadReport}
      errorRequestedReports={errorRequestedReports}
      requestedReportsSortCriterias={requestedReportsSortCriterias}
    />
  );
};
