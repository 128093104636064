import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadManufacturersRequested,
  followedManufacturer,
  partnerActions,
  unfollowedManufacturer,
} from "../../downstream";
import { selectFilteredManufacturers } from "../../downstream/partnerSelectors";
import ManufacturersView from "./ManufacturersView";
import isEmpty from "lodash/isEmpty";

export const ManufacturersContainer = () => {
  const [search, setSearch] = useState("");

  const [manufacturerIdFollowed, setManufacturerIdFollowed] = useState("");

  const manufacturersLoading = useSelector(
    (state) => state.partner.manufacturersLoading,
  );
  const filteredManufacturers = useSelector((state) =>
    selectFilteredManufacturers(state, search),
  );

  const followManufacturerLoading = useSelector(
    (state) => state.partner.followManufacturerLoading,
  );

  const manufacturersError = useSelector(
    (state) => state.partner.manufacturersError,
  );

  const errorFollowManufacturer = useSelector(
    (state) => state.partner.errorFollowManufacturer,
  );

  const successFollowManufacturer = useSelector(
    (state) => state.partner.successFollowManufacturer,
  );

  const successUnfollowManufacturer = useSelector(
    (state) => state.partner.successUnfollowManufacturer,
  );

  const dispatch = useDispatch();

  const onSearchManufacturer = useCallback(
    (searchText) => setSearch(searchText),
    [search],
  );

  const onFollowedManufacturer = useCallback(
    (manufacturerId) => {
      setManufacturerIdFollowed(manufacturerId);
      dispatch(followedManufacturer(manufacturerId));
    },
    [dispatch],
  );

  const onUnfollowedManufacturer = useCallback(
    (manufacturerId) => {
      dispatch(unfollowedManufacturer(manufacturerId));
    },
    [dispatch],
  );

  const resetErrorFollowManufacturerRequested = useCallback(() => {
    dispatch(partnerActions.resetErrorFollowManufacturerRequested());
  }, [dispatch]);

  const onLoadManufacturersRequested = useCallback(() => {
    if (isEmpty(filteredManufacturers)) {
      dispatch(loadManufacturersRequested());
    }
  }, [dispatch]);

  useEffect(() => {
    onLoadManufacturersRequested();
  }, [onLoadManufacturersRequested]);

  return (
    <ManufacturersView
      onFollowedManufacturer={onFollowedManufacturer}
      onUnfollowedManufacturer={onUnfollowedManufacturer}
      manufacturersLoading={manufacturersLoading}
      successUnfollowManufacturer={successUnfollowManufacturer}
      filteredManufacturers={filteredManufacturers}
      onSearchManufacturer={onSearchManufacturer}
      followManufacturerLoading={followManufacturerLoading}
      manufacturerIdFollowed={manufacturerIdFollowed}
      manufacturersError={manufacturersError}
      loadManufacturersRequested={loadManufacturersRequested}
      errorFollowManufacturer={errorFollowManufacturer}
      successFollowManufacturer={successFollowManufacturer}
      resetErrorFollowManufacturerRequested={
        resetErrorFollowManufacturerRequested
      }
    />
  );
};
