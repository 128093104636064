import React from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../assets/images/KiwaLogo.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Scorecards from "../common/Scorecards";
import Button from "@material-ui/core/Button";
import { UserOptionsContainer } from "../layout/User/UserOptionsContainer";
import { useClearButtonsStyles } from "../../themes/appStyles";
import { LogoButton } from "features/common/LogoButton";

const useStyles = makeStyles(() => ({
  bar: {
    backgroundColor: "#e9ecef",
  },
  list: {
    width: "100%",
  },
  listItem: {
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  breadcrumStyle: {
    margin: 20,
    color: "#676767",
  },
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();
  const location = useLocation();

  return (
    <AppBar position="static" style={theme.generalContainer}>
      <Toolbar variant="regular" className={classes.bar}>
        <LogoButton homeRoute="/pvel" />
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              variant="contained"
              component={Link}
              to="/pvel/users"
              className={
                location.pathname === "/pvel/users"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              Users
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/pvel/reports"
              className={
                location.pathname === "/pvel/reports"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              My Reports
            </Button>

            <Button
              variant="contained"
              component={Link}
              to="/pvel/projects"
              className={
                location.pathname === "/pvel/projects"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              My Projects
            </Button>

            <Button
              variant="contained"
              component={Link}
              to="/pvel/find-a-report"
              className={
                location.pathname === "/pvel/find-a-report"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              Find a report
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/pvel/partners-and-manufacturers"
              className={
                location.pathname === "/pvel/partners-and-manufacturers"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              Partners and Manufacturers
            </Button>

            <Scorecards />

            <UserOptionsContainer />
          </ListItem>
        </List>
      </Toolbar>
    </AppBar>
  );
};
