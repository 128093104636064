import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  loadPendingReportsRequested,
  disclosedReport,
  downloadedPendingReport,
  reportActions,
} from "../";

import { MyPendingReportsView } from "./MyPendingReportsView";

export const MyPendingReportsContainer = ({ executeScroll }) => {
  const pendingReportsLoading = useSelector(
    (state) => state.report.pendingReportsLoading,
  );
  const pendingReports = useSelector((state) => state.report.pendingReports);
  const pendingReportsCurrentPage = useSelector(
    (state) => state.report.pendingReportsCurrentPage,
  );
  const pendingReportsCount = useSelector(
    (state) => state.report.pendingReportsCount,
  );
  const pendingReportsRowsPerPage = useSelector(
    (state) => state.report.pendingReportsRowsPerPage,
  );

  const errorPendingReports = useSelector(
    (state) => state.report.errorPendingReports,
  );

  const errorDisclosingReport = useSelector(
    (state) => state.report.errorDisclosingReport,
  );

  const discloseReportLoading = useSelector(
    (state) => state.report.discloseReportLoading,
  );

  const successDisclosingReport = useSelector(
    (state) => state.report.successDisclosingReport,
  );

  const processedReportsRowsPerPage = useSelector(
    (state) => state.report.processedReportsRowsPerPage,
  );
  const processedReportsSortCriterias = useSelector(
    (state) => state.report.processedReportsSortCriterias,
  );
  const pendingReportsSortCriterias = useSelector(
    (state) => state.report.pendingReportsSortCriterias,
  );
  const currentProjectFilter = useSelector(
    (state) => state.report.currentProjectFilter,
  );

  const dispatch = useDispatch();

  const onLoadPendingReports = useCallback(
    (params) => dispatch(loadPendingReportsRequested(params)),
    [dispatch],
  );

  const onDisclosureSelection = useCallback(
    (reportId, disclosureAction, params) =>
      dispatch(disclosedReport(reportId, disclosureAction, params)),
    [dispatch],
  );

  const onDownloadReport = useCallback(
    (report) => dispatch(downloadedPendingReport(report)),
    [dispatch],
  );

  const onResetErrorsPendingReports = useCallback(
    () => dispatch(reportActions.resetErrorsPendingReportsRequested()),
    [dispatch],
  );

  useEffect(() => {
    if (isEmpty(pendingReports)) {
      onLoadPendingReports({
        page: null,
        limit: null,
        offset: null,
        sort: null,
        clearSort: false,
      });
    }
  }, [onLoadPendingReports]);

  return (
    <MyPendingReportsView
      onLoadPendingReports={onLoadPendingReports}
      pendingReports={pendingReports}
      pendingReportsCount={pendingReportsCount}
      pendingReportsRowsPerPage={pendingReportsRowsPerPage}
      pendingReportsCurrentPage={pendingReportsCurrentPage}
      pendingReportsLoading={pendingReportsLoading}
      onDisclosureSelection={onDisclosureSelection}
      onDownloadReport={onDownloadReport}
      errorPendingReports={errorPendingReports}
      errorDisclosingReport={errorDisclosingReport}
      successDisclosingReport={successDisclosingReport}
      discloseReportLoading={discloseReportLoading}
      onResetErrorsPendingReports={onResetErrorsPendingReports}
      processedReportsSortCriterias={processedReportsSortCriterias}
      processedReportsRowsPerPage={processedReportsRowsPerPage}
      pendingReportsSortCriterias={pendingReportsSortCriterias}
      currentProjectFilter={currentProjectFilter}
      executeScroll={executeScroll}
    />
  );
};
