import React, { useState } from "react";
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { MaybeLoading } from "../../../common/MaybeLoading";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import { FetchError } from "features/common/FetchError";
import { useSorting } from "features/common/hooks/useSorting";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import { ReportRequestDialogView } from "../../../shared/reportRequestStatus/ReportRequestDialogView";
import has from "lodash/has";
import Link from "@material-ui/core/Link";
import { DisclosureModalAuthorization } from "../../../shared/manufacturerDiclosureAuthorization/DisclosureModalAuthorization";

export const PendingRequestsView = (props) => {
  const {
    onLoadPendingRequests,
    pendingRequests,
    pendingRequestsLoading,
    pendingRequestsSortCriterias,
    processedRequestsSortCriterias,
    errorPendingRequests,
    onRequestStatusChanged,
    errorRequestStatusChanged,
    successRequestStatusChanged,
    onResetErrorsRequestStatusChanged,
    processedRequestsRowsPerPage,
  } = props;

  React.useEffect(() => {
    checkForRequestStatus();
    onResetErrorsRequestStatusChanged();
  }, [errorRequestStatusChanged, successRequestStatusChanged]);

  const theme = useTheme();
  const classes = useClearButtonsStyles();
  const [openRequestDialog, setOpenRequestDialog] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [isApprove, setIsApprove] = React.useState(false);
  const [openDisclosureModal, setOpenDisclosureModal] = useState(false);
  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: pendingRequestsSortCriterias,
    getDataFunc: refreshRequests,
  });

  const checkForRequestStatus = () => {
    if (!isEmpty(errorRequestStatusChanged)) {
      toast.error(errorRequestStatusChanged.detail);
    } else if (successRequestStatusChanged) {
      toast.success("Report request was processed successfully.");
    }
  };

  const handleClick = (id) => (event) => {
    let selectedRequests = {};
    if (isSelected(id)) {
      let { [id]: _, ...result } = selected;
      selectedRequests = result;
    } else {
      let currentRequest = pendingRequests.entities.results[id];
      selectedRequests = {
        ...selected,
        [id]: currentRequest,
      };
    }
    setSelected(selectedRequests);
  };

  const handleOpenDisclosureDialog = (e) => {
    e.preventDefault();
    setOpenDisclosureModal(true);
  };

  const handleCloseDisclosureDialog = () => {
    setOpenDisclosureModal(false);
  };

  const handleSendRequestStatus = (requests) => {
    setSelected([]);
    onRequestStatusChanged(requests, {
      isApprove,
      sortPendingRequests: pendingRequestsSortCriterias,
      sortProcessedRequests: processedRequestsSortCriterias,
      processedRequestsRowsPerPage,
    });
    setOpenRequestDialog(false);
  };

  const isSelected = (id) => has(selected, id);

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={pendingRequestsLoading} />
      </TableCell>
    </TableRow>
  );

  const handleOpenRequestDialog = (isApprove) => () => {
    setIsApprove(isApprove);
    setOpenRequestDialog(true);
  };

  const handleCancelRequestDialog = () => {
    setOpenRequestDialog(false);
  };

  function refreshRequests(params) {
    onLoadPendingRequests(params);
  }

  const hasRecords = () =>
    !isEmpty(pendingRequests) && !isEmpty(pendingRequests.entities);

  const renderTableBody = () => {
    if (!isEmpty(pendingRequests) && !isEmpty(pendingRequests.entities)) {
      return pendingRequests.result.results.map((requestId) => {
        let currentRequest = pendingRequests.entities.results[requestId];
        const requestorCompany =
          currentRequest.requestor_company[0].company_type_text.split("(");
        const isItemSelected = isSelected(requestId);
        const labelId = `request-table-checkbox-${requestId}`;
        return (
          <TableRow
            hover
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={requestId}
            selected={isItemSelected}
          >
            {currentRequest.status !== null ? (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  onChange={handleClick(requestId)}
                />
              </TableCell>
            ) : (
              <TableCell />
            )}
            <TableCell>
              {new Date(currentRequest.created).toLocaleDateString()}
            </TableCell>
            <TableCell>{currentRequest.document.id}</TableCell>
            <TableCell>{currentRequest.document.title}</TableCell>
            <TableCell>
              {currentRequest.requestor_company[0].display_name}
            </TableCell>
            <TableCell>{requestorCompany[0]}</TableCell>
            <TableCell>{currentRequest.requestor_comment}</TableCell>
            <TableCell>{currentRequest.document.type_text}</TableCell>
          </TableRow>
        );
      });
    }
  };

  if (errorPendingRequests && !pendingRequests.length) {
    return (
      <FetchError
        title="Error while loading data for pending requests. Expand for more details"
        error={errorPendingRequests}
        onRetry={() =>
          onLoadPendingRequests({ sort: pendingRequestsSortCriterias })
        }
      />
    );
  }

  return (
    <div style={theme.mainContainer}>
      <Typography style={theme.pvelH2}>Pending Requests</Typography>
      <Typography style={theme.paragraph}>
        Kiwa PVEL Downstream Partners have requested access to your reports as
        indicated below. The primary project contact may respond by clicking the
        check boxes and approving or rejecting at their discretion. Check boxes
        are only visible for projects for which you are the primary contact. By
        approving report requests, you authorize the named Downstream Partner(s)
        to use such Data for any purpose.{" "}
        <Link
          style={theme.downloadLink}
          href=""
          onClick={handleOpenDisclosureDialog}
        >
          Click here
        </Link>
        for our full disclosure terms.
      </Typography>
      <Grid
        container
        direction="row"
        justify="flex-end"
        item
        xs={12}
        alignItems="center"
      >
        <Button
          variant="outlined"
          className={classes.clearButtons}
          onClick={clearSorting}
          disabled={isSortingDisabled()}
        >
          Clear Sorting
        </Button>
      </Grid>
      <Paper style={theme.tableContainer}>
        <TableContainer style={theme.table}>
          <Table stickyHeader aria-label="My Report Requests Table">
            <TableHead>
              <TableRow>
                <TableCell
                  padding="default"
                  style={theme.tableHeader}
                ></TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={pendingRequestsSortCriterias.requestDate.active}
                    direction={
                      pendingRequestsSortCriterias.requestDate.active
                        ? pendingRequestsSortCriterias.requestDate.type
                        : "asc"
                    }
                    onClick={sortHandler("requestDate")}
                  >
                    Request Date
                  </TableSortLabel>
                </TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  Report Id
                </TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  Report Title
                </TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  Requestor Company
                </TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  Requestor Company Type
                </TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={
                      pendingRequestsSortCriterias.requestorComments.active
                    }
                    direction={
                      pendingRequestsSortCriterias.requestorComments.active
                        ? pendingRequestsSortCriterias.requestorComments.type
                        : "asc"
                    }
                    onClick={sortHandler("requestorComments")}
                  >
                    Requestor Comments
                  </TableSortLabel>
                </TableCell>
                <TableCell padding="default" style={theme.tableHeader}>
                  Report Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingRequestsLoading ? renderLoader() : renderTableBody()}
            </TableBody>
            <TableFooter>
              <TableRow></TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
      <div>
        <Button
          style={theme.buttonSubmit}
          variant="contained"
          onClick={handleOpenRequestDialog(true)}
          disabled={isEmpty(selected)}
        >
          Approve
        </Button>
        <Button
          className={classes.clearButtons}
          variant="outlined"
          onClick={handleOpenRequestDialog(false)}
          disabled={isEmpty(selected)}
        >
          Reject
        </Button>
      </div>
      {!isEmpty(selected) && !isEmpty(pendingRequests) && (
        <ReportRequestDialogView
          open={openRequestDialog}
          data={pendingRequests}
          selectedData={selected}
          dataType={"Request"}
          dialogTitle={
            isApprove ? "Approval Confirmation" : "Rejection Confirmation"
          }
          dialogContentText="Please add an optional message for each request"
          onCancelRequestDialog={handleCancelRequestDialog}
          onSubmitData={handleSendRequestStatus}
        />
      )}
      <DisclosureModalAuthorization
        open={openDisclosureModal}
        handleClose={handleCloseDisclosureDialog}
      />
    </div>
  );
};

PendingRequestsView.propTypes = {
  onLoadPendingRequests: PropTypes.func.isRequired,
  pendingRequests: PropTypes.array.isRequired,
  pendingRequestsLoading: PropTypes.bool.isRequired,
  errorPendingRequests: PropTypes.string.isRequired,
  requestStatusChanged: PropTypes.func.isRequired,
  successSentRequestsStatus: PropTypes.any,
  errorSentRequestsStatus: PropTypes.any,
  onResetErrorsSentRequestsStatusRequested: PropTypes.any,
};
