import React, { useState } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { Link } from "react-router-dom";
import { useClearButtonsStyles } from "../../../../themes/appStyles.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  loadActiveRequestsRequested,
  loadNewReportsRequested,
  loadExpiredRequestsRequested,
  loadAllPendingReportsRequested,
} from "../../../manufacturer/dashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    textAlign: "center",
    "& > * ": {
      margin: theme.spacing(1),
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  container: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(2),
    height: 300,
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  items: {
    padding: theme.spacing(3),
    color: "#1d241d !important",
    "&:hover": {
      background: "linear-gradient(green,black)",
      "& $child": {
        color: "#FFFFFF",
      },
    },
  },
  child: {
    color: "#1d243d",
  },
}));

export const PVELSummaryActivity = () => {
  const [showSummary, setShowSummary] = useState(false); // State to control visibility

  const newReportsCount = useSelector(
    (state) => state.dashboard.newReportsCount
  );
  const newReportsCountLoading = useSelector(
    (state) => state.dashboard.newReportsCountLoading
  );

  const activeRequestsCount = useSelector(
    (state) => state.dashboard.activeRequestsCount
  );
  const activeRequestsCountLoading = useSelector(
    (state) => state.dashboard.activeRequestsCountLoading
  );

  const expiredRequestsCount = useSelector(
    (state) => state.dashboard.expiredRequestsCount
  );
  const expiredRequestsCountLoading = useSelector(
    (state) => state.dashboard.expiredRequestsLoading
  );

  const pendingReportsCount = useSelector(
    (state) => state.dashboard.pendingReportsCount
  );
  const pendingReportsCountLoading = useSelector(
    (state) => state.dashboard.pendingReportsLoading
  );

  const dispatch = useDispatch();

  const onLoadActiveRequestsRequested = React.useCallback(() => {
    if (activeRequestsCount === null) {
      dispatch(loadActiveRequestsRequested());
    }
  }, [dispatch]);

  const onLoadNewReportsRequested = React.useCallback(() => {
    if (newReportsCount === null) {
      dispatch(loadNewReportsRequested());
    }
  }, [dispatch]);

  const onLoadExpiredRequestsRequested = React.useCallback(() => {
    if (expiredRequestsCount === null) {
      dispatch(loadExpiredRequestsRequested());
    }
  }, [dispatch]);

  const onLoadAllPendingReportsRequested = React.useCallback(() => {
    if (newReportsCount === null) {
      dispatch(loadAllPendingReportsRequested());
    }
  }, [dispatch]);

  React.useEffect(() => {
    onLoadActiveRequestsRequested();
    onLoadNewReportsRequested();
    onLoadExpiredRequestsRequested();
    onLoadAllPendingReportsRequested();
  }, [
    onLoadActiveRequestsRequested,
    onLoadNewReportsRequested,
    onLoadExpiredRequestsRequested,
    onLoadAllPendingReportsRequested,
  ]);

  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();

  const renderNewReportsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>{newReportsCount}</Typography>
      <Tooltip title="New reports uploaded in the last 30 days">
        <Typography
          component={Link}
          className={newClasses.dashboardLinks}
          to="/manufacturer/reports"
        >
          New Reports
        </Typography>
      </Tooltip>
    </>
  );

  const renderActiveRequestsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>
        {activeRequestsCount}
      </Typography>
      <Typography className={newClasses.dashboardLinks}>
        Active Requests
      </Typography>
    </>
  );

  const renderExpiredRequestsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>
        {expiredRequestsCount}
      </Typography>
      <Typography className={newClasses.dashboardLinks}>
        Expired Requests
      </Typography>
    </>
  );

  const renderPendingReportsCount = () => (
    <>
      <Typography className={newClasses.pvelH4}>
        {pendingReportsCount}
      </Typography>
      <Typography className={newClasses.dashboardLinks}>
        Pending Reports
      </Typography>
    </>
  );

  const renderLoading = (loading) => <MaybeLoading loading={loading} />;

  return (
    <div>
      {showSummary && ( // Conditionally rendering based on state
        <Container className={classes.container}>
          <Typography style={theme.pvelH3}>Summary of Activity</Typography>
          <Container className={classes.root}>
            <Paper elevation={3} variant="outlined" className={newClasses.items}>
              {newReportsCountLoading
                ? renderLoading(newReportsCountLoading)
                : renderNewReportsCount()}
            </Paper>
            <Paper elevation={3} variant="outlined" className={newClasses.items}>
              {activeRequestsCountLoading
                ? renderLoading(activeRequestsCountLoading)
                : renderActiveRequestsCount()}
            </Paper>
            <Paper elevation={3} variant="outlined" className={newClasses.items}>
              {expiredRequestsCountLoading
                ? renderLoading(expiredRequestsCountLoading)
                : renderExpiredRequestsCount()}
            </Paper>
            <Paper elevation={3} variant="outlined" className={newClasses.items}>
              {pendingReportsCountLoading
                ? renderLoading(pendingReportsCountLoading)
                : renderPendingReportsCount()}
            </Paper>
          </Container>
        </Container>
      )}
    </div>
  );
};
