import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import {
  loadPendingRequestsRequested,
  requestStatusChanged,
  requestActions,
} from "../";

import { PendingRequestsView } from "./PendingRequestsView";

export const PendingRequestsContainer = () => {
  const pendingRequestsLoading = useSelector(
    (state) => state.request.pendingRequestsLoading,
  );
  const pendingRequests = useSelector((state) => state.request.pendingRequests);

  const errorPendingRequests = useSelector(
    (state) => state.request.errorPendingRequests,
  );

  const requestStatusChangedLoading = useSelector(
    (state) => state.request.requestStatusChangedLoading,
  );

  const errorRequestStatusChanged = useSelector(
    (state) => state.request.errorRequestStatusChanged,
  );

  const successRequestStatusChanged = useSelector(
    (state) => state.request.successRequestStatusChanged,
  );

  const pendingRequestsSortCriterias = useSelector(
    (state) => state.request.pendingRequestsSortCriterias,
  );

  const processedRequestsSortCriterias = useSelector(
    (state) => state.request.processedRequestsSortCriterias,
  );
  const processedRequestsRowsPerPage = useSelector(
    (state) => state.request.processedRequestsRowsPerPage,
  );

  const dispatch = useDispatch();

  const onLoadPendingRequests = useCallback(
    (params) => dispatch(loadPendingRequestsRequested(params)),
    [dispatch],
  );

  const onRequestStatusChanged = useCallback(
    (requests, params) => dispatch(requestStatusChanged(requests, params)),
    [dispatch],
  );

  const onResetErrorsRequestStatusChanged = useCallback(
    () => dispatch(requestActions.resetErrorsRequestStatusChanged()),
    [dispatch],
  );

  useEffect(() => {
    if (isEmpty(pendingRequests)) {
      onLoadPendingRequests({ sort: null, clearSort: false });
    }
  }, [onLoadPendingRequests]);

  return (
    <PendingRequestsView
      onLoadPendingRequests={onLoadPendingRequests}
      pendingRequests={pendingRequests}
      pendingRequestsLoading={pendingRequestsLoading}
      errorPendingRequests={errorPendingRequests}
      onRequestStatusChanged={onRequestStatusChanged}
      requestStatusChangedLoading={requestStatusChangedLoading}
      errorRequestStatusChanged={errorRequestStatusChanged}
      successRequestStatusChanged={successRequestStatusChanged}
      onResetErrorsRequestStatusChanged={onResetErrorsRequestStatusChanged}
      pendingRequestsSortCriterias={pendingRequestsSortCriterias}
      processedRequestsSortCriterias={processedRequestsSortCriterias}
      processedRequestsRowsPerPage={processedRequestsRowsPerPage}
    />
  );
};
