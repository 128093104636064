import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { TablePaginationActions } from "../../../common/TablePaginationActions";
import { Typography } from "@material-ui/core";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import TelegramIcon from "@material-ui/icons/Telegram";
import Tooltip from "@material-ui/core/Tooltip";
import { FetchError } from "features/common/FetchError";
import { useSorting } from "features/common/hooks/useSorting";
import { usePagination } from "../../../common/hooks/usePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import isEmpty from "lodash/isEmpty";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useClearButtonsStyles } from "../../../../themes/appStyles";

export const ProcessedRequestsView = (props) => {
  const {
    onLoadProcessedRequests,
    processedRequests,
    processedRequestsCount,
    processedRequestsRowsPerPage,
    processedRequestsCurrentPage,
    processedRequestsLoading,
    errorProcessedRequests,
    processedRequestsSortCriterias,
  } = props;
  const theme = useTheme();
  const classes = useClearButtonsStyles();

  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: processedRequestsSortCriterias,
    getDataFunc: refreshRequests,
  });

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadProcessedRequests,
    limit: processedRequestsRowsPerPage,
    sort: processedRequestsSortCriterias,
  });
  function refreshRequests({ sort, clearSort }) {
    onLoadProcessedRequests({
      page: processedRequestsCurrentPage,
      limit: processedRequestsRowsPerPage,
      offset: processedRequestsCurrentPage * processedRequestsRowsPerPage,
      sort,
      clearSort,
    });
  }

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={processedRequestsLoading} />
      </TableCell>
    </TableRow>
  );

  const renderIconStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return (
          <Tooltip title="Request Sent">
            <TelegramIcon />
          </Tooltip>
        );
      case "APPROVED":
        return (
          <Tooltip title="Request Approved">
            <Done />
          </Tooltip>
        );
      case "REFUSED":
        return (
          <Tooltip title="Request Denied">
            <Close />
          </Tooltip>
        );
      default:
        return <div />;
    }
  };
  const hasRecords = () => !isEmpty(processedRequests);

  const renderTableBody = () =>
    processedRequests.map((request, idx) => {
      const requestorCompany =
        request.requestor_company[0].company_type_text.split("(");
      return (
        <TableRow key={`my-processed-request-${idx}`}>
          <TableCell align="left">{renderIconStatus(request.status)}</TableCell>
          <TableCell>
            {new Date(request.created).toLocaleDateString()}
          </TableCell>
          <TableCell align="left">{request.document.id}</TableCell>
          <TableCell align="left">{request.document.title}</TableCell>
          <TableCell align="left">
            {request.requestor_company[0].display_name}
          </TableCell>
          <TableCell align="left">{requestorCompany[0]}</TableCell>
          <TableCell align="left">{request.approver_comment}</TableCell>
          <TableCell align="left">{request.document.type_text}</TableCell>
        </TableRow>
      );
    });

  if (errorProcessedRequests && !processedRequests.length) {
    return (
      <FetchError
        title="Error while loading data for processed requests. Expand for more details"
        error={errorProcessedRequests}
        onRetry={() => refreshRequests({ sort: null, clearSort: false })}
      />
    );
  }

  return (
    <div style={theme.mainContainer}>
      <Typography variant="subtitle1" style={theme.pvelH2} gutterBottom>
        Processed Requests
      </Typography>
      <Typography style={theme.paragraph}>
        The reports below have been successfully approved or rejected as
        indicated by the symbol in the Status column.
      </Typography>
      <Grid
        container
        direction="row"
        justify="flex-end"
        item
        xs={12}
        alignItems="center"
      >
        <Button
          variant="outlined"
          className={classes.clearButtons}
          onClick={clearSorting}
          disabled={isSortingDisabled()}
        >
          Clear Sorting
        </Button>
      </Grid>
      <Paper style={theme.tableContainer}>
        <TableContainer style={theme.table}>
          <Table stickyHeader aria-label="My Processed Requests Table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={processedRequestsSortCriterias.status.active}
                    direction={
                      processedRequestsSortCriterias.status.active
                        ? processedRequestsSortCriterias.status.type
                        : "asc"
                    }
                    onClick={sortHandler("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={processedRequestsSortCriterias.requestDate.active}
                    direction={
                      processedRequestsSortCriterias.requestDate.active
                        ? processedRequestsSortCriterias.requestDate.type
                        : "asc"
                    }
                    onClick={sortHandler("requestDate")}
                  >
                    Request Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Report Id
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Report Title
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Requestor Company
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Requestor Company Type
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  <TableSortLabel
                    disabled={!hasRecords()}
                    active={
                      processedRequestsSortCriterias.approverComments.active
                    }
                    direction={
                      processedRequestsSortCriterias.approverComments.active
                        ? processedRequestsSortCriterias.approverComments.type
                        : "asc"
                    }
                    onClick={sortHandler("approverComments")}
                  >
                    Approver Comments
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Report Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processedRequestsLoading ? renderLoader() : renderTableBody()}
            </TableBody>
            <TableFooter>
              <TableRow></TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={processedRequestsCount}
          rowsPerPage={processedRequestsRowsPerPage}
          page={processedRequestsCurrentPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
};

ProcessedRequestsView.propTypes = {
  onLoadProcessedRequests: PropTypes.func.isRequired,
  processedRequests: PropTypes.array.isRequired,
  processedRequestsCount: PropTypes.number.isRequired,
  processedRequestsRowsPerPage: PropTypes.number.isRequired,
  processedRequestsCurrentPage: PropTypes.number.isRequired,
  processedRequestsLoading: PropTypes.bool.isRequired,
};
