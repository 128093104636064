import isEmpty from "lodash/isEmpty";
import { createSelector } from "@reduxjs/toolkit";
import lodashFilter from "lodash/filter";

export const selectFilteredDownstreamPartners = createSelector(
  (state) => state.partner.downstreamPartners,
  (_, searchText) => searchText,
  (downstreamPartners, searchText) => {
    if (!isEmpty(searchText)) {
      const searchResultIds = lodashFilter(
        downstreamPartners?.result?.results,
        (partnerId) => {
          let currentPartner = downstreamPartners.entities.results[partnerId];
          return currentPartner.display_name
            .toLowerCase()
            .includes(searchText.toLowerCase());
        },
      );
      return searchResultIds.map((partnerId, idx) => {
        return downstreamPartners.entities.results[partnerId];
      });
    }
    return downstreamPartners?.result?.results?.map((partnerId, idx) => {
      return downstreamPartners.entities.results[partnerId];
    });
  },
);

export const selectFilteredManufacturers = createSelector(
  (state) => state.partner.manufacturers,
  (_, searchText) => searchText,
  (manufacturers, searchText) => {
    if (!isEmpty(searchText)) {
      const searchResultIds = lodashFilter(
        manufacturers?.result?.results,
        (partnerId) => {
          let currentManufacturer = manufacturers.entities.results[partnerId];
          return currentManufacturer.display_name
            .toLowerCase()
            .includes(searchText.toLowerCase());
        },
      );
      return searchResultIds.map((manufacturerId, idx) => {
        return manufacturers.entities.results[manufacturerId];
      });
    }
    return manufacturers?.result?.results?.map((manufacturerId, idx) => {
      return manufacturers.entities.results[manufacturerId];
    });
  },
);
