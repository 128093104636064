import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Scorecards from "../common/Scorecards";
import Button from "@material-ui/core/Button";
import { UserOptionsContainer } from "../layout/User/UserOptionsContainer";
import { useLocation, Link } from "react-router-dom";
import { useClearButtonsStyles } from "../../themes/appStyles";
import { LogoButton } from "features/common/LogoButton";

const useStyles = makeStyles(() => ({
  bar: {
    flexGrow: 1,
    backgroundColor: "#e9ecef",
  },
  list: {
    width: "100%",
  },
  listItem: {
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  scorecardsStyle: {
    color: "#30417f",
    padding: 10,
    background: "#e9ecef",
    borderRadius: 20,
  },
}));

export const ManufacturerHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();
  const location = useLocation();
  return (
    <AppBar position="static" style={theme.generalContainer}>
      <Toolbar variant="regular" className={classes.bar}>
        <LogoButton homeRoute="/manufacturer" />
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              variant="contained"
              component={Link}
              to="/manufacturer/reports"
              className={
                location.pathname === "/manufacturer/reports"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              My Reports
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/manufacturer/projects"
              className={
                location.pathname === "/manufacturer/projects"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              My Projects
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/manufacturer/requests"
              className={
                location.pathname === "/manufacturer/requests"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              Report Requests
            </Button>

            <Button
              variant="contained"
              component={Link}
              to="/manufacturer/partners"
              className={
                location.pathname === "/manufacturer/partners"
                  ? newClasses.activeButtons
                  : newClasses.headerLinks
              }
            >
              Downstream Partners
            </Button>

            <Scorecards />

            <UserOptionsContainer />
          </ListItem>
        </List>
      </Toolbar>
    </AppBar>
  );
};
