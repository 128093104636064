import React from "react";
import { useRouteMatch } from "react-router-dom";

import { MyReportsView } from "./reports/MyReportsView";
import { MyProjectsContainer } from "../shared/projects/MyProjectsContainer";
import { RequestsView } from "./requests/RequestsView";
import { DownstreamPartnersContainer } from "../downstream/downstreamPartners/DownstreamPartnersContainer";
import { DashboardView } from "./dashboard/DashboardView";
import { ManufacturerHeader } from "./ManufacturerHeader";

import { PrivateRoute } from "../../util/authUtil";
// import { IdleTimerContainer } from "features/common/IdleTimerContainer";

export const Manufacturer = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <ManufacturerHeader />
      {/* <IdleTimerContainer /> */}
      <PrivateRoute exact path={`${path}`} component={DashboardView} />
      <PrivateRoute path={`${path}/reports`} component={MyReportsView} />
      <PrivateRoute path={`${path}/requests`} component={RequestsView} />
      <PrivateRoute path={`${path}/projects`} component={MyProjectsContainer} />
      <PrivateRoute
        path={`${path}/partners`}
        component={DownstreamPartnersContainer}
      />
    </div>
  );
};
