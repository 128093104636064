import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Tooltip from "@material-ui/core/Tooltip";
import { useClearButtonsStyles } from "../../themes/appStyles";

export const ShowDetailsButton = (props) => {
  const { text, toggleCurrentView, isOpen, tooltipText } = props;
  const anchorRef = React.useRef(null);
  const classes = useClearButtonsStyles();

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item xs={12}>
        <ButtonGroup
          variant="outlined"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button disabled className={classes.buttonDisclosure}>
            {text}
          </Button>
          <Tooltip title={tooltipText} placement="right">
            <Button
              className={classes.buttonDisclosure}
              size="small"
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={toggleCurrentView}
            >
              {isOpen ? <AddCircle /> : <RemoveCircle />}
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};
