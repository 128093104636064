import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { MyPendingReportsContainer } from "./PendingReports/";
import { MyProcessedReportsContainer } from "./ProcessedReports/";
import { useScroll } from "../../../features/common/hooks/useScroll";

export const MyReportsView = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [executeScroll, htmlElRef] = useScroll();

  return (
    <Grid container style={theme.generalContainer}>
      <Grid item xs={12} style={theme.mainContainer}>
        <Typography variant="h4" style={theme.pvelH1}>
          My Reports
        </Typography>
        <Typography style={theme.paragraph}>
          {/* Access your company's PVEL reports below. */}
        </Typography>
        {/*<Typography style={theme.paragraph}>
          Note: Since PVEL was founded in 2010, the specific companies issuing
          our reports have evolved. PVEL operated as PV Evolution Labs from 2010
          until 2014 when it joined DNV GL. In 2019, PVEL relaunched as an
          independent entity. The logos and company names shown on older reports
          reflect this history.&nbsp;
          <Link
            href="https://www.pvel.com/dnv-gl-transfers-ownership-to-pvel-management-team/"
            target="_blank"
            rel="noopener noreferrer"
            style={theme.downloadLink}
          >
            Click here&nbsp;
          </Link>
          to learn more.
  </Typography>*/}
      </Grid>
      <Grid item xs={12}>
        <MyPendingReportsContainer executeScroll={executeScroll} />
      </Grid>
      <Grid item xs={12}>
        <MyProcessedReportsContainer scrollRef={htmlElRef} />
      </Grid>
    </Grid>
  );
};
