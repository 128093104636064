import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { MaybeLoading } from "../../common/MaybeLoading";
import { TablePaginationActions } from "../../common/TablePaginationActions";
import { FetchError } from "features/common/FetchError";
import { useSorting } from "features/common/hooks/useSorting";
import { usePagination } from "../../common/hooks/usePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import isEmpty from "lodash/isEmpty";

import { useClearButtonsStyles } from "../../../themes/appStyles";
import { loadUsersRequested } from "./userSlice";

export const UsersView = () => {
  const users = useSelector((state) => state.user.users);
  const usersCount = useSelector((state) => state.user.usersCount);
  const usersLoading = useSelector((state) => state.user.usersLoading);
  const usersCurrentPage = useSelector((state) => state.user.usersCurrentPage);
  const usersRowsPerPage = useSelector((state) => state.user.usersRowsPerPage);
  const usersSortCriterias = useSelector(
    (state) => state.user.usersSortCriterias,
  );
  const errorUsers = useSelector((state) => state.user.errorUsers);

  const dispatch = useDispatch();

  const onLoadUsersRequested = React.useCallback(
    (params) => {
      dispatch(loadUsersRequested(params));
    },
    [dispatch],
  );

  const theme = useTheme();
  const classes = useClearButtonsStyles();
  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: usersSortCriterias,
    getDataFunc: refreshUsers,
  });

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadUsersRequested,
    limit: usersRowsPerPage,
    sort: usersSortCriterias,
  });

  React.useEffect(() => {
    if (isEmpty(users)) {
      onLoadUsersRequested({
        page: null,
        limit: null,
        offset: null,
        sort: null,
        clearSort: false,
      });
    }
  }, [onLoadUsersRequested]);

  function refreshUsers({ sort, clearSort }) {
    onLoadUsersRequested({
      page: usersCurrentPage,
      limit: usersRowsPerPage,
      offset: usersCurrentPage * usersRowsPerPage,
      sort,
      clearSort,
    });
  }

  const hasRecords = () => !isEmpty(users);

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={usersLoading} />
      </TableCell>
    </TableRow>
  );

  const renderTableBody = () => {
    if (!isEmpty(users) && !isEmpty(users.entities)) {
      return users.result.results.map((userId) => {
        let user = users.entities.results[userId];
        return (
          <TableRow key={`users-${userId}`}>
            <TableCell align="left">{user.email}</TableCell>
            <TableCell align="left">{user.username}</TableCell>
            <TableCell align="left">{user.first_name}</TableCell>
            <TableCell align="left">{user.last_name}</TableCell>
            <TableCell align="left">{user.date_joined}</TableCell>
            <TableCell align="left">{user.profile.entity_legal_name}</TableCell>
          </TableRow>
        );
      });
    }
  };

  if (errorUsers !== null && !users.length) {
    return (
      <FetchError
        title="Error while loading data for users. Expand for more details"
        error={errorUsers}
        onRetry={() => refreshUsers({ sort: null, clearSort: true })}
      />
    );
  }

  return (
    <div style={theme.mainContainer}>
      <Grid
        container
        direction="row"
        justify="flex-end"
        item
        xs={12}
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="outlined"
            className={classes.clearButtons}
            onClick={clearSorting}
            disabled={isSortingDisabled()}
          >
            Clear Sorting
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={theme.pvelH2} gutterBottom>
            Users
          </Typography>
          <Typography style={theme.paragraph}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
            repellendus cupiditate tempore voluptatibus, similique enim? Quas,
            necessitatibus nulla. Nihil commodi est sunt recusandae dolor
            impedit blanditiis inventore non, velit sapiente?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={theme.tableContainer}>
            <TableContainer style={theme.table}>
              <Table stickyHeader aria-label="My Users Table">
                <TableHead>
                  <TableRow style={theme.tableHeaderRow}>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={usersSortCriterias.email.active}
                        direction={
                          usersSortCriterias.email.active
                            ? usersSortCriterias.email.type
                            : "asc"
                        }
                        onClick={sortHandler("email")}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={usersSortCriterias.username.active}
                        direction={
                          usersSortCriterias.username.active
                            ? usersSortCriterias.username.type
                            : "asc"
                        }
                        onClick={sortHandler("username")}
                      >
                        Username
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={usersSortCriterias.first_name.active}
                        direction={
                          usersSortCriterias.first_name.active
                            ? usersSortCriterias.first_name.type
                            : "asc"
                        }
                        onClick={sortHandler("first_name")}
                      >
                        First Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={usersSortCriterias.last_name.active}
                        direction={
                          usersSortCriterias.last_name.active
                            ? usersSortCriterias.last_name.type
                            : "asc"
                        }
                        onClick={sortHandler("last_name")}
                      >
                        Last Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      <TableSortLabel
                        disabled={!hasRecords()}
                        active={usersSortCriterias.date_joined.active}
                        direction={
                          usersSortCriterias.date_joined.active
                            ? usersSortCriterias.date_joined.type
                            : "asc"
                        }
                        onClick={sortHandler("date_joined")}
                      >
                        Date Joined
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={theme.tableHeader}>
                      Legal Entity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersLoading ? renderLoader() : renderTableBody()}
                </TableBody>
                <TableFooter>
                  <TableRow></TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={[25, { label: "All", value: -1 }]}
              colSpan={3}
              count={usersCount}
              rowsPerPage={usersRowsPerPage}
              page={usersCurrentPage}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
