import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  loadFilteredReportsRequested,
  loadCompaniesRequested,
  loadTechnologyTagsRequested,
  loadTypesRequested,
  loadRangeRequested,
  sentReportRequests,
  filterReportsActions,
} from "../";

import { downloadedFilteredReport } from "../";

import { FilterReportsView } from "./FilterReportsView";

export const FilterReportsContainer = () => {
  const filteredReportsLoading = useSelector(
    (state) => state.filterReports.filteredReportsLoading,
  );
  const filteredReports = useSelector(
    (state) => state.filterReports.filteredReports,
  );
  const filteredReportsCurrentPage = useSelector(
    (state) => state.filterReports.filteredReportsCurrentPage,
  );
  const filteredReportsCount = useSelector(
    (state) => state.filterReports.filteredReportsCount,
  );
  const filteredReportsRowsPerPage = useSelector(
    (state) => state.filterReports.filteredReportsRowsPerPage,
  );

  const companiesLoading = useSelector(
    (state) => state.filterReports.companiesLoading,
  );
  const companies = useSelector((state) => state.filterReports.companies);

  const technologyTagsLoading = useSelector(
    (state) => state.filterReports.technologyTagsLoading,
  );
  const technologyTags = useSelector(
    (state) => state.filterReports.technologyTags,
  );

  const typesLoading = useSelector((state) => state.filterReports.typesLoading);
  const types = useSelector((state) => state.filterReports.types);

  const rangeLoading = useSelector((state) => state.filterReports.rangeLoading);
  const range = useSelector((state) => state.filterReports.range);

  const reportRequestsLoading = useSelector(
    (state) => state.filterReports.sentReportRequestsLoading,
  );

  const errorFilteredReports = useSelector(
    (state) => state.filterReports.errorFilteredReports,
  );

  const errorCompanies = useSelector(
    (state) => state.filterReports.errorCompanies,
  );

  const errorTechnologyTags = useSelector(
    (state) => state.filterReports.errorTechnologyTags,
  );

  const errorTypes = useSelector((state) => state.filterReports.errorTypes);

  const errorRange = useSelector((state) => state.filterReports.errorRange);

  const errorSentReportRequests = useSelector(
    (state) => state.filterReports.errorSentReportRequests,
  );

  const successSentReportRequests = useSelector(
    (state) => state.filterReports.successSentReportRequests,
  );

  const dispatch = useDispatch();

  const onLoadFilteredReports = useCallback(
    (params) => dispatch(loadFilteredReportsRequested(params)),
    [dispatch],
  );

  const onClearFilteredReports = useCallback(
    () => dispatch(filterReportsActions.clearedFilteredReports()),
    [dispatch],
  );

  const onSentReportRequests = useCallback(
    (requests, params) => dispatch(sentReportRequests(requests, params)),
    [dispatch],
  );
  const onDownloadReport = useCallback(
    (report) => dispatch(downloadedFilteredReport(report)),
    [dispatch],
  );

  const resetErrorsFilterReportRequested = useCallback(
    () => dispatch(filterReportsActions.resetErrorsFilterReportRequested()),
    [dispatch],
  );

  const onLoadCompaniesRequested = useCallback(() => {
    dispatch(loadCompaniesRequested());
  }, [companies, dispatch]);

  const onLoadTechnologyTagsRequested = useCallback(() => {
    dispatch(loadTechnologyTagsRequested());
  }, [dispatch]);

  const onLoadTypesRequested = useCallback(() => {
    dispatch(loadTypesRequested());
  }, [dispatch]);

  const onLoadRangeRequested = useCallback(() => {
    dispatch(loadRangeRequested());
  }, [dispatch]);

  const onInitFilteringData = useCallback(() => {
    if (isEmpty(filteredReports)) {
      onLoadFilteredReports({
        page: 0,
        limit: 5,
        offset: 0,
        order: null,
        issuedMin: "",
        issuedMax: "",
        companies: [],
        types: [],
        technologyTags: [],
      });
    }
    if (isEmpty(range)) {
      onLoadRangeRequested();
    }
    if (isEmpty(types)) {
      onLoadTypesRequested();
    }
    if (isEmpty(technologyTags)) {
      onLoadTechnologyTagsRequested();
    }
    if (isEmpty(companies)) {
      onLoadCompaniesRequested();
    }
  }, [
    dispatch,
    loadRangeRequested,
    loadTypesRequested,
    loadTechnologyTagsRequested,
    loadCompaniesRequested,
  ]);

  useEffect(() => {
    onInitFilteringData();
    window.scrollTo(0, 0);
  }, [onInitFilteringData]);

  return (
    <FilterReportsView
      onLoadFilteredReports={onLoadFilteredReports}
      onClearFilteredReports={onClearFilteredReports}
      onSentReportRequests={onSentReportRequests}
      filteredReports={filteredReports}
      filteredReportsCount={filteredReportsCount}
      filteredReportsRowsPerPage={filteredReportsRowsPerPage}
      filteredReportsCurrentPage={filteredReportsCurrentPage}
      filteredReportsLoading={filteredReportsLoading}
      companies={companies}
      companiesLoading={companiesLoading}
      technologyTags={technologyTags}
      technologyTagsLoading={technologyTagsLoading}
      types={types}
      typesLoading={typesLoading}
      range={range}
      rangeLoading={rangeLoading}
      reportRequestsLoading={reportRequestsLoading}
      onDownloadReport={onDownloadReport}
      errorFilteredReports={errorFilteredReports}
      errorCompanies={errorCompanies}
      errorTechnologyTags={errorTechnologyTags}
      errorTypes={errorTypes}
      errorRange={errorRange}
      errorSentReportRequests={errorSentReportRequests}
      loadCompaniesRequested={loadCompaniesRequested}
      loadTechnologyTagsRequested={loadTechnologyTagsRequested}
      loadTypesRequested={loadTypesRequested}
      loadRangeRequested={loadRangeRequested}
      resetErrorsFilterReportRequested={resetErrorsFilterReportRequested}
      successSentReportRequests={successSentReportRequests}
    />
  );
};
