import { authorizationHeader } from "../../../util/fetchUtil";
import { responseBody } from "../../../util/fetchUtil";
import { TokenManager } from "../../../util/TokenManager";

// const host = process.env.REACT_APP_API_HOST;
const host = "https://portolaprod.azurewebsites.net/";

export const loadFilteredReports = async (limit, offset, queryString) => {
  try {
    const url = `${host}/api/documents/${queryString}&limit=${
      limit || 10
    }&offset=${offset || 0}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadCompanies = async () => {
  try {
    const url = `${host}/api/documents/entities/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadTechnologyTags = async () => {
  try {
    const url = `${host}/api/tags/?limit=-1`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadTypes = async () => {
  try {
    const url = `${host}/api/documents/types/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadRange = async () => {
  try {
    const url = `${host}/api/documents/range/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const sendReportRequests = async (requests) => {
  try {
    const url = `${host}/api/documents/batch/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requests),
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
