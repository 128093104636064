import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

class ErrorMessage extends React.Component {
  render() {
    const { message } = this.props;

    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
    );
  }
}

export default ErrorMessage;
