export const authorizationHeader = (token) => {
  return `Token ${token}`;
};

export const responseBody = async (res) => {
  if (res.status >= 400) {
    let rejectResponse = await res.json();
    rejectResponse.statusCode = res.status;
    rejectResponse.statusText = res.statusText;
    return Promise.reject(rejectResponse);
  }
  return await res.json();
};
