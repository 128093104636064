import { authorizationHeader } from "../../../util/fetchUtil";
import { responseBody } from "../../../util/fetchUtil";
import { TokenManager } from "../../../util/TokenManager";

// const host = process.env.REACT_APP_API_HOST;
const host = "https://portolaprod.azurewebsites.net/";

export const loadNewReports = async () => {
  try {
    const url = `${host}/api/documents/new/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadAllPendingReports = async () => {
  try {
    const url = `${host}/api/documents/?disclosure=PENDING`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
