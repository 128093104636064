import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FilterReportsCriteriaLoaderView } from "./FilterReportsCriteriaLoaderView";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import { FetchError } from "features/common/FetchError";
import isEmpty from "lodash/isEmpty";

export const FilterReportsCriteriaView = (props) => {
  const {
    isFilterDataLoading,
    companies,
    range,
    technologyTags,
    types,
    onLoadFilteredReports,
    onClearFilteredReports,
    fromDateSelected,
    setFromDateSelected,
    toDateSelected,
    setToDateSelected,
    companiesSelected,
    setCompaniesSelected,
    technologyTagsSelected,
    setTechnologyTagsSelected,
    typesSelected,
    setTypesSelected,
    setSelected,
    setIsInitialSearch,
    errorCompanies,
    errorTechnologyTags,
    errorTypes,
    errorRange,
    loadCompaniesRequested,
    loadTechnologyTagsRequested,
    loadTypesRequested,
    loadRangeRequested,
    currentLimit,
  } = props;

  const theme = useTheme();
  const classes = useClearButtonsStyles();

  const hasError = () =>
    errorCompanies || errorTechnologyTags || errorTypes || errorRange;

  const objectTypes = Object.entries(types).map(([key, value], index) => {
    return { key, value };
  });

  const handleCompanyChange = (event, value) => {
    if (value) {
      setCompaniesSelected(value);
    }
  };
  const handleTechnologyTagsChange = (event, value) => {
    if (value) {
      setTechnologyTagsSelected(value);
    }
  };
  const handleTypeChange = (event, value) => {
    if (value) {
      setTypesSelected(value);
    }
  };

  const onFromDateChange = (date, value) => {
    setFromDateSelected(value);
  };
  const onToDateChange = (date, value) => {
    setToDateSelected(value);
  };
  const onFilterReports = (event) => {
    setIsInitialSearch(false);
    setSelected([]);
    onLoadFilteredReports({
      page: 0,
      limit: currentLimit,
      offset: 0,
      issuedMin: fromDateSelected,
      issuedMax: toDateSelected,
      companies: companiesSelected,
      types: typesSelected,
      technologyTags: technologyTagsSelected,
    });
  };

  const onClearFilterReports = (event) => {
    setIsInitialSearch(true);
    setSelected([]);
    setCompaniesSelected([]);
    setTechnologyTagsSelected([]);
    setTypesSelected([]);
    setFromDateSelected(range.issued_min);
    setToDateSelected(range.issued_max);
    onClearFilteredReports();
  };

  if (isFilterDataLoading)
    return (
      <FilterReportsCriteriaLoaderView
        isFilterDataLoading={isFilterDataLoading}
      />
    );
  return (
    <div style={theme.mainContainer}>
      <Grid
        container
        spacing={6}
        component={Paper}
        direction="row"
        justify="space-between"
        align-items="center"
      >
        {errorCompanies && !companies.length ? (
          <Grid item xs={4}>
            <FetchError
              title="Error while loading data for companies. Expand for more details"
              error={errorCompanies}
              onRetry={() => loadCompaniesRequested()}
            />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id="pvel-rpt-cmpny"
              options={companies}
              getOptionLabel={(option) => option.displayName}
              onChange={handleCompanyChange}
              value={companiesSelected}
              renderInput={(params) => (
                <TextField {...params} label="Company" variant="outlined" />
              )}
            />
          </Grid>
        )}

        {errorRange && (range || !range.issued_min || !range.issued_min) ? (
          <Grid item xs={8}>
            <FetchError
              title="Error while loading data for issue dates. Expand for more details"
              error={errorRange}
              onRetry={() => loadRangeRequested()}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={4}>
              <KeyboardDatePicker
                margin="normal"
                id="pvel-rpt-isdtfr"
                variant="dialog"
                label={`Reports are available from ${range.issued_min}`}
                fullWidth
                invalidDateMessage=" YYYY-MM-DD format"
                format="YYYY-MM-DD"
                minDate={range.issued_min}
                value={fromDateSelected}
                onChange={onFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change from issue date",
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <KeyboardDatePicker
                margin="normal"
                id="pvel-rpt-isdto"
                variant="dialog"
                label={`Report date are available to ${range.issued_max}`}
                fullWidth
                invalidDateMessage=" YYYY-MM-DD format"
                format="YYYY-MM-DD"
                maxDate={range.issued_max}
                value={toDateSelected}
                onChange={onToDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change to issue date",
                }}
              />
            </Grid>
          </>
        )}

        {errorTechnologyTags && !technologyTags.length ? (
          <Grid item xs={4}>
            <FetchError
              title="Error while loading data for technology tags. Expand for more details"
              error={errorTechnologyTags}
              onRetry={() => loadTechnologyTagsRequested()}
            />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id="pvel-rpt-tt"
              options={technologyTags}
              getOptionLabel={(option) => option.title}
              onChange={handleTechnologyTagsChange}
              value={technologyTagsSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Technology Tags"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}

        {errorTypes && isEmpty(types) ? (
          <Grid item xs={4}>
            <FetchError
              title="Error while loading data for processed reports. Expand for more details"
              error={errorTypes}
              onRetry={() => loadTypesRequested()}
            />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id="pvel-rpt-rt"
              options={objectTypes}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) => {
                return option.key === value.key;
              }}
              onChange={handleTypeChange}
              value={typesSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Report Types"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <Button
            variant="contained"
            style={theme.buttonSubmit}
            onClick={onFilterReports}
            disabled={hasError()}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            className={classes.clearButtons}
            onClick={onClearFilterReports}
            disabled={hasError()}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
