import { createSlice } from "@reduxjs/toolkit";
import {
  loadPendingRequests,
  changeRequestStatus,
  loadProcessedRequests,
} from "./requestServices";
import { getApiPaginationParams } from "../../../util/appUtil";
import { normalize, schema } from "normalizr";
import isEmpty from "lodash/isEmpty";

const result = new schema.Entity("results");
const resultSchema = { results: [result] };

const initialState = {
  pendingRequestsLoading: false,
  pendingRequests: [],
  errorPendingRequests: null,
  pendingRequestsSortCriterias: {
    requestDate: { active: false, type: "asc" },
    requestorComments: { active: false, type: "asc" },
  },
  requestStatusChangedLoading: false,
  successRequestStatusChanged: false,
  errorRequestStatusChanged: null,
  processedRequestsLoading: false,
  processedRequests: [],
  errorProcessedRequests: null,
  processedRequestsSortCriterias: {
    status: { active: false, type: "asc" },
    requestDate: { active: false, type: "asc" },
    approverComments: { active: false, type: "asc" },
  },
  processedRequestsCurrentPage: 0,
  processedRequestsCount: 0,
  processedRequestsRowsPerPage: 25,
};

const requestSlice = createSlice({
  name: "request",
  initialState: initialState,
  reducers: {
    loadPendingRequestsPending: (state, action) => {
      state.pendingRequests = [];
      state.pendingRequestsLoading = true;
      state.errorPendingRequests = null;
      state.errorPendingRequests = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.pendingRequestsSortCriterias =
            initialState.pendingRequestsSortCriterias;
        } else {
          state.pendingRequestsSortCriterias = action.payload.sort;
        }
      }
    },
    loadPendingRequestsFulfilled: (state, action) => {
      state.pendingRequestsLoading = false;
      state.pendingRequests = normalize(action.payload, resultSchema);
    },
    loadPendingRequestsRejected: (state, action) => {
      state.pendingRequestsLoading = false;
      state.errorPendingRequests = action.payload;
    },
    requestStatusChangedPending: (state, action) => {
      state.requestStatusChangedLoading = true;
      state.successRequestStatusChanged = false;
      state.errorRequestStatusChanged = null;
    },
    requestStatusChangedFulfilled: (state, action) => {
      state.requestStatusChangedLoading = false;
      state.successRequestStatusChanged = true;
    },
    requestStatusChangedRejected: (state, action) => {
      state.requestStatusChangedLoading = false;
      state.errorRequestStatusChanged = action.payload;
    },
    loadProcessedRequestsPending: (state, action) => {
      state.processedRequests = [];
      state.processedRequestsLoading = true;
      state.errorProcessedRequests = null;
      state.processedRequestsCurrentPage = action.payload.pageToRequest;
      state.processedRequestsRowsPerPage = action.payload.limitToRequest;
      state.errorProcessedRequests = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.processedRequestsSortCriterias =
            initialState.processedRequestsSortCriterias;
        } else {
          state.processedRequestsSortCriterias = action.payload.sort;
        }
      }
    },
    loadProcessedRequestsFulfilled: (state, action) => {
      state.processedRequestsLoading = false;
      state.processedRequests = action.payload.results;
      state.processedRequestsCount = action.payload.count;
    },
    loadProcessedRequestsRejected: (state, action) => {
      state.processedRequestsLoading = false;
      state.errorProcessedRequests = action.payload;
    },
    resetErrorsRequestStatusChanged: (state, action) => {
      state.successRequestStatusChanged = false;
      state.errorRequestStatusChanged = null;
    },
  },
});

export const { actions, reducer } = requestSlice;

// Requests Thunks

export const loadPendingRequestsRequested = ({ sort, clearSort }) => {
  return async (dispatch) => {
    dispatch(actions.loadPendingRequestsPending({ sort, clearSort }));
    try {
      let queryString = getSortQueryForPendingRequests(sort);
      let result = await loadPendingRequests(queryString);
      dispatch(actions.loadPendingRequestsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadPendingRequestsRejected(err));
    }
  };
};

export const requestStatusChanged = (requests, params) => {
  return async (dispatch) => {
    dispatch(actions.requestStatusChangedPending());
    try {
      let result = await changeRequestStatus(requests, params);
      dispatch(actions.requestStatusChangedFulfilled(result));
      dispatch(
        loadPendingRequestsRequested({
          sort: params.sortPendingRequests,
          clearSort: false,
        }),
      );
      dispatch(
        loadProcessedRequestsRequested({
          page: initialState.processedRequestsCurrentPage,
          limit: params.processedRequestsRowsPerPage,
          offset:
            initialState.processedRequestsCurrentPage *
            params.processedRequestsRowsPerPage,
          sort: params.sortProcessedRequests,
          clearSort: false,
        }),
      );
    } catch (err) {
      dispatch(actions.requestStatusChangedRejected(err));
    }
  };
};

export const loadProcessedRequestsRequested = ({
  page,
  limit,
  offset,
  sort,
  clearSort,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.processedRequestsRowsPerPage,
        initialState.processedRequestsCurrentPage,
      );
    dispatch(
      actions.loadProcessedRequestsPending({
        pageToRequest,
        limitToRequest,
        sort,
        clearSort,
      }),
    );
    try {
      const queryString = getSortQueryForProcessedRequests(sort);
      const result = await loadProcessedRequests(
        limitToRequest,
        offsetToRequest,
        queryString,
      );
      dispatch(actions.loadProcessedRequestsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadProcessedRequestsRejected(err));
    }
  };
};

const getSortQueryForPendingRequests = (sort) => {
  let queryString = "";
  if (
    !isEmpty(sort) &&
    (sort.requestDate.active || sort.requestorComments.active)
  ) {
    let sortParametersArray = [];
    if (sort.requestDate.active) {
      sortParametersArray.push(
        sort.requestDate.type === "asc" ? "created" : "-created",
      );
    }
    if (sort.requestorComments.active) {
      sortParametersArray.push(
        sort.requestorComments.type === "asc"
          ? "requestor_comment"
          : "-requestor_comment",
      );
    }
    queryString += `&ordering=${sortParametersArray.join()}`;
    return queryString;
  }
};

const getSortQueryForProcessedRequests = (sort) => {
  let queryString = "";
  if (
    !isEmpty(sort) &&
    (sort.status.active ||
      sort.requestDate.active ||
      sort.approverComments.active)
  ) {
    let sortParametersArray = [];
    if (sort.status.active) {
      sortParametersArray.push(
        sort.status.type === "asc" ? "status" : "-status",
      );
    }
    if (sort.requestDate.active) {
      sortParametersArray.push(
        sort.requestDate.type === "asc" ? "created" : "-created",
      );
    }
    if (sort.approverComments.active) {
      sortParametersArray.push(
        sort.approverComments.type === "asc"
          ? "approver_comment"
          : "-approver_comment",
      );
    }
    queryString += `&ordering=${sortParametersArray.join()}`;
    return queryString;
  } else {
    queryString += "&ordering=-resolved";
    return queryString;
  }
};
