const key = "userToken";

export class TokenManager {
  static getToken() {
    return localStorage.getItem(key);
  }

  static setToken(token) {
    localStorage.setItem(key, token);
  }

  static clearToken() {
    localStorage.removeItem(key);
  }
}
