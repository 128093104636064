import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { loadProcessedRequestsRequested } from "../";

import { ProcessedRequestsView } from "./ProcessedRequestsView";

export const ProcessedRequestsContainer = () => {
  const processedRequestsLoading = useSelector(
    (state) => state.request.processedRequestsLoading,
  );
  const processedRequests = useSelector(
    (state) => state.request.processedRequests,
  );
  const processedRequestsCurrentPage = useSelector(
    (state) => state.request.processedRequestsCurrentPage,
  );
  const processedRequestsCount = useSelector(
    (state) => state.request.processedRequestsCount,
  );
  const processedRequestsRowsPerPage = useSelector(
    (state) => state.request.processedRequestsRowsPerPage,
  );
  const errorProcessedRequests = useSelector(
    (state) => state.request.errorProcessedRequests,
  );

  const processedRequestsSortCriterias = useSelector(
    (state) => state.request.processedRequestsSortCriterias,
  );

  const dispatch = useDispatch();

  const onLoadProcessedRequests = useCallback(
    (params) => dispatch(loadProcessedRequestsRequested(params)),
    [dispatch],
  );

  useEffect(() => {
    if (isEmpty(processedRequests)) {
      onLoadProcessedRequests({
        page: null,
        limit: null,
        offset: null,
        sort: null,
        clearSort: false,
      });
    }
  }, [onLoadProcessedRequests]);

  return (
    <ProcessedRequestsView
      onLoadProcessedRequests={onLoadProcessedRequests}
      processedRequests={processedRequests}
      processedRequestsCount={processedRequestsCount}
      processedRequestsRowsPerPage={processedRequestsRowsPerPage}
      processedRequestsCurrentPage={processedRequestsCurrentPage}
      processedRequestsLoading={processedRequestsLoading}
      errorProcessedRequests={errorProcessedRequests}
      processedRequestsSortCriterias={processedRequestsSortCriterias}
    />
  );
};
