import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MessagePvelContainer } from "../manufacturer/dashboard/MessagePvel/MessagePvelContainer";
import { PVELSummaryActivity } from "./dashboard/PVELSummaryActivity";
import { TwitterMarketingView } from "../manufacturer/dashboard/TwitterMarketing/TwitterMarketingView";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(10),
  },
}));

export const PVELDashboardView = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root} style={theme.generalContainer}>
      <Grid container spacing={5}>
        {/* <Grid item md={6} xs={12}>
          <MessagePvelContainer />
        </Grid>
        <Grid item md={6} xs={12}>
          <PVELSummaryActivity />
        </Grid> */}
        <Grid item md={12} xs={12}>
          <TwitterMarketingView />
        </Grid>
      </Grid>
    </div>
  );
};
