import React from "react";
import { useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../util/authUtil";
import PVELMenu from "./PVELMenu";
import { FilterReportsContainer } from "../downstream/filterReports/FilterReports";
import { PVELDashboardView } from "./PVELDashboardView";
// import { IdleTimerContainer } from "features/common/IdleTimerContainer";
import { MyReportsView } from "../manufacturer/reports/MyReportsView";
import { MyProjectsContainer } from "features/shared/projects/MyProjectsContainer";
import { UsersView } from "./users/UsersView";
import { TabList } from "features/downstream/TabList";

export const PVEL = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <PVELMenu />
      {/* <IdleTimerContainer /> */}
      <PrivateRoute
        path={`${path}/partners-and-manufacturers`}
        component={TabList}
      />
      <PrivateRoute exact path={`${path}`} component={PVELDashboardView} />
      <PrivateRoute path={`${path}/users`} component={UsersView} />
      <PrivateRoute path={`${path}/reports`} component={MyReportsView} />
      <PrivateRoute path={`${path}/projects`} component={MyProjectsContainer} />

      {/* Commenting for future use for PVEL role
      <PrivateRoute
        path={`${path}/requests`}
        component={RequestedReportsContainer}
      /> */}
      <PrivateRoute
        path={`${path}/find-a-report`}
        component={FilterReportsContainer}
      />
    </div>
  );
};
