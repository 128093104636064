import { combineReducers } from "redux";

import { auth } from "./features/auth/index";
import { bootstrap } from "./features/bootstrap";
import { partner } from "./features/downstream";
import { project } from "./features/shared/projects";
import { report, request } from "./features/manufacturer";
import { filterReports, partnerReports } from "./features/downstream";
import { dashboard } from "./features/manufacturer/dashboard";
import { dashboardPartner } from "./features/downstream/dashboard";
import { user } from "./features/pvel/users";

const appReducer = combineReducers({
  auth,
  bootstrap,
  partner,
  project,
  report,
  request,
  filterReports,
  partnerReports,
  dashboard,
  dashboardPartner,
  user,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
