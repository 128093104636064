import React, { useEffect } from "react";
import { MessagePvelView } from "./MessagePvelView";
import { useSelector, useDispatch } from "react-redux";
import { loadMessageFromPvelRequested } from "../";
import isEmpty from "lodash/isEmpty";

export const MessagePvelContainer = () => {
  const messageFromPvel = useSelector(
    (state) => state.dashboard.messageFromPvel,
  );
  const messageFromPvelLoading = useSelector(
    (state) => state.dashboard.messageFromPvelLoading,
  );

  const dispatch = useDispatch();

  const onLoadMessageFromPvelRequested = React.useCallback(() => {
    if (isEmpty(messageFromPvel)) {
      dispatch(loadMessageFromPvelRequested());
    }
  }, [dispatch]);

  useEffect(() => {
    onLoadMessageFromPvelRequested();
  }, [onLoadMessageFromPvelRequested]);

  return (
    <MessagePvelView
      messageFromPvel={messageFromPvel}
      messageFromPvelLoading={messageFromPvelLoading}
    />
  );
};
