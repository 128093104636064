import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import { ShowDetailsButton } from "features/common/ShowDetailsButton";
import Slide from "@material-ui/core/Slide";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import Link from "@material-ui/core/Link";
import { useDisableContextMenu } from "../../common/hooks/useDisableContextMenu";
import { DownloadReportLink } from "../../common/DownloadReportLink";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useClearButtonsStyles } from "../../../themes/appStyles";
import Button from "@material-ui/core/Button";
import some from "lodash/some";

const useStyles = makeStyles((theme) => ({
  active: {
    "&$active": {
      color: "#FFFFFF",
    },
  },
}));

export const MyProjectDetailsView = (props) => {
  const {
    documents,
    entityType,
    onDownloadReport,
    projectData,
    toggleCurrentView,
  } = props;

  const initialSortCriterias = {
    disclosure_text: { active: false, type: "asc" },
    type_text: { active: false, type: "asc" },
    title: { active: false, type: "asc" },
    name: { active: false, type: "asc" },
    bom: { active: false, type: "asc" },
    issued_date: { active: false, type: "asc" },
    factory_witness_date: { active: false, type: "asc" },
    technology_tags: { active: false, type: "asc" },
  };
  const theme = useTheme();
  const clearButtonStyles = useClearButtonsStyles();
  const classes = useStyles();
  const { disableContextMenu } = useDisableContextMenu();
  const [sortedDocuments, setSortedDocuments] = React.useState([]);
  const [sortCriterias, setSortCriterias] =
    React.useState(initialSortCriterias);
  const pinnedProjectReportsData = projectData.document_project;
  const projectDocuments = pinnedProjectReportsData.map((documentId, index) => {
    return documents[documentId];
  });
  React.useEffect(() => {
    setSortedDocuments(projectDocuments);
  }, []);

  const renderTableDetailBody = () => {
    return (
      <TableRow hover tabIndex={-1} key={projectData.id}>
        <TableCell>
          <ShowDetailsButton
            text={projectData.number}
            tooltipText="Click to go back to projects list."
            isOpen={projectData.id === null}
            toggleCurrentView={toggleCurrentView(projectData.id)}
          />
        </TableCell>
        <TableCell>{projectData.type_text}</TableCell>
        <TableCell>{projectData.name}</TableCell>
        <TableCell>
          <Link
            style={theme.downloadLink}
            href={`mailto:${projectData.pvel_manager_user}@pvel.com`}
          >
            {projectData.pvel_manager_name}
          </Link>
        </TableCell>

        {entityType !== "Downstream Client" && (
          <TableCell>
            {isEmpty(projectData.document_approver_name) ||
            projectData.document_approver_name === ", " ? (
              <Link
                style={theme.downloadLink}
                href={`mailto:${projectData.document_approver_user}`}
              >
                {projectData.document_approver_user}
              </Link>
            ) : (
              projectData.document_approver_name
            )}
          </TableCell>
        )}
        <TableCell>
          {isEmpty(projectData.primary_contact_name) ||
          projectData.primary_contact_name === ", " ? (
            <Link
              style={theme.downloadLink}
              href={`mailto:${projectData.primary_contact_user}`}
            >
              {projectData.primary_contact_user}
            </Link>
          ) : (
            projectData.document_approver_name
          )}
        </TableCell>
        <TableCell>{projectData.contract_signature}</TableCell>
        <TableCell>{projectData.last_document_date}</TableCell>
        <TableCell>{projectData.document_project.length}</TableCell>
      </TableRow>
    );
  };

  const renderProjectReports = () => {
    return sortedDocuments.map((document, index) => {
      return (
        <TableRow hover tabIndex={-1} key={index}>
          {entityType !== "Downstream Client" && (
            <TableCell>{document.disclosure_text}</TableCell>
          )}
          <TableCell>
            <DownloadReportLink
              onContextMenu={disableContextMenu}
              currentReport={document}
              onDownloadReport={onDownloadReport}
              objectToModify={document}
            />
          </TableCell>
          {entityType === "Downstream Client" && (
            <TableCell>{document.name}</TableCell>
          )}
          <TableCell>{document.type_text}</TableCell>
          <TableCell>{document.bom}</TableCell>
          <TableCell>{document.issued_date}</TableCell>
          <TableCell>{document.factory_witness_date}</TableCell>
          <TableCell>{document?.technology_tags.join(", ")}</TableCell>
        </TableRow>
      );
    });
  };
  const hasRecords = () => !isEmpty(sortedDocuments);

  const sortHandler = (sortCriteria) => () => {
    let requestedSorting = Object.assign({}, sortCriterias[sortCriteria]);
    if (
      sortCriterias[sortCriteria].active &&
      sortCriterias[sortCriteria].type === "asc"
    ) {
      requestedSorting.type = "desc";
    } else if (
      sortCriterias[sortCriteria].active &&
      sortCriterias[sortCriteria].type === "desc"
    ) {
      requestedSorting.type = "asc";
    } else if (!sortCriterias[sortCriteria].active) {
      requestedSorting = {
        ...requestedSorting,
        active: true,
      };
    }
    const updatedSortCriterias = {
      ...sortCriterias,
      [sortCriteria]: requestedSorting,
    };
    setSortCriterias(updatedSortCriterias);
    const updatedSortedDocuments = orderBy(
      sortedDocuments,
      sortCriteria,
      requestedSorting.type,
    );
    setSortedDocuments(updatedSortedDocuments);
  };
  const isSortingDisabled = () => !some(sortCriterias, ["active", true]);
  const clearSorting = () => {
    setSortCriterias(initialSortCriterias);
    setSortedDocuments(projectDocuments);
  };

  return (
    <>
      <div style={theme.mainContainer}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <Typography variant="h4" style={theme.pvelH1}>
              My Projects
            </Typography>

            <Typography style={theme.paragraph}>
              Your company's recent PVEL projects are listed in the table below.
              Click the + icon to show a list of the reports that are associated
              with a specific project.
            </Typography>
            {entityType === "Manufacturer" && (
              <Typography style={theme.paragraph}>
                To disclose a report, please navigate to the My Reports tab. To
                assign names to your projects, please email{" "}
                <Link style={theme.downloadLink} href="mailto:portal@pvel.com">
                  portal@pvel.com
                </Link>{" "}
                with the PVEL project numbers and their corresponding project
                names.
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={clearSorting}
              disabled={isSortingDisabled()}
              className={classes.clearButtons}
            >
              Clear Project Sorting
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12}>
        <div style={theme.masterContainer}>
          <Paper style={theme.tableContainer}>
            <TableContainer style={theme.table}>
              <Table stickyHeader aria-label="My Projects Table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="default" style={theme.tableHeader}>
                      Project Number
                    </TableCell>
                    <TableCell padding="default" style={theme.tableHeader}>
                      Project Type
                    </TableCell>
                    <TableCell padding="default" style={theme.tableHeader}>
                      Project Name
                    </TableCell>
                    <TableCell padding="default" style={theme.tableHeader}>
                      PVEL Project Manager
                    </TableCell>
                    {entityType !== "Downstream Client" && (
                      <TableCell padding="default" style={theme.tableHeader}>
                        Document Approver
                      </TableCell>
                    )}
                    <TableCell padding="default" style={theme.tableHeader}>
                      Primary Contact
                    </TableCell>
                    <TableCell padding="default" style={theme.tableHeader}>
                      Contract Signature Date
                    </TableCell>
                    <TableCell padding="default" style={theme.tableHeader}>
                      Latest Report Date
                    </TableCell>
                    <TableCell padding="default" style={theme.tableHeader}>
                      # of Reports
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableDetailBody()}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>

        <Slide
          direction="up"
          in={projectData.id !== null}
          mountOnEnter
          unmountOnExit
        >
          <div style={theme.detailContainer}>
            <Paper style={theme.tableContainer}>
              <TableContainer style={theme.projectReportTableContainer}>
                <Table stickyHeader aria-label="My Project Reports Table">
                  <TableHead>
                    <TableRow>
                      {entityType !== "Downstream Client" && (
                        <TableCell
                          padding="default"
                          style={theme.projectReportHeader}
                        >
                          <TableSortLabel
                            className={classes.active}
                            disabled={!hasRecords()}
                            active={sortCriterias.disclosure_text.active}
                            direction={
                              sortCriterias.disclosure_text.active
                                ? sortCriterias.disclosure_text.type
                                : "asc"
                            }
                            onClick={sortHandler("disclosure_text")}
                          >
                            Disclosure
                          </TableSortLabel>
                        </TableCell>
                      )}
                      <TableCell
                        padding="default"
                        style={theme.projectReportHeader}
                      >
                        <TableSortLabel
                          className={classes.active}
                          disabled={!hasRecords()}
                          active={sortCriterias.title.active}
                          direction={
                            sortCriterias.title.active
                              ? sortCriterias.title.type
                              : "asc"
                          }
                          onClick={sortHandler("title")}
                        >
                          Report title
                        </TableSortLabel>
                      </TableCell>
                      {entityType === "Downstream Client" && (
                        <TableCell
                          padding="default"
                          style={theme.projectReportHeader}
                        >
                          <TableSortLabel
                            className={classes.active}
                            disabled={!hasRecords()}
                            active={sortCriterias.name.active}
                            direction={
                              sortCriterias.name.active
                                ? sortCriterias.name.type
                                : "asc"
                            }
                            onClick={sortHandler("name")}
                          >
                            Report filename
                          </TableSortLabel>
                        </TableCell>
                      )}
                      <TableCell
                        padding="default"
                        style={theme.projectReportHeader}
                      >
                        <TableSortLabel
                          className={classes.active}
                          disabled={!hasRecords()}
                          active={sortCriterias.type_text.active}
                          direction={
                            sortCriterias.type_text.active
                              ? sortCriterias.type_text.type
                              : "asc"
                          }
                          onClick={sortHandler("type_text")}
                        >
                          Report Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        padding="default"
                        style={theme.projectReportHeader}
                      >
                        <TableSortLabel
                          className={classes.active}
                          disabled={!hasRecords()}
                          active={sortCriterias.bom.active}
                          direction={
                            sortCriterias.bom.active
                              ? sortCriterias.bom.type
                              : "asc"
                          }
                          onClick={sortHandler("bom")}
                        >
                          BOM
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" style={theme.projectReportHeader}>
                        <TableSortLabel
                          className={classes.active}
                          disabled={!hasRecords()}
                          active={sortCriterias.issued_date.active}
                          direction={
                            sortCriterias.issued_date.active
                              ? sortCriterias.issued_date.type
                              : "asc"
                          }
                          onClick={sortHandler("issued_date")}
                        >
                          Issue Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" style={theme.projectReportHeader}>
                        <TableSortLabel
                          className={classes.active}
                          disabled={!hasRecords()}
                          active={sortCriterias.factory_witness_date.active}
                          direction={
                            sortCriterias.factory_witness_date.active
                              ? sortCriterias.factory_witness_date.type
                              : "asc"
                          }
                          onClick={sortHandler("factory_witness_date")}
                        >
                          Factory Witness Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        padding="default"
                        style={theme.technologyTagsColumn}
                      >
                        <TableSortLabel
                          className={classes.active}
                          disabled={!hasRecords()}
                          active={sortCriterias.technology_tags.active}
                          direction={
                            sortCriterias.technology_tags.active
                              ? sortCriterias.technology_tags.type
                              : "asc"
                          }
                          onClick={sortHandler("technology_tags")}
                        >
                          Technology Tags
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderProjectReports()}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Slide>
      </Grid>
    </>
  );
};
