// import React from "react";
// import Button from "@material-ui/core/Button";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import Popper from "@material-ui/core/Popper";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Link from "@material-ui/core/Link";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import { useClearButtonsStyles } from "../../themes/appStyles";

// export default () => {
//   const [open, setOpen] = React.useState(false);
//   const anchorRef = React.useRef(null);
//   const newClasses = useClearButtonsStyles();

//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }

//     setOpen(false);
//   };

//   function handleListKeyDown(event) {
//     if (event.key === "Tab") {
//       event.preventDefault();
//       setOpen(false);
//     }
//   }

//   const prevOpen = React.useRef(open);
//   React.useEffect(() => {
//     if (prevOpen.current === true && open === false) {
//       anchorRef.current.focus();
//     }

//     prevOpen.current = open;
//   }, [open]);

//   return (
//     <div>
//       <Button
//         variant="contained"
//         className={newClasses.scorecardsStyle}
//         ref={anchorRef}
//         aria-controls={open ? "menu-list-grow" : undefined}
//         aria-haspopup="true"
//         onClick={handleToggle}
//       >
//         Scorecards <ArrowDropDownIcon />
//       </Button>
//       <Popper
//         open={open}
//         anchorEl={anchorRef.current}
//         role={undefined}
//         transition
//         disablePortal
//       >
//         {({ TransitionProps, placement }) => (
//           <Grow
//             {...TransitionProps}
//             style={{
//               transformOrigin:
//                 placement === "bottom" ? "center top" : "center bottom",
//             }}
//           >
//             <Paper>
//               <ClickAwayListener onClickAway={handleClose}>
//                 <MenuList
//                   autoFocusItem={open}
//                   id="menu-list-grow"
//                   onKeyDown={handleListKeyDown}
//                 >
//                   <MenuItem onClick={handleClose}>
//                     <Link
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       href="https://www.pvel.com/pv-scorecard/"
//                     >
//                       2020 PV Module Reliability Scorecard
//                     </Link>
//                   </MenuItem>
//                   <MenuItem onClick={handleClose}>
//                     <Link
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       href="https://www.pvel.com/inverter-scorecard/"
//                     >
//                       2019 PV Inverter Scorecard
//                     </Link>
//                   </MenuItem>
//                 </MenuList>
//               </ClickAwayListener>
//             </Paper>
//           </Grow>
//         )}
//       </Popper>
//     </div>
//   );
// };


import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useClearButtonsStyles } from "../../themes/appStyles";

export default () => {
  const newClasses = useClearButtonsStyles();

  const handleScorecardsClick = () => {
    window.open("http://www.scorecard.pvel.com", "_blank");
  };

  return (
    <div>
      <Button
        variant="contained"
        className={newClasses.scorecardsStyle}
        onClick={handleScorecardsClick}
      >
        Scorecards 
      </Button>
    </div>
  );
};
