import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { MaybeLoading } from "../../../common/MaybeLoading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { useRouteMatch, Link } from "react-router-dom";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableHead } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import Done from "@material-ui/icons/Done";
import TimerOff from "@material-ui/icons/TimerOff";
import TelegramIcon from "@material-ui/icons/Telegram";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import { useDisableContextMenu } from "../../../common/hooks/useDisableContextMenu";
import { DownloadReportLink } from "../../../common/DownloadReportLink";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "space-around",
  },
  cards: {
    border: "1 px solid grey",
    width: "95%",
    textAlign: "center",
    padding: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
}));

export const ReportRequestsPartnerView = (props) => {
  const {
    reportRequests,
    reportRequestsLoading,
    reports,
    reportsLoading,
    onDownloadReportDashboard,
    onDownloadReportRequestDashboard,
  } = props;

  const { disableContextMenu } = useDisableContextMenu();

  const renderLoading = (loading) => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={loading} />
      </TableCell>
    </TableRow>
  );

  const renderReportStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return (
          <Tooltip title="Report request sent">
            <TelegramIcon />
          </Tooltip>
        );
      case "APPROVED":
        return (
          <Tooltip title="Report request approved">
            <Done />
          </Tooltip>
        );
      case "EXPIRED":
        return (
          <Tooltip title="Report request expired">
            <TimerOff />
          </Tooltip>
        );
      case "REJECTED":
        return (
          <Tooltip title="Report request rejected">
            <Close />
          </Tooltip>
        );
      case "REFUSED":
        return (
          <Tooltip title="Report request rejected">
            <Close />
          </Tooltip>
        );
      default:
        return <div />;
    }
  };

  const renderReportLinkToDownload = (report) => {
    if (report.disclosure === "GENERAL") {
      return (
        <DownloadReportLink
          onContextMenu={disableContextMenu}
          currentReport={report}
          onDownloadReport={onDownloadReportDashboard}
          objectToModify={report}
        />
      );
    }

    return report.title;
  };

  const renderReportRequestDownloadLink = (report) => {
    if (report.status !== "" && report.status === "APPROVED") {
      return (
        <DownloadReportLink
          onContextMenu={disableContextMenu}
          currentReport={report}
          onDownloadReport={onDownloadReportRequestDashboard}
          objectToModify={report}
        />
      );
    }
    return report.title;
  };

  const renderReportRequests = () => {
    if (!isEmpty(reportRequests) && !isEmpty(reportRequests.entities)) {
      return reportRequests.result.map((reportId) => {
        let report = reportRequests.entities.results[reportId];
        return (
          <TableRow key={`my-reports-requests-${reportId}`}>
            <TableCell component="th" scope="row" align="center">
              {renderReportStatus(report.status)}
            </TableCell>
            <TableCell align="center">{report.issued_date}</TableCell>

            <TableCell align="center">
              {renderReportRequestDownloadLink(report)}
            </TableCell>
          </TableRow>
        );
      });
    }
  };

  const renderFollowingIcon = (following) => {
    if (following) {
      return (
        <Tooltip title="Following">
          <Star />
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  const renderReports = () => {
    if (!isEmpty(reports) && !isEmpty(reports.entities)) {
      return reports.result.map((reportId) => {
        let report = reports.entities.results[reportId];
        return (
          <TableRow key={`new-reports-${reportId}`}>
            <TableCell component="th" scope="row" align="center">
              {renderFollowingIcon(report.following)}
            </TableCell>
            <TableCell component="th" scope="row" align="center">
              {report.entity_display_name}
            </TableCell>
            <TableCell align="center">{report.issued_date}</TableCell>
            <TableCell align="center">
              {renderReportLinkToDownload(report)}
            </TableCell>
          </TableRow>
        );
      });
    }
  };

  const classes = useStyles();

  const theme = useTheme();
  const importedClasses = useClearButtonsStyles();
  const { path } = useRouteMatch();

  return (
    <Grid container className={classes.root}>
      <Grid item md={6} xs={12}>
        <Typography variant="h4" style={theme.pvelH2Center}>
          New Reports
        </Typography>
        <Card className={classes.cards}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={theme.tableHeader}></TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    COMPANY
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    RELEASE DATE
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    REPORT NAME
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportsLoading
                  ? renderLoading(reportsLoading)
                  : renderReports()}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            component={Link}
            to={`${path}/find-a-report`}
            className={importedClasses.reportButtons}
          >
            SEE ALL
          </Button>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Tooltip
          title="See all the report requests you have submitted to manufacturers below."
          style={theme.pvelH3}
        >
          <Typography variant="h4" style={theme.pvelH2Center}>
            My Report Requests
          </Typography>
        </Tooltip>
        <Card className={classes.cards}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={theme.tableHeader}></TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    RELEASE DATE
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    REPORT NAME
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportRequestsLoading
                  ? renderLoading(reportRequestsLoading)
                  : renderReportRequests()}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            component={Link}
            className={importedClasses.reportButtons}
            to={`${path}/my-reports`}
          >
            SEE ALL
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};

ReportRequestsPartnerView.propTypes = {
  reportRequests: PropTypes.object.isRequired,
  reportRequestsLoading: PropTypes.bool.isRequired,
  reports: PropTypes.object.isRequired,
  reportsLoading: PropTypes.bool.isRequired,
  onDownloadReportDashboard: PropTypes.func.isRequired,
  onDownloadReportRequestDashboard: PropTypes.func.isRequired,
};
