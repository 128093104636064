import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MessagePvelPartnerContainer } from "./MessagePvelPartner/MessagePvelPartnerContainer";
import { SummaryActivityPartnerView } from "./SummaryActivityPartner/SummaryActivityPartnerView";
import { ReportRequestsPartnerContainer } from "./ReportRequestsPartner/ReportRequestsPartnerContainer";
import { TwitterMarketingPartnerView } from "./TwitterMarketingPartner/TwitterMarketingPartnerView";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(10),
  },
}));

export const DashboardPartnerView = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div style={theme.generalContainer}>
      <Grid container className={classes.root}>
        <Grid item md={6} xs={12}>
          <MessagePvelPartnerContainer />
        </Grid>
        <Grid item md={6} xs={12}>
          <SummaryActivityPartnerView />
        </Grid>
        <Grid item md={12} xs={12}>
          <ReportRequestsPartnerContainer />
        </Grid>
        <Grid item md={12} xs={12}>
          <TwitterMarketingPartnerView />
        </Grid>
      </Grid>
    </div>
  );
};
