import { responseBody } from "../../util/fetchUtil";
import { authorizationHeader } from "../../util/fetchUtil";
import { TokenManager } from "../../util/TokenManager";

// const host = process.env.REACT_APP_API_HOST;
const host = "https://portolaprod.azurewebsites.net/";

export const loadDownstreamPartners = async () => {
  try {
    const url = `${host}/api/entities/?type=PARTNER&limit=-1`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const partnersResponseAsJSON = await responseBody(response);
    return partnersResponseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadManufacturers = async () => {
  try {
    const url = `${host}/api/entities/?type=MANUFACTURER&limit=-1`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const followManufacturer = async (manufacturerId) => {
  try {
    const url = `${host}/api/entities/${manufacturerId}/follow/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-Type": "application/json",
      },
      body: "",
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const unFollowManufacturer = async (manufacturerId) => {
  try {
    const url = `${host}/api/entities/${manufacturerId}/unfollow/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
        "Content-type": "application/json",
      },
      body: "",
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadMessageFromPvel = async () => {
  try {
    const url = `${host}/api/newsfeed/`; //
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};

export const loadReportsRequests = async () => {
  try {
    const url = `${host}/api/requests/new/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: authorizationHeader(TokenManager.getToken()),
      },
    });
    const responseAsJSON = await responseBody(response);
    return responseAsJSON;
  } catch (err) {
    throw err;
  }
};
