import React, { useEffect } from "react";
import { MessagePvelPartnerView } from "./MessagePvelPartnerView";
import { useSelector, useDispatch } from "react-redux";
import { loadMessageFromPvelRequested } from "../";
import isEmpty from "lodash/isEmpty";

export const MessagePvelPartnerContainer = () => {
  const messageFromPvelPartner = useSelector(
    (state) => state.dashboardPartner.messageFromPvelPartner,
  );
  const messageFromPvelPartnerLoading = useSelector(
    (state) => state.dashboardPartner.messageFromPvelPartnerLoading,
  );

  const dispatch = useDispatch();

  const onLoadMessageFromPvelRequested = React.useCallback(() => {
    if (isEmpty(messageFromPvelPartner)) {
      dispatch(loadMessageFromPvelRequested());
    }
  }, [dispatch]);

  useEffect(() => {
    onLoadMessageFromPvelRequested();
  }, [onLoadMessageFromPvelRequested]);

  return (
    <MessagePvelPartnerView
      messageFromPvelPartner={messageFromPvelPartner}
      messageFromPvelPartnerLoading={messageFromPvelPartnerLoading}
    />
  );
};
