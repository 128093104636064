import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useLocation, Link } from "react-router-dom";
import { useClearButtonsStyles } from "../../themes/appStyles";
import logo from "../../assets/images/KiwaLogo.png";

export const LogoButton = ({ homeRoute }) => {
  const newClasses = useClearButtonsStyles();
  const location = useLocation();

  if (location.pathname !== homeRoute) {
    return (
      <Button component={Link} to={homeRoute} className={newClasses.logoButton}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <img src={logo} alt="Logo" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="button" display="block" gutterBottom>
              Return to Dashboard
            </Typography>
          </Grid>
        </Grid>
      </Button>
    );
  } else {
    return (
      <div>
        <img src={logo} alt="Logo" />
      </div>
    );
  }
};
