import { createSlice } from "@reduxjs/toolkit";
import {
  loadPendingReports,
  loadProcessedReports,
  discloseReport,
  downloadReport,
  discloseReportUpdate,
} from "./reportServices";
import { getApiPaginationParams } from "../../../util/appUtil";
import isEmpty from "lodash/isEmpty";

import { normalize, schema } from "normalizr";
const result = new schema.Entity("results");
const resultSchema = { results: [result] };

const initialState = {
  pendingReportsLoading: false,
  pendingReportsCurrentPage: 0,
  pendingReportsCount: 0,
  pendingReportsRowsPerPage: 25,
  pendingReportsSortCriterias: {
    reportId: { active: false, type: "asc" },
    reportName: { active: false, type: "asc" },
    projectNumber: { active: false, type: "asc" },
    reportDate: { active: false, type: "asc" },
    reportType: { active: false, type: "asc" },
  },
  pendingReports: {},
  errorPendingReports: null,
  processedReportsLoading: false,
  processedReportsCurrentPage: 0,
  processedReportsCount: 0,
  processedReportsRowsPerPage: 25,
  processedReportsSortCriterias: {
    reportId: { active: false, type: "asc" },
    reportName: { active: false, type: "asc" },
    projectNumber: { active: false, type: "asc" },
    reportDate: { active: false, type: "asc" },
    reportType: { active: false, type: "asc" },
  },
  processedReports: {},
  currentProjectFilter: null,
  errorProcessedReports: null,
  errorDisclosingReport: null,
  successDisclosingReport: false,
  downloadReportLoading: false,
  discloseReportLoading: false,
  errorDownloadReport: null,
};

const reportSlice = createSlice({
  name: "manufacturer/reports",
  initialState: initialState,
  reducers: {
    loadPendingReportsPending: (state, action) => {
      state.pendingReports = {};
      state.pendingReportsLoading = true;
      state.pendingReportsCurrentPage = action.payload.pageToRequest;
      state.pendingReportsRowsPerPage = action.payload.limitToRequest;
      state.errorPendingReports = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.pendingReportsSortCriterias =
            initialState.pendingReportsSortCriterias;
        } else {
          state.pendingReportsSortCriterias = action.payload.sort;
        }
      }
    },
    loadPendingReportsFulfilled: (state, action) => {
      state.pendingReportsLoading = false;
      state.pendingReportsCount = action.payload.count;
      state.pendingReports = normalize(action.payload, resultSchema);
    },
    loadPendingReportsRejected: (state, action) => {
      state.pendingReportsLoading = false;
      state.errorPendingReports = action.payload;
    },
    discloseReportPending: (state, action) => {
      state.discloseReportLoading = true;
      state.errorDisclosingReport = null;
      state.successDisclosingReport = false;
    },
    discloseReportFulfilled: (state, action) => {
      state.discloseReportLoading = false;
      state.successDisclosingReport = true;
      let { [action.payload.reportId]: _, ...result } =
        state.pendingReports.entities.results;
      state.pendingReports.entities.results = result;
      state.pendingReports.result.results =
        state.pendingReports.result.results.filter(
          (item) => item !== action.payload.reportId,
        );
      state.pendingReportsCount = state.pendingReportsCount - 1;
    },
    discloseReportRejected: (state, action) => {
      state.discloseReportLoading = false;
      state.errorDisclosingReport = action.payload;
    },
    downloadPendingReportPending: (state, action) => {
      state.pendingReports.entities.results[action.payload].loading = true;
    },
    downloadPendingReportFulfilled: (state, action) => {
      state.pendingReports.entities.results[action.payload].loading = false;
    },
    downloadPendingReportRejected: (state, action) => {
      state.errorDownloadReport = action.payload;
      state.pendingReports.entities.results[action.payload].loading = true;
    },
    downloadProcessedReportPending: (state, action) => {
      state.processedReports.entities.results[action.payload].loading = true;
    },
    downloadProcessedReportFulfilled: (state, action) => {
      state.processedReports.entities.results[action.payload].loading = false;
    },
    downloadProcessedReportRejected: (state, action) => {
      state.errorDownloadReport = action.payload;
      state.processedReports.entities.results[action.payload].loading = true;
    },
    loadProcessedReportsPending: (state, action) => {
      state.processedReports = {};
      if (action.payload.projectNumber) {
        state.currentProjectFilter = action.payload.projectNumber;
      } else {
        state.currentProjectFilter = null;
      }
      state.processedReportsLoading = true;
      state.processedReportsCurrentPage = action.payload.pageToRequest;
      state.processedReportsRowsPerPage = action.payload.limitToRequest;
      state.errorProcessedReports = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.processedReportsSortCriterias =
            initialState.processedReportsSortCriterias;
        } else {
          state.processedReportsSortCriterias = action.payload.sort;
        }
      }
    },
    loadProcessedReportsFulfilled: (state, action) => {
      state.processedReportsLoading = false;
      state.processedReportsCount = action.payload.count;
      state.processedReports = normalize(action.payload, resultSchema);
    },
    loadProcessedReportsRejected: (state, action) => {
      state.errorProcessedReports = action.payload;
      state.processedReportsLoading = false;
    },
    resetErrorsPendingReportsRequested: (state, action) => {
      state.errorPendingReports = null;
      state.errorDisclosingReport = null;
      state.successDisclosingReport = false;
    },
    resetErrorsProcessedReportsRequested: (state, action) => {
      state.errorProcessedReports = null;
      state.errorDownloadReport = null;
    },
  },
});

export const { actions, reducer } = reportSlice;

// My Reports Reports Thunks

export const loadPendingReportsRequested = ({
  page,
  limit,
  offset,
  sort,
  clearSort,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.pendingReportsRowsPerPage,
        initialState.pendingReportsCurrentPage,
      );
    dispatch(
      actions.loadPendingReportsPending({
        pageToRequest,
        limitToRequest,
        sort,
        clearSort,
      }),
    );
    try {
      const sortQueryString = getSortQueryForReports(sort);
      const result = await loadPendingReports(
        limitToRequest,
        offsetToRequest,
        sortQueryString,
      );
      dispatch(actions.loadPendingReportsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadPendingReportsRejected(err));
    }
  };
};

export const disclosedReport = (reportId, disclosureType, params) => {
  return async (dispatch) => {
    dispatch(actions.discloseReportPending());
    try {
      let result = await discloseReport(reportId, disclosureType);
      dispatch(actions.discloseReportFulfilled({ result, reportId }));
      /* dispatch(
        loadPendingReportsRequested({
          page: initialState.pendingReportsCurrentPage,
          limit: params.pendingReportsRowsPerPage,
          offset:
            initialState.pendingReportsCurrentPage *
            params.pendingReportsRowsPerPage,
          sort: params.sortPendingReports,
          clearSort: false,
        })
      ); */
      dispatch(
        loadProcessedReportsRequested({
          page: initialState.processedReportsCurrentPage,
          limit: params.processedReportsRowsPerPage,
          offset:
            initialState.processedReportsCurrentPage *
            params.processedReportsRowsPerPage,
          sort: params.sortProcessedReports,
          clearSort: false,
          projectNumber: params.projectNumber,
        }),
      );
    } catch (err) {
      dispatch(actions.discloseReportRejected(err));
    }
  };
};


export const disclosedReportUpdate = (reportId, disclosureType, params) => {
  return async (dispatch) => {
    dispatch(actions.discloseReportPending());
    try {
      let result = await discloseReportUpdate(reportId, disclosureType);
      dispatch(actions.discloseReportFulfilled({ result, reportId }));
      /* dispatch(
        loadPendingReportsRequested({
          page: initialState.pendingReportsCurrentPage,
          limit: params.pendingReportsRowsPerPage,
          offset:
            initialState.pendingReportsCurrentPage *
            params.pendingReportsRowsPerPage,
          sort: params.sortPendingReports,
          clearSort: false,
        })
      ); */
      dispatch(
        loadProcessedReportsRequested({
          page: initialState.processedReportsCurrentPage,
          limit: params.processedReportsRowsPerPage,
          offset:
            initialState.processedReportsCurrentPage *
            params.processedReportsRowsPerPage,
          sort: params.sortProcessedReports,
          clearSort: false,
          projectNumber: params.projectNumber,
        }),
      );
    } catch (err) {
      dispatch(actions.discloseReportRejected(err));
    }
  };
};

export const loadProcessedReportsRequested = ({
  page,
  limit,
  offset,
  projectNumber,
  sort,
  clearSort,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.processedReportsRowsPerPage,
        initialState.processedReportsCurrentPage,
      );
    dispatch(
      actions.loadProcessedReportsPending({
        pageToRequest,
        limitToRequest,
        sort,
        clearSort,
        projectNumber,
      }),
    );
    try {
      const projectFilter = !projectNumber
        ? ""
        : `&project__number=${projectNumber}`;
      const queryString = getSortQueryForReports(sort);
      let result = await loadProcessedReports(
        limitToRequest,
        offsetToRequest,
        projectFilter,
        queryString,
      );
      dispatch(actions.loadProcessedReportsFulfilled(result));
    } catch (err) {
      dispatch(actions.loadProcessedReportsRejected(err));
    }
  };
};

export const downloadedPendingReport = (report) => {
  return async (dispatch) => {
    dispatch(actions.downloadPendingReportPending(report.id));
    try {
      await downloadReport(report);
      dispatch(actions.downloadPendingReportFulfilled(report.id));
    } catch (err) {
      dispatch(actions.downloadPendingReportRejected(err));
    }
  };
};

export const downloadedProcessedReport = (report) => {
  return async (dispatch) => {
    dispatch(actions.downloadProcessedReportPending(report.id));
    try {
      await downloadReport(report);
      dispatch(actions.downloadProcessedReportFulfilled(report.id));
    } catch (err) {
      dispatch(actions.downloadProcessedReportRejected(err));
    }
  };
};

const getSortQueryForReports = (sort) => {
  let queryString = "";
  if (
    !isEmpty(sort) &&
    (sort.reportId.active ||
      sort.reportName.active ||
      sort.projectNumber.active ||
      sort.reportDate.active ||
      sort.reportType.active)
  ) {
    let sortParametersArray = [];
    if (sort.reportId.active) {
      sortParametersArray.push(sort.reportId.type === "asc" ? "id" : "-id");
    }
    if (sort.reportName.active) {
      sortParametersArray.push(
        sort.reportName.type === "asc" ? "title" : "-title",
      );
    }
    if (sort.projectNumber.active) {
      sortParametersArray.push(
        sort.projectNumber.type === "asc" ? "project" : "-project",
      );
    }
    if (sort.reportDate.active) {
      sortParametersArray.push(
        sort.reportDate.type === "asc" ? "issued_date" : "-issued_date",
      );
    }
    if (sort.reportType.active) {
      sortParametersArray.push(
        sort.reportType.type === "asc" ? "type" : "-type",
      );
    }
    queryString += `&ordering=${sortParametersArray.join()}`;
    return queryString;
  }
};
