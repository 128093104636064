import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { ShowDetailsButton } from "features/common/ShowDetailsButton";
import isEmpty from "lodash/isEmpty";
import Link from "@material-ui/core/Link";

const ProjectReportsRowComponent = (props) => {
  const { entityType, currentProject, toggleCurrentView, projectData } = props;
  const theme = useTheme();

  return (
    <>
      <TableRow hover tabIndex={-1} key={projectData.id}>
        <TableCell>
          <ShowDetailsButton
            text={projectData.number}
            tooltipText="Click to display the reports that belong to this project"
            isOpen={currentProject === null}
            toggleCurrentView={toggleCurrentView(projectData.id)}
          />
        </TableCell>
        <TableCell>{projectData.type_text}</TableCell>
        <TableCell>{projectData.name}</TableCell>
        <TableCell>
          <Link
            style={theme.downloadLink}
            href={`mailto:${projectData.pvel_manager_user}`}
          >
            {projectData.pvel_manager_name}
          </Link>
        </TableCell>

        {entityType !== "Downstream Client" && (
          <TableCell>
            <Link
              style={theme.downloadLink}
              href={`mailto:${projectData.document_approver_user}`}
            >
              {isEmpty(projectData.document_approver_name) ||
              projectData.document_approver_name === ", "
                ? projectData.document_approver_user
                : projectData.document_approver_name}
            </Link>
          </TableCell>
        )}
        <TableCell>
          <Link
            style={theme.downloadLink}
            href={`mailto:${projectData.primary_contact_user}`}
          >
            {isEmpty(projectData.primary_contact_name) ||
            projectData.primary_contact_name === ", "
              ? projectData.primary_contact_user
              : projectData.primary_contact_name}
          </Link>
        </TableCell>
        <TableCell>{projectData.contract_signature}</TableCell>
        <TableCell>{projectData.last_document_date}</TableCell>
        <TableCell>{projectData.document_project.length}</TableCell>
      </TableRow>
    </>
  );
};

export const ProjectReportsRowView = React.memo(ProjectReportsRowComponent);
