import { createSlice } from "@reduxjs/toolkit";
import { loadUsers } from "./userServices";
import { getApiPaginationParams } from "../../../util/appUtil";
import isEmpty from "lodash/isEmpty";

import { normalize, schema } from "normalizr";
const result = new schema.Entity("results");
const resultSchema = { results: [result] };

const initialState = {
  usersLoading: false,
  usersCurrentPage: 0,
  usersCount: 0,
  usersRowsPerPage: 25,
  usersSortCriterias: {
    email: { active: false, type: "asc" },
    username: { active: false, type: "asc" },
    first_name: { active: false, type: "asc" },
    last_name: { active: false, type: "asc" },
    date_joined: { active: false, type: "asc" },
    entity_legal_name: { active: false, type: "asc" },
  },
  users: {},
  errorUsers: null,
};

const reportSlice = createSlice({
  name: "pvel/users",
  initialState: initialState,
  reducers: {
    loadUsersPending: (state, action) => {
      state.users = {};
      state.usersLoading = true;
      state.usersCurrentPage = action.payload.pageToRequest;
      state.usersRowsPerPage = action.payload.limitToRequest;
      state.errorUsers = null;
      if (action.payload.sort !== null || action.payload.clearSort !== false) {
        if (action.payload.clearSort) {
          state.usersSortCriterias = initialState.usersSortCriterias;
        } else {
          state.usersSortCriterias = action.payload.sort;
        }
      }
    },
    loadUsersFulfilled: (state, action) => {
      state.usersLoading = false;
      state.usersCount = action.payload.count;
      state.users = normalize(action.payload, resultSchema);
    },
    loadUsersRejected: (state, action) => {
      state.usersLoading = false;
      state.errorUsers = action.payload;
    },
  },
});

export const { actions, reducer } = reportSlice;

// Users Thunks

export const loadUsersRequested = ({
  page,
  limit,
  offset,
  sort,
  clearSort,
}) => {
  return async (dispatch) => {
    const { pageToRequest, limitToRequest, offsetToRequest } =
      getApiPaginationParams(
        page,
        limit,
        offset,
        initialState.usersRowsPerPage,
        initialState.usersCurrentPage,
      );
    dispatch(
      actions.loadUsersPending({
        pageToRequest,
        limitToRequest,
        sort,
        clearSort,
      }),
    );
    try {
      const sortQueryString = getSortQueryForUsers(sort);
      const result = await loadUsers(
        limitToRequest,
        offsetToRequest,
        sortQueryString,
      );
      dispatch(actions.loadUsersFulfilled(result));
    } catch (err) {
      dispatch(actions.loadUsersRejected(err));
    }
  };
};

const getSortQueryForUsers = (sort) => {
  let queryString = "";
  if (
    !isEmpty(sort) &&
    (sort.email.active ||
      sort.username.active ||
      sort.first_name.active ||
      sort.last_name.active ||
      sort.date_joined.active ||
      sort.entity_legal_name.active)
  ) {
    let sortParametersArray = [];
    if (sort.email.active) {
      sortParametersArray.push(sort.email.type === "asc" ? "email" : "-email");
    }
    if (sort.first_name.active) {
      sortParametersArray.push(
        sort.first_name.type === "asc" ? "first_name" : "-first_name",
      );
    }
    if (sort.last_name.active) {
      sortParametersArray.push(
        sort.last_name.type === "asc" ? "last_name" : "-last_name",
      );
    }
    if (sort.date_joined.active) {
      sortParametersArray.push(
        sort.date_joined.type === "asc" ? "date_joined" : "-date_joined",
      );
    }
    if (sort.entity_legal_name.active) {
      sortParametersArray.push(
        sort.entity_legal_name.type === "asc"
          ? "entity_legal_name"
          : "-entity_legal_name",
      );
    }
    queryString += `&ordering=${sortParametersArray.join()}`;
    return queryString;
  }
};
