import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequested } from "../index";
import { ForgotPasswordView } from "./ForgotPasswordView";

export const ForgotPasswordContainer = () => {
  const errorForgotPasswordRequested = useSelector(
    (state) => state.auth.errorForgotPasswordRequested,
  );
  const successForgotPasswordRequested = useSelector(
    (state) => state.auth.successForgotPasswordRequested,
  );

  const dispatch = useDispatch();
  const onForgotPasswordRequested = React.useCallback(
    (email) => dispatch(forgotPasswordRequested(email)),
    [dispatch],
  );

  return (
    <ForgotPasswordView
      errorForgotPasswordRequested={errorForgotPasswordRequested}
      successForgotPasswordRequested={successForgotPasswordRequested}
      onForgotPasswordRequested={onForgotPasswordRequested}
    />
  );
};
