import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useProgressStyles = makeStyles((theme) => ({
  progress: { margin: theme.spacing(2) },
}));

export const MaybeLoading = ({ loading }) => {
  const classes = useProgressStyles();
  return loading ? <CircularProgress className={classes.progress} /> : null;
};
