import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { MaybeLoading } from "../../common/MaybeLoading";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import isEmpty from "lodash/isEmpty";
import Link from "@material-ui/core/Link";
import { FetchError } from "features/common/FetchError";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(3),
      width: 300,
    },
    marginTop: theme.spacing(5),
  },
  icon: {
    fontSize: "30px",
    verticalAlign: "baseline",
    marginTop: "15px",
  },
  formcontrol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-end",
  },
  title: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  followButtons: {
    color: "#FFFFFF",
    background: "#003366",
    borderRadius: 20,
    "&:hover": {
      color: "#003366",
      borderColor: "#003366",
    },
  },
  unfollowButtons: {
    borderColor: "#003366",
    color: "#003366",
    borderRadius: 20,
    "&:hover": {
      color: "#FFFFFF",
      background: "#003366",
    },
  },
}));

const ManufacturersView = (props) => {
  const {
    onFollowedManufacturer,
    onUnfollowedManufacturer,
    manufacturersLoading,
    followManufacturerLoading,
    filteredManufacturers,
    onSearchManufacturer,
    manufacturerIdFollowed,
    manufacturersError,
    loadManufacturersRequested,
    errorFollowManufacturer,
    successFollowManufacturer,
    successUnfollowManufacturer,
    resetErrorFollowManufacturerRequested,
  } = props;

  React.useEffect(() => {
    checkForFollowingStatus();
    resetErrorFollowManufacturerRequested();
  }, [
    errorFollowManufacturer,
    successFollowManufacturer,
    successUnfollowManufacturer,
  ]);

  const theme = useTheme();
  const classes = useStyles();

  const checkForFollowingStatus = () => {
    if (!isEmpty(errorFollowManufacturer)) {
      toast.error(errorFollowManufacturer.detail);
    } else if (successFollowManufacturer) {
      toast.success("Follow manufacturer was successful.");
    } else if (successUnfollowManufacturer) {
      toast.success("Unfollow manufacturer was successful.");
    }
  };

  const delayedQuery = debounce((value) => onSearchManufacturer(value), 500);

  const handleFilterContent = (e) => {
    delayedQuery(e.target.value);
  };

  const renderLoader = (loading) => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={loading} />
      </TableCell>
    </TableRow>
  );

  const handleFollowManufacturer = (event) => {
    onFollowedManufacturer(event.currentTarget.id);
  };

  const handleUnfollowManufacturer = (event) => {
    onUnfollowedManufacturer(event.currentTarget.id);
  };

  const renderFollowingIcon = (manufacturer) => {
    if (
      followManufacturerLoading &&
      manufacturer.id === parseInt(manufacturerIdFollowed)
    )
      return <MaybeLoading loading={followManufacturerLoading} />;

    return manufacturer.following ? (
      <Button
        variant="outlined"
        startIcon={<Close />}
        className={classes.unfollowButtons}
        id={manufacturer.id}
        onClick={handleUnfollowManufacturer}
      >
        Unfollow
      </Button>
    ) : (
      <Button
        variant="contained"
        startIcon={<Add />}
        className={classes.followButtons}
        id={manufacturer.id}
        onClick={handleFollowManufacturer}
      >
        Follow
      </Button>
    );
  };

  const renderWebsiteLink = (manufacturer) => {
    if (manufacturer.website !== "") {
      return (
        <Link
          style={theme.downloadLink}
          href={manufacturer.website}
          target="_blank"
          rel="noopener noreferrer"
        >
          {manufacturer.display_name}
        </Link>
      );
    }
    return manufacturer.display_name;
  };

  const renderTableBody = () => {
    return (
      !isEmpty(filteredManufacturers) &&
      filteredManufacturers.map((manufacturer) => {
        return (
          <TableRow key={`manufacturer-${manufacturer.id}`}>
            <TableCell align="left">
              {renderWebsiteLink(manufacturer)}
            </TableCell>
            <TableCell align="left">{manufacturer.country}</TableCell>
            <TableCell align="left">
              {renderFollowingIcon(manufacturer)}
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  if (manufacturersError && !filteredManufacturers) {
    return (
      <FetchError
        title="Error while loading data for Manufacturers. Expand for more details"
        error={manufacturersError}
        onRetry={() => loadManufacturersRequested()}
      />
    );
  }

  return (
    <div style={theme.mainContainer}>
      <div className={classes.root}>
        <FormControl className={classes.formcontrol}>
          <TextField
            id="standar-multiline-flexible"
            label="search"
            type="search"
            color="secondary"
            className={classes.input}
            onChange={handleFilterContent}
          />
          <Search className={classes.icon} />
        </FormControl>
      </div>
      <Paper style={theme.tableContainer}>
        <TableContainer style={theme.table}>
          <Table stickyHeader aria-label="My Manufacturer Partners">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={theme.tableHeader}>
                  Company
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Headquarters Location
                </TableCell>
                <TableCell align="left" style={theme.tableHeader}>
                  Click to Follow
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manufacturersLoading
                ? renderLoader(manufacturersLoading)
                : renderTableBody()}
            </TableBody>
            <TableFooter>
              <TableRow></TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

ManufacturersView.propTypes = {
  onFollowedManufacturer: PropTypes.func.isRequired,
  manufacturersLoading: PropTypes.bool.isRequired,
  followManufacturerLoading: PropTypes.bool.isRequired,
  filteredManufacturers: PropTypes.array,
  onSearchManufacturer: PropTypes.func.isRequired,
  manufacturerIdFollowed: PropTypes.string.isRequired,
  manufacturersError: PropTypes.any,
  loadManufacturersRequested: PropTypes.func.isRequired,
};

export default React.memo(ManufacturersView);
