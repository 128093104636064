import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useDialog } from "../../../common/hooks/useDialog";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import TelegramIcon from "@material-ui/icons/Telegram";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Typography from "@material-ui/core/Typography";
import { MaybeLoading } from "../../../common/MaybeLoading";
import { TablePaginationActions } from "../../../common/TablePaginationActions";
import { usePagination } from "../../../common/hooks/usePagination";
import { RequestedReportInfoView } from "./RequestedReportInfoView";
import Link from "@material-ui/core/Link";
import { FetchError } from "features/common/FetchError";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { useSorting } from "features/common/hooks/useSorting";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import isEmpty from "lodash/isEmpty";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useClearButtonsStyles } from "../../../../themes/appStyles";
import { useDisableContextMenu } from "../../../common/hooks/useDisableContextMenu";
import { DownloadReportLink } from "../../../common/DownloadReportLink";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export const RequestedReportsView = (props) => {
  const {
    onLoadRequestedReports,
    requestedReports,
    requestedReportsCount,
    requestedReportsRowsPerPage,
    requestedReportsCurrentPage,
    requestedReportsLoading,
    onDownloadReport,
    errorRequestedReports,
    requestedReportsSortCriterias,
  } = props;
  const { path } = useRouteMatch();
  const theme = useTheme();
  const newClasses = useClearButtonsStyles();
  const classes = useStyles();
  const [item, open, toggleDialog] = useDialog();
  const { sortHandler, clearSorting, isSortingDisabled } = useSorting({
    sortCriterias: requestedReportsSortCriterias,
    getDataFunc: refreshRequests,
  });

  const { handleChangePage, handleChangeRowsPerPage } = usePagination({
    actionCreator: onLoadRequestedReports,
    limit: requestedReportsRowsPerPage,
    sort: requestedReportsSortCriterias,
  });

  const { disableContextMenu } = useDisableContextMenu();

  function refreshRequests({ sort, clearSort }) {
    onLoadRequestedReports({
      page: requestedReportsRowsPerPage,
      limit: requestedReportsCurrentPage,
      offset: requestedReportsCurrentPage * requestedReportsRowsPerPage,
      sort,
      clearSort,
    });
  }

  const hasRecords = () => !isEmpty(requestedReports);

  const getIconFromStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return (
          <Tooltip title="Request Sent">
            <TelegramIcon />
          </Tooltip>
        );
      case "APPROVED":
        return (
          <Tooltip title="Request Approved">
            <Done />
          </Tooltip>
        );
      case "EXPIRED":
        return (
          <Tooltip title="Request Expired">
            <Icon className="fa fa-hourglass-end" />
          </Tooltip>
        );
      case "REJECTED":
        return (
          <Tooltip title="Request Rejected">
            <Close />
          </Tooltip>
        );
      case "REFUSED":
        return (
          <Tooltip title="Request Refused">
            <Close />
          </Tooltip>
        );
      default:
        return <div />;
    }
  };

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={requestedReportsLoading} />
      </TableCell>
    </TableRow>
  );

  const renderDownloadReportLink = (request) => {
    if (request.status === "APPROVED") {
      return (
        <TableCell align="center">
          <DownloadReportLink
            onContextMenu={disableContextMenu}
            currentReport={request.document}
            onDownloadReport={onDownloadReport}
            objectToModify={request}
          />
        </TableCell>
      );
    }

    return <TableCell align="center">{request.document.title}</TableCell>;
  };

  const isPVELUser = () => {
    return path.includes("/pvel");
  };

  const renderTableBody = () => {
    if (!isEmpty(requestedReports) && !isEmpty(requestedReports.entities)) {
      return requestedReports.result.results.map((reportId) => {
        let request = requestedReports.entities.results[reportId];
        return (
          <TableRow key={`my-requested-report-${reportId}`}>
            <TableCell align="center">
              {request.document.entity_display_name}
            </TableCell>
            <TableCell align="center">
              <Tooltip title="More Info">
                <IconButton
                  style={theme.infoButtonColor}
                  aria-label="info"
                  component="span"
                  onClick={toggleDialog(true, request)}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              {getIconFromStatus(request.status)}
            </TableCell>
            {renderDownloadReportLink(request)}
            <TableCell align="center">
              {request.document.project_number}
            </TableCell>
            <TableCell align="center">
              {request?.document.issued_date}
            </TableCell>
            <TableCell align="center">
              {request?.document.technology_tags.join(", ")}
            </TableCell>
            <TableCell align="center">{request.document.type_text}</TableCell>
            {isPVELUser() && (
              <TableCell align="center">{request.requestor_company}</TableCell>
            )}
          </TableRow>
        );
      });
    }
  };

  if (errorRequestedReports && !requestedReports.length) {
    return (
      <div className={classes.root}>
        <FetchError
          title="Error while loading data for requested reports. Expand for more details"
          error={errorRequestedReports}
          onRetry={() => refreshRequests({ sort: null, clearSort: false })}
        />
      </div>
    );
  }

  return (
    <div style={theme.generalContainer}>
      <div className={classes.root}>
        <Typography variant="h1" style={theme.pvelH1} gutterBottom>
          My Report Requests
        </Typography>
        <Typography variant="h5" style={theme.pvelH2} gutterBottom>
          Requested Reports
        </Typography>
        <Typography style={theme.paragraph}>
          Download your requested reports and view your outstanding requests to
          manufacturers below. If the manufacturer does not respond within 30
          days, your report request will expire.
        </Typography>
        <Typography style={theme.paragraph}>
          Note: Confidentiality terms apply to any/all reports and data
          contained here. For questions, please consult your confidentiality
          agreement with PVEL or email&nbsp;
          <Link href="mailto:portal@pvel.com" style={theme.downloadLink}>
            portal@pvel.com
          </Link>
          .
        </Typography>
        <div style={theme.mainContainer}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            item
            xs={12}
            alignItems="center"
          >
            <Button
              variant="outlined"
              className={newClasses.clearButtons}
              onClick={clearSorting}
              disabled={isSortingDisabled()}
            >
              Clear Sorting
            </Button>
          </Grid>
        </div>
        <Paper style={theme.tableContainer}>
          <TableContainer style={theme.table}>
            <Table stickyHeader aria-label="My Requested Reports Table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={theme.tableHeader}>
                    Company
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    Info
                  </TableCell>
                  <TableCell align="left" style={theme.tableHeader}>
                    <TableSortLabel
                      disabled={!hasRecords()}
                      active={requestedReportsSortCriterias.status.active}
                      direction={
                        requestedReportsSortCriterias.status.active
                          ? requestedReportsSortCriterias.status.type
                          : "asc"
                      }
                      onClick={sortHandler("status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    Report Title
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    Project No.
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    Report Date
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    Technology Tags
                  </TableCell>
                  <TableCell align="center" style={theme.tableHeader}>
                    Report Type
                  </TableCell>
                  {isPVELUser() && (
                    <TableCell align="center" style={theme.tableHeader}>
                      Requestor
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestedReportsLoading ? renderLoader() : renderTableBody()}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[25, { label: "All", value: -1 }]}
            colSpan={3}
            count={requestedReportsCount}
            rowsPerPage={requestedReportsRowsPerPage}
            page={requestedReportsCurrentPage}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        <Dialog open={open} keepMounted onClose={toggleDialog(false, null)}>
          <DialogTitle id="Info Title">{`Request# ${
            item ? item.id : ""
          }`}</DialogTitle>
          <DialogContent>
            <RequestedReportInfoView requestedReport={item} />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

RequestedReportsView.propTypes = {
  onLoadRequestedReports: PropTypes.func.isRequired,
  onDownloadReport: PropTypes.func.isRequired,
  requestedReports: PropTypes.array.isRequired,
  requestedReportsCount: PropTypes.number.isRequired,
  requestedReportsRowsPerPage: PropTypes.number.isRequired,
  requestedReportsCurrentPage: PropTypes.number.isRequired,
  requestedReportsLoading: PropTypes.bool.isRequired,
  errorRequestedReports: PropTypes.any,
};
