import { createSlice } from "@reduxjs/toolkit";
import { TokenManager } from "../../util/TokenManager";
import {
  login,
  getCurrentUser,
  changeUserProfile,
  changeUserPassword,
  magicLinkRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
} from "./authServices";
import { loadBootstrapDataRequested } from "../bootstrap/index";
import { persistor } from "../../store";

let defaultState = {
  loading: false,
  failed: false,
  loginFailed: false,
  tokenExpired: false,
  actionMessage: null,
  reason: null,
  errorUser: null,
  errorPasswordChange: null,
  successPasswordChange: null,
  successLogin: null,
  successMagicLinkCallback: null,
  errorMagicLinkCallback: null,
  magicLinkAuthToken: null,
  errorForgotPasswordRequested: null,
  successForgotPasswordRequested: null,
  errorResetPasswordRequested: null,
  successResetPasswordRequested: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: defaultState,
  reducers: {
    loginPending: (state, action) => {
      state.loading = true;
      state.loginFailed = false;
      state.reason = null;
      state.errorUser = null;
      state.successLogin = null;
      state.actionMessage = "Verifying credentials...";
      TokenManager.clearToken();
    },
    loginFullfilled: (state, action) => {
      TokenManager.setToken(action.payload.token);
      return {
        ...state,
        ...defaultState,
        successLogin: true,
      };
    },
    loginRejected: (state, action) => {
      TokenManager.clearToken();
      state.loading = false;
      state.loginFailed = true;
      state.actionMessage = "";
      /*  var reason = action.payload.non_field_errors
        ? action.payload.non_field_errors.join("\n")
        : "An unknown error has occurred."; */

      state.reason = action.payload.detail;
    },
    userPending: (state, action) => {
      return {
        ...state,
        ...defaultState,
        actionMessage: "Loading user information...",
      };
    },
    userFulfilled: (state, action) => {
      const user = action.payload.results[0];
      TokenManager.clearToken();
      TokenManager.setToken(user.token);
      return {
        ...state,
        ...defaultState,
        actionMessage: "User information loaded.",
        currentUserChecked: true,
        user,
        displayName:
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.email,
        entityType: user.profile.entity.type_text,
      };
    },
    userRejected: (state, action) => {
      TokenManager.clearToken();

      /* state.reason = action.payload.non_field_errors
        ? action.payload.non_field_errors.join("\n")
        : "An unknown error has occurred."; */
      state.currentUserChecked = true;
      state.tokenExpired = true;
      state.loading = false;
      state.actionMessage = "";
      state.errorUser = action.payload.detail;
    },
    userProfileChangedPending: (state, action) => {
      state.loading = true;
    },
    userProfileChangedFulfilled: (state, action) => {
      state.loading = false;
      state.user.first_name = action.payload.firstName;
      state.user.last_name = action.payload.lastName;
      state.user.profile.bio = action.payload.bio;
      state.user.profile.location = action.payload.location;
      state.user.profile.birth_date = action.payload.birthDate;
    },
    userProfileChangedRejected: (state, action) => {
      state.loading = false;
      state.actionMessage = action.payload;
    },
    userPasswordChangedPending: (state, action) => {
      state.loading = true;
      state.successPasswordChange = false;
      state.errorPasswordChange = null;
      state.successPasswordChange = null;
    },
    userPasswordChangedFulfilled: (state, action) => {
      TokenManager.clearToken();
      TokenManager.setToken(action.payload.token);
      state.loading = false;
      state.successPasswordChange = true;
    },
    userPasswordChangedRejected: (state, action) => {
      state.loading = false;
      state.errorPasswordChange = action.payload;
    },
    resetErrors: (state, action) => {
      state.loading = false;
      state.errorPasswordChange = null;
      state.successPasswordChange = null;
      state.actionMessage = null;
      state.errorUser = null;
    },
    tokenExpirationErrorRaised: (state, action) => {
      state.errorUser = action.payload;
    },
    magicLinkRequestedPending: (state, action) => {
      state.loading = true;
      state.successMagicLinkCallback = null;
      state.errorMagicLinkCallback = null;
      state.magicLinkAuthToken = null;
    },
    magicLinkRequestedFulfilled: (state, action) => {
      TokenManager.setToken(action.payload.token);
      state.loading = false;
      state.successMagicLinkCallback = true;
      state.magicLinkAuthToken = action.payload;
    },
    magicLinkRequestedRejected: (state, action) => {
      state.loading = false;
      state.successMagicLinkCallback = false;
      state.errorMagicLinkCallback = action.payload;
      state.magicLinkAuthToken = null;
    },
    forgotPasswordRequestedPending: (state, action) => {
      state.loading = true;
      state.successForgotPasswordRequested = null;
      state.errorForgotPasswordRequested = null;
    },
    forgotPasswordRequestedFulfilled: (state, action) => {
      state.loading = false;
      state.successForgotPasswordRequested = action.payload;
    },
    forgotPasswordRequestedRejected: (state, action) => {
      state.loading = false;
      state.successForgotPasswordRequested = null;
      state.errorForgotPasswordRequested = action.payload;
    },
    resetPasswordRequestedPending: (state, action) => {
      state.loading = true;
      state.successResetPasswordRequested = null;
      state.errorResetPasswordRequested = null;
    },
    resetPasswordRequestedFulfilled: (state, action) => {
      TokenManager.setToken(action.payload.token);
      state.loading = false;
      state.successResetPasswordRequested = true;
    },
    resetPasswordRequestedRejected: (state, action) => {
      state.loading = false;
      state.successResetPasswordRequested = false;
      state.errorResetPasswordRequested = action.payload;
    },
  },
});

export const { actions, reducer } = authSlice;

// Auth Thunks

export const loginRequested = (username, password) => {
  return async (dispatch) => {
    dispatch(actions.loginPending());
    try {
      const data = new FormData();
      data.append("username", username);
      data.append("password", password);
      let result = await login(data);
      dispatch(actions.loginFullfilled(result));
      dispatch(currentUserRequested());
    } catch (err) {
      dispatch(actions.loginRejected(err));
    }
  };
};

export const currentUserRequested = () => {
  return async (dispatch) => {
    dispatch(actions.userPending());
    try {
      let result = await getCurrentUser(TokenManager.getToken());
      dispatch(actions.userFulfilled(result));
      dispatch(loadBootstrapDataRequested());
    } catch (err) {
      dispatch(actions.userRejected(err));
    }
  };
};

export const currentUserOnApplicationLoadRequested = () => {
  return async (dispatch) => {
    dispatch(actions.userPending());
    try {
      let result = await getCurrentUser(TokenManager.getToken());
      dispatch(actions.userFulfilled(result));
      dispatch(loadBootstrapDataRequested());
    } catch (err) {
      dispatch(actions.userRejected(err));
    }
  };
};

export const userProfileChanged = (data) => {
  return async (dispatch) => {
    dispatch(actions.userProfileChangedPending());
    try {
      await changeUserProfile(TokenManager.getToken(), data);
      dispatch(actions.userProfileChangedFulfilled(data));
    } catch (err) {
      dispatch(actions.userProfileChangedRejected(err));
    }
  };
};

export const userPasswordChanged = (data) => {
  return async (dispatch) => {
    dispatch(actions.userPasswordChangedPending());
    try {
      let result = await changeUserPassword(TokenManager.getToken(), data);
      dispatch(actions.userPasswordChangedFulfilled(result));
    } catch (err) {
      dispatch(actions.userPasswordChangedRejected(err));
    }
  };
};

export const logout = (errorDetail) => {
  return async (dispatch) => {
    TokenManager.clearToken();
    dispatch({ type: "RESET" });
    await persistor.purge();
    await persistor.persist();
    if (errorDetail !== null) {
      dispatch(actions.tokenExpirationErrorRaised(errorDetail));
    }
  };
};

export const magicLinkRequested = (data) => {
  return async (dispatch) => {
    dispatch(actions.magicLinkRequestedPending());
    try {
      let result = await magicLinkRequest(data);
      dispatch(actions.magicLinkRequestedFulfilled(result));
      dispatch(currentUserOnApplicationLoadRequested());
    } catch (err) {
      dispatch(actions.magicLinkRequestedRejected(err));
    }
  };
};

export const forgotPasswordRequested = (email) => {
  return async (dispatch) => {
    dispatch(actions.forgotPasswordRequestedPending());
    try {
      let result = await forgotPasswordRequest(email);
      dispatch(actions.forgotPasswordRequestedFulfilled(result));
    } catch (err) {
      dispatch(actions.forgotPasswordRequestedRejected(err));
    }
  };
};

export const resetPasswordRequested = (id, password) => {
  return async (dispatch) => {
    dispatch(actions.resetPasswordRequestedPending());
    try {
      let result = await resetPasswordRequest(
        id,
        password,
        TokenManager.getToken(),
      );
      dispatch(actions.resetPasswordRequestedFulfilled(result));
      dispatch(currentUserOnApplicationLoadRequested());
    } catch (err) {
      dispatch(actions.resetPasswordRequestedRejected(err));
    }
  };
};
