import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import { PendingRequestsContainer } from "./PendingRequests/PendingRequestsContainer";
import { ProcessedRequestsContainer } from "./ProcessedRequests/ProcessedRequestsContainer";

export const RequestsView = () => {
  const theme = useTheme();

  return (
    <Grid container style={theme.generalContainer}>
      <Grid item xs={12} style={theme.mainContainer}>
        <Typography variant="h4" style={theme.pvelH1}>
          Report Requests
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PendingRequestsContainer />
      </Grid>
      <Grid item xs={12}>
        <ProcessedRequestsContainer />
      </Grid>
    </Grid>
  );
};
