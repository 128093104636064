import React from "react";
export const useDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState(null);

  const toggleDialog = (status, item) => () => {
    setOpen(status);
    setItem(item);
  };

  return [item, open, toggleDialog];
};
