import { createSlice } from "@reduxjs/toolkit";
import {
  loadMessageFromPvel,
  loadPendingRequests,
  loadActiveRequests,
  loadExpiredRequests,
  loadReportRequests,
} from "../reports/reportServices";
import {
  loadNewReports,
  loadAllPendingReports,
} from "../../shared/services/sharedServices";

const initialState = {
  messageFromPvel: {},
  messageFromPvelLoading: false,
  messageFromPvelError: null,
  reportRequestsCount: null,
  reportRequestsCountLoading: false,
  reportRequestsCountError: null,
  newReportsCount: null,
  newReportsCountLoading: false,
  newReportsCountError: null,
  activeRequestsCount: null,
  activeRequestsCountLoading: false,
  activeRequestsCountError: null,
  expiredRequestsCount: null,
  expiredRequestsCountLoading: false,
  expiredRequestsCountError: null,
  pendingRequestsCount: null,
  pendingRequestsCountLoading: false,
  pendingRequestsCountError: null,
  pendingReportsCount: null,
  pendingReportsCountLoading: false,
  pendingReportsCountError: null,
  newPvelReportsCount: null,
  newPvelReportsCountLoading: false,
  newPvelReportsCountError: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    loadMessageFromPvelPending: (state, action) => {
      state.messageFromPvelLoading = true;
      state.messageFromPvelError = null;
    },
    loadMessageFromPvelFulFilled: (state, action) => {
      state.messageFromPvelLoading = false;
      state.messageFromPvel = action.payload.results[0];
    },
    loadMessageFromPvelRejected: (state, action) => {
      state.messageFromPvelLoading = false;
      state.messageFromPvelError = action.payload;
    },
    loadReportRequestsCountPending: (state, action) => {
      state.reportRequestsCountLoading = true;
      state.reportRequestsCountError = null;
    },
    loadReportRequestsCountFulFilled: (state, action) => {
      state.reportRequestsCountLoading = false;
      state.reportRequestsCount = action.payload.count;
    },
    loadReportRequestsCountRejected: (state, action) => {
      state.reportRequestsCountLoading = false;
      state.reportRequestsCount = action.payload;
    },
    loadNewReportsCountPending: (state, action) => {
      state.newReportsCountLoading = true;
      state.newReportsCountError = null;
    },
    loadNewReportsCountFulFilled: (state, action) => {
      state.newReportsCountLoading = false;
      state.newReportsCount = action.payload.length;
    },
    loadNewReportsCountRejected: (state, action) => {
      state.newReportsCountLoading = false;
      state.newReportsCountError = action.payload;
    },
    loadExpiredRequestsCountPending: (state, action) => {
      state.expiredRequestsCountLoading = true;
      state.expiredRequestsCountError = null;
    },
    loadExpiredRequestsCountFulFilled: (state, action) => {
      state.expiredRequestsCountLoading = false;
      state.expiredRequestsCount = action.payload.count;
    },
    loadExpiredRequestsCountRejected: (state, action) => {
      state.expiredRequestsCountLoading = false;
      state.expiredRequestsCountError = action.payload;
    },
    loadActiveRequestsCountPending: (state, action) => {
      state.activeRequestsCountLoading = true;
      state.activeRequestsCountError = null;
    },
    loadActiveRequestsCountFulFilled: (state, action) => {
      state.activeRequestsCountLoading = false;
      state.activeRequestsCount = action.payload.count;
    },
    loadActiveRequestsCountRejected: (state, action) => {
      state.activeRequestsCountLoading = false;
      state.activeRequestsCountError = action.payload;
    },
    loadPendingReportsCountPending: (state, action) => {
      state.pendingReportsCountLoading = true;
      state.pendingReportsCountError = null;
    },
    loadPendingReportsCountFulFilled: (state, action) => {
      state.pendingReportsCountLoading = false;
      state.pendingReportsCount = action.payload.count;
    },
    loadPendingReportsCountRejected: (state, action) => {
      state.pendingReportsCountLoading = false;
      state.pendingReportsCountError = action.payload;
    },
    loadPendingRequestsCountPending: (state, action) => {
      state.pendingRequestsCountLoading = true;
      state.pendingRequestsCountError = null;
    },
    loadPendingRequestsCountFulFilled: (state, action) => {
      state.pendingRequestsCountLoading = false;
      state.pendingRequestsCount = action.payload.pending_count;
    },
    loadPendingRequestsCountRejected: (state, action) => {
      state.pendingRequestsCountLoading = false;
      state.pendingRequestsCountError = action.payload;
    },
    /*     loadNewPvelReportsCountLoading: (state, action) => {
      state.newPvelReportsCountLoading = true;
      state.newPvelReportsCountError = null;
    },
    loadNewPvelReportsCountFulFilled: (state, action) => {
      state.newPvelReportsCountLoading = false;
      state.newPvelReportsCount = action.payload.count;
    },
    loadNewPvelReportsRejected: (state, action) => {
      state.newPvelReportsCountLoading = false;
      state.newPvelReportsCountError = action.payload;
    } */
  },
});

export const { actions, reducer } = dashboardSlice;

export const loadMessageFromPvelRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadMessageFromPvelPending());
    try {
      let result = await loadMessageFromPvel();
      dispatch(actions.loadMessageFromPvelFulFilled(result));
    } catch (err) {
      dispatch(actions.loadMessageFromPvelRejected(err));
    }
  };
};

export const loadPendingRequestsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadPendingRequestsCountPending());
    try {
      let result = await loadPendingRequests();
      dispatch(actions.loadPendingRequestsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadPendingRequestsCountRejected(err));
    }
  };
};

export const loadActiveRequestsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadActiveRequestsCountPending());
    try {
      let result = await loadActiveRequests();
      dispatch(actions.loadActiveRequestsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadActiveRequestsCountRejected(err));
    }
  };
};
export const loadExpiredRequestsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadExpiredRequestsCountPending());
    try {
      let result = await loadExpiredRequests();
      dispatch(actions.loadExpiredRequestsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadExpiredRequestsCountRejected(err));
    }
  };
};

export const loadReportsRequestsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadReportRequestsCountPending());
    try {
      let result = await loadReportRequests();
      dispatch(actions.loadReportRequestsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadReportRequestsCountRejected(err));
    }
  };
};

export const loadAllPendingReportsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadPendingReportsCountPending());
    try {
      let result = await loadAllPendingReports();
      dispatch(actions.loadPendingReportsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadPendingReportsCountRejected(err));
    }
  };
};

export const loadNewReportsRequested = () => {
  return async (dispatch) => {
    dispatch(actions.loadNewReportsCountPending());
    try {
      let result = await loadNewReports();
      dispatch(actions.loadNewReportsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadNewReportsCountRejected(err));
    }
  };
};

/**Commented for future use */
/* export const loadNewPvelReportsRequested = () => {
  return async dispatch => {
    dispatch(actions.loadNewPvelReportsCountLoading());
    try {
      let result = await loadNewPvelReports();
      dispatch(actions.loadNewPvelReportsCountFulFilled(result));
    } catch (err) {
      dispatch(actions.loadNewPvelReportsRejected(err));
    }
  };
}; */
