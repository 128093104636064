import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReportRequestsPartnerView } from "./ReportRequestsPartnerView";
import isEmpty from "lodash/isEmpty";

import {
  loadReportsRequestsRequested,
  loadReportsPartnersRequested,
  downloadedReportPartnersDashboard,
  downloadedReportRequestPartnersDashboard,
} from "../";

export const ReportRequestsPartnerContainer = () => {
  const reportRequests = useSelector(
    (state) => state.dashboardPartner.reportRequests,
  );
  const reportRequestsLoading = useSelector(
    (state) => state.dashboardPartner.reportRequestsLoading,
  );
  const reports = useSelector((state) => state.dashboardPartner.reports);
  const reportsLoading = useSelector(
    (state) => state.dashboardPartner.reportsLoading,
  );

  const dispatch = useDispatch();

  const onDownloadReportDashboard = React.useCallback(
    (report) => dispatch(downloadedReportPartnersDashboard(report)),
    [dispatch],
  );

  const onDownloadReportRequestDashboard = React.useCallback(
    (report) => dispatch(downloadedReportRequestPartnersDashboard(report)),
    [dispatch],
  );

  const onLoadReportRequestsRequested = React.useCallback(() => {
    if (isEmpty(reportRequests)) {
      dispatch(loadReportsRequestsRequested());
    }
  }, [dispatch]);

  const onLoadReportsPartnersRequested = React.useCallback(() => {
    if (isEmpty(reports)) {
      dispatch(loadReportsPartnersRequested());
    }
  }, [dispatch]);

  React.useEffect(() => {
    onLoadReportRequestsRequested();
    onLoadReportsPartnersRequested();
  }, [onLoadReportRequestsRequested, onLoadReportsPartnersRequested]);

  return (
    <ReportRequestsPartnerView
      reportRequests={reportRequests}
      reportRequestsLoading={reportRequestsLoading}
      reports={reports}
      reportsLoading={reportsLoading}
      onDownloadReportDashboard={onDownloadReportDashboard}
      onDownloadReportRequestDashboard={onDownloadReportRequestDashboard}
    />
  );
};
