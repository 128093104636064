import React from "react";

import "font-awesome/css/font-awesome.min.css";
import "./App.scss";
import { Routes } from "./Routes";

export const App = () => {
  return (
    <>
      <Routes />
    </>
  );
};
