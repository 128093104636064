import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { MaybeLoading } from "../../common/MaybeLoading";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import Link from "@material-ui/core/Link";
import debounce from "lodash/debounce";
import { FetchError } from "features/common/FetchError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
  },
  icon: {
    fontSize: "30px",
    verticalAlign: "baseline",
    marginTop: "15px",
  },
  formcontrol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "flex-end",
  },
  title: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
}));

const DownstreamPartnersView = (props) => {
  const {
    downstreamPartnersLoading,
    filteredPartners,
    onSearchPartner,
    downstreamPartnersError,
    loadDownstreamPartnersRequested,
  } = props;

  const delayedQuery = debounce((value) => onSearchPartner(value), 500);

  const theme = useTheme();
  const classes = useStyles();

  const handleFilterContent = (e) => {
    delayedQuery(e.target.value);
  };

  const renderLoader = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        <MaybeLoading loading={downstreamPartnersLoading} />
      </TableCell>
    </TableRow>
  );

  const renderWebsiteLink = (partner) => {
    if (partner.website !== "") {
      return (
        <Link
          style={theme.downloadLink}
          href={partner.website}
          target="_blank"
          rel="noopener noreferrer"
        >
          {partner.display_name}
        </Link>
      );
    } else {
      return partner.display_name;
    }
  };

  const renderTableBody = () => {
    return (
      !isEmpty(filteredPartners) &&
      filteredPartners.map((partner, idx) => {
        return (
          <TableRow key={`manufacturer-${partner.id}`}>
            <TableCell align="left">{renderWebsiteLink(partner)}</TableCell>
            <TableCell align="left">{partner.country}</TableCell>
          </TableRow>
        );
      })
    );
  };

  if (downstreamPartnersError && !filteredPartners) {
    return (
      <FetchError
        title="Error while loading data for Downstream partners. Expand for more details" //
        error={downstreamPartnersError}
        onRetry={() => loadDownstreamPartnersRequested()}
      />
    );
  }

  return (
    <div style={theme.generalContainer}>
      <div style={theme.mainContainer}>
        <div className={classes.root}>
          {/* <Typography align="left" style={theme.paragraph}>
            Would you like to refer a company to the Kiwa PVEL Downstream Partner
            network? Let us know at{" "}
            <Link style={theme.downloadLink} href="mailto:kiwa@pvel.com">
              kiwa@pvel.com
            </Link>
          </Typography> */}
          <FormControl className={classes.formcontrol}>
            <TextField
              id="standar-multiline-flexible"
              name="search"
              label="search"
              type="search"
              color="secondary"
              className={classes.input}
              onChange={handleFilterContent}
            />
            <Search className={classes.icon} />
          </FormControl>
        </div>
        <Paper style={theme.tableContainer}>
          <TableContainer style={theme.table}>
            <Table stickyHeader aria-label="My Downstream Partners Table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={theme.tableHeader}>
                    Company
                  </TableCell>
                  <TableCell align="left" style={theme.tableHeader}>
                    Primary Operating Country
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {downstreamPartnersLoading ? renderLoader() : renderTableBody()}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

DownstreamPartnersView.propTypes = {
  downstreamPartnersLoading: PropTypes.bool.isRequired,
  filteredPartners: PropTypes.array,
  onSearchPartner: PropTypes.func.isRequired,
  loadDownstreamPartnersRequested: PropTypes.func.isRequired,
  downstreamPartnersError: PropTypes.any,
};

export default React.memo(DownstreamPartnersView);
