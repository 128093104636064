import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ErrorMessage from "features/common/ErrorMessage";

const useStyles = makeStyles((theme) => ({
  forgotPasswordContainer: {
    height: "60vh",
    paddingTop: "20vh",
  },
}));

export const CenteredMessage = ({ title, message, error }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (error) {
    return (
      <div className={classes.forgotPasswordContainer}>
        <Container>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
          >
            {/*             <Grid item xs={12} style={theme.resetPasswordMainTitle}> */}
            <Grid item xs={12}>
              <ErrorMessage message={message} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.forgotPasswordContainer}>
      <Container>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          spacing={2}
        >
          {/*           <Grid item xs={12} style={theme.resetPasswordMainTitle}> */}
          <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          {/*           <Grid item xs={12} style={theme.resetPasswordMainTitle}> */}
          <Grid item xs={12}>
            <Typography variant="body1">{message}</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
